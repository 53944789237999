.error-message {
    padding-top: 5em;
    padding-bottom: 5em;
}

.error-action {
    text-align: center;
    margin-bottom: 3em;
}

.error-debug {
    margin-top: 2em;
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: 1px dotted @colorBorderGray;

    pre {
        word-wrap: normal;
    }
}