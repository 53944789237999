section.section-guide {
    .guide-image {
        position: relative;
        .desc {
            bottom: 0;
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            padding: 15px;
            font-size: 25px;
            font-weight: 300;
        }
    }

    .content {
        padding: 20px;
        font-weight: 300;
        strong {
            font-weight: 500;
        }
        .guide-button {
            margin: 0;
            margin-top: 10px;
        }
    }
}

.guide-page,
.guide-attractions {
    a {
        color: @colorFontGrayDark;
    }
}

.guide-photos-preview {
    .photo {
        margin-bottom: 30px;
    }
}

.guide-weather-preview {
    .weather {
        background: @colorPink;
        color: #fff;
        padding: 20px;
        h4 {
            color: #fff;
        }
        table {
            width: 100%;
            .picture {
                width: 80px;
            }
            .temperature {
                width: 35px;
                font-size: 20px;
            }
            .details {
                border-left: 1px solid #fff;
                padding-left: 15px;
            }
        }
    }
}

.guide-map,
.guide-map-preview {
    .map {
        padding: 5px;
        border: 1px solid @colorBorderGray;
        margin-bottom: 10px;
        #guide-gmap {
            height: 400px;
        }
    }
    .description {
        color: @colorFontGray;
        font-size: 12px;
        line-height: 40px;
    }
}

.guide-map {
    #guide-gmap {
        height: 500px;
    }
}

.guide-content-preview {
    font-weight: 300;
    .photos-preview {
        .photos {
            margin-left: -5px;
            margin-right: -5px;
            div[class*=col-] {
                padding: 5px;
            }
        }
    }

    .description {
        color: @colorFontGray;
        font-size: 12px;
    }
    .guide-link {
        text-align: right;
        font-size: 15px;
        margin: 10px 0;
        font-weight: normal;
        a {
            color: @colorPink;
            text-decoration: none;
            text-align: right;
            &:hover {
                color: @colorPinkDarker;
            }
        }
    }

    .map-preview {
        .map {
            padding: 5px;
            border: 1px solid @colorBorderGray;
            #guide-preview-gmap {
                height: 200px;
            }
        }
    }
}

.guide-weather,
.weather-preview {
    .forecast {
        .weather {
            background: @colorPink;
            color: #fff;
            padding: 20px;
            h4 {
                color: #fff;
            }

            .picture {
                font-size: 30px;
            }
        }
    }
}

section.guide-weather {
    .current-weather {
        padding: 20px;
        background: #fff;
        position: relative;
        margin-bottom: 30px;

        .current-info {
            position: absolute;
            top: 0;
            right: 0;
            width: 210px;
            background: @colorPink;
            color: #fff;
            padding: 0px 15px;
            .weather-picture {
                margin-top: 10px;
                margin-bottom: 10px;
                margin-right: 20px;
            }
            .temp {
                font-size: 24px;
                margin-top: 10px;
            }
            .temp-realfeel {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
        .title {
            color: @colorPink;
            font-size: 18px;
            padding-right: 210px;
            min-height: 76px;
        }
        table {
            width: 100%;
            tbody {
                th {
                    width: 70%;
                }
                td {
                    width: 30%;
                }
                td, th {
                    font-weight: 300;
                    padding: 5px 2px;
                    font-size: 13px;
                }
            }
        }
    }

    .forecast {
        .weather {
            margin-bottom: 30px;
        }
        .accuWeather {
            text-align: right;
            color: @colorFontGray;
            font-size: 12px;
        }
    }
}

.guide-photos {
    .guide-image {
        margin-bottom: 30px;
    }
    .photos {
        & > div {
            margin-bottom: 30px;
        }
    }
    .content-info {
        text-align: right;
        color: @colorFontGray;
        font-size: 12px;
        margin-bottom: 10px;
    }
}

@media(max-width: @screen-xs-max) {
    section.guide-weather {
        .current-weather {
            .current-info {
                position: relative;
                width: 100%;
            }

            .title {
                min-height: 0;
                padding-right: 0;
            }
        }
    }
}