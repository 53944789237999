section.register.entity-list {
    .entity-card {
        position: relative;
        margin-bottom: 10px;
        border: 1px solid @colorBorderGray;
        padding: 5px;
        min-height: 217px;
        .title {
            text-align: center;
            font-weight: bold;
        }
        .bed-list {
            margin-bottom: 10px;
            text-align: center;

            .bed {
                width: 40px;
                height: 46px;
                vertical-align: top;
                display: inline-block;
                font-size: 11px;
                i {
                    font-size: 35px;
                }
            }
        }
        .details {
            font-size: 12px;
            margin-bottom: 30px;
        }

        .actions {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 5px;
        }
    }

    .entity-card-new-button {
        min-height: 217px;
        margin-bottom: 10px;
        border: 1px solid @colorBorderGray;

        text-align: center;
        padding: 85px 0;
        width: 100%;
    }

    .actions {
        margin-top: 20px;
        .btn-const {
            margin-top: 0;
        }
    }
}

@media (max-width: @screen-xs-max) {
    section.register.entity-list {
        .entity-card {
            min-height: 0;
        }

        .entity-card-new-button {
            min-height: 150px;
            padding: 55px 0;
        }

        .actions {
            .btn-const {
                width: 140px;
                padding: 0 20px;
            }
        }
    }
}