#sidebar-search {
    margin-bottom: 40px;
    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

}
.static-map {
    h1.page-title {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        padding-bottom: 0;
    }
    .page-subtitle {
        display: block;
        font-size: 24px;
        color: @colorFontGray;
        padding-bottom: 30px;
    }

    h3 {
        margin-bottom: 10px;
    }
    ul.two-columns {
        li {
            width: 50%;
            float: left;
        }
    }
}

.locationSearchForm {
    margin-bottom: 10px;
    form {
        .row {
            margin-left: -2px;
            margin-right: -2px;
        }
        div[class*=col-] {
            padding-left: 2px;
            padding-right: 2px;
        }
        input[type=text] {
            height: 50px;
        }
    }
}

.locationsLetters {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
    .choose-letter {
        line-height: 30px;
    }
    ul.letters-list {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        li {
            display: block;
            float: left;
            margin: 0;
            padding: 4px 5px;
            border-left: 1px solid @colorPink;
            &:first-child {
                border-left: none;
            }
            a {
                color: @colorBlack;
            }
        }
    }
}
.lettersContent,
.locationsList {
    ul {
        overflow: hidden;
        margin-bottom: 10px;
        li {
            width: 50%;
            float: left;
        }
    }
    .paginationControl {
        text-align: center;
    }
}

#weatherIndexMap,
#weatherSearchResultsMap,
#locationMap {
    height: 450px;

    .leaflet-popup-content {
        a {
            color: @colorFontGrayDark;
            span {
                color: @colorFontGrayLight;
            }
        }
    }
}