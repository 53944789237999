#form-increase-priority {

    .daily-priority,
    .offer-position {
        display: inline-block;
        margin-bottom: 15px;
    }

    .decrement-increment {
        display: inline-block;
        vertical-align: middle;

        .number-control {
            max-width: 123px;
            margin: 0 5px;

            input {
                text-align: center;
            }
        }
    }
}

.priority-error {
    margin-top: 15px;

    .btn {
        margin-bottom: 15px;
    }
}