.cookie-info {
    background: @colorBgGray;

    .container {
        position: relative;
    }
    .cookie-message {
        color: @colorFontGray;
        margin: 15px 100px 15px 0;
        font-size: 12px;
        font-weight: 300;
    }

    .cookie-button {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -15px;
    }

    .nc-cookie {
        background: url('/img/nc-cookie.svg');
        background-size: contain;
        height: 17px;
        width: 17px;
        display: block;
        float: left;
        margin-right: 5px;
    }
}

@media (max-width: @screen-phone-vertical-max) {
    .cookie-info {
        .cookie-message {
            margin: 15px 0;
            font-size: 8pt;
        }

        .cookie-button {
            position: relative;
            margin: 0 0 15px 0;
            display: block;
            text-align: right;
        }

        .nc-cookie {
            height: 30px;
            width: 30px;
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
}