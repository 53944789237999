#search-form.horizontal {
    .row {
        margin-left: -2px;
        margin-right: -2px;
    }
    div[class*="col-"] {
        padding-left: 2px;
        padding-right: 2px;
    }

    display: block;
    .form-element {
        //float: left;
        //margin-right: 5px;
        input[type='text'] {
            height: 50px;
        }
        .styled-select {
            display: block;
            &:before {
                top: 18px;
            }
            select {
                height: 50px;
            }
        }
    }

    .location {
        //width: 270px;
    }
    .date {
        //width: 150px;
    }
    .guest {
        //width: 120px;
    }
    .submit-element {
        //width: 150px;
        //float: left;
        input {
            width: 100%;
        }
    }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: @screen-xs-max) {
    #search-form.horizontal {
        .form-element {
            margin-bottom: 15px;
            .placeholder {
                height: 50px;
                line-height: 40px;
                .placeholder-text {
                    color: @colorFontGrayLight;
                }
            }
            &.date {
                .placeholder {
                    padding-right: 30px;
                }
            }
        }
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}
