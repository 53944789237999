.btn-track-transaction-next {
    animation: 3s buttonTrackTransactionNextAnimation;
    animation-fill-mode: forwards;
    visibility: hidden;
    opacity: 0;
}
@keyframes buttonTrackTransactionNextAnimation {
    80% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}