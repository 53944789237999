.customer-reviews {
    .comments {
        .comment {
            border-bottom: 1px solid #b9b4b4;
            padding: 20px 0;
            min-height: 150px;

            .image {
                width: 100px;
                height: 100px;
                position: relative;
                overflow: hidden;
                border-radius: 50%;
                float: left;

                img {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                    width: auto;
                }
            }

            .review {
                margin-left: 115px;

                blockquote {
                    margin: 0;
                    padding: 0;
                    font-size: inherit;
                    font-weight: 300;
                    border: none;
                }

                .author {
                    padding-top: 15px;
                    .name {
                        display: inline-block;
                    }

                    .location {
                        font-weight: 500;

                        &::before {
                            content: " - ";
                        }
                    }
                }
            }
        }
    }
}