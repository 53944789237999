.modal-offer-preview {
    &.modal.iframe {
        button.close {
            top: 20px;
        }
    }
}

.iframe-offer-preview,
.iframe-entity-preview {
    overflow: hidden;
}

.offer-preview-content {
    .photos {
        float: left;
        width: 848px;
        min-height: 500px;

        .carousel.gallery .carousel-thumbnails {
            margin: 0;
            position: relative;
            background: #000;
        }
    }

    .details {
        float: left;
        width: 352px;
        position: relative;
        min-height: 556px;

        .address {
            span {
                color: @colorFontGray;
                display: block;
            }
        }

        .phone-section {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.25s ease-in-out;

            .title {
                margin-top: 13px;
            }

            .phone-list {
                margin: 10px 0;

                a {
                    margin-bottom: 5px;
                    margin-right: 5px;
                }
            }

            &.not-visible {
                visibility: hidden;
                opacity: 0;
            }
        }

        .disclaimer {
            margin: 10px;

            .fa {
                font-size: 20px;
                margin-right: 5px;
            }
        }

        .call-us {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            background: @colorWhite;
        }
    }

    .offer-title {
        margin: 0;
        background: #F0EEEF;
        padding: 15px 40px 15px 15px;
        font-size: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .offer-details {
        padding: 5px 20px;
        & > p {
            margin-bottom: 15px;
            font-weight: 300;
            .paragraph-title {
                font-weight: 500;
            }

            i {
                font-size: 28px;
                padding: 5px;
                vertical-align: middle;
                color: #918787;
            }
        }
    }
}