.favorite-addon {
    .favorite-heart-icon {
        position: relative;
        width: 20px;
        height: 20px;

        &:after {
            content: "\f08a";
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &.active {
        .favorite-heart-icon {
            &:before {
                content: "\f004";
                position: absolute;
                top: 1px;
                left: 1px;
                color: @colorIconRed;
                font-size: 18px;
            }
        }
    }
}

.favorite-icon-badge {
    color: @colorFontGray;
    position: relative;

    .label-as-badge {
        position: absolute;
        top: -2px;
        left: 12px;
        padding: .2em .4em .3em;

        background: #ff695d;
        border-radius: 1em;
        font-size: 10px;
    }

}

span.favorite-icon-badge {
    .label-as-badge {
        left: auto;
        top: 0;
        margin-left: 5px;
    }
}
.favorite-search-button {
    margin-top: 30px;
    margin-bottom: 30px;
}

.favorite-no-found {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.favorite-search-icon {
    background: url('/img/favorite-search.png') no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-position: center;
    min-height: 135px;
}

.offer-favorites {
    .result-list {
        max-width: 848px;
        margin: auto;
    }

    .paginator {
        max-width: 848px;
        margin: 0 auto 20px auto;
    }

    .page-title {
        .fa-heart {
            color: @colorIconRed;
            font-size: 0.8em;
        }
    }
}


