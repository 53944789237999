.carousel.gallery {
    .carousel-control {
        display: none;
        background: none;
        .arrow {
            position: absolute;
            top: 50%;
            z-index: 5;
            display: inline-block;
            margin-top: -20px;
            font-size: 35px;
            padding: 10px 20px;
            background: rgba(0, 0, 0, 0.5);
        }
        &.left {
            .arrow {
                left: 0;
            }
        }
        &.right {
            .arrow {
                right: 0;
            }
        }
        &:focus {
            outline: none !important;
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important;
            border: 0 !important;
        }
    }
    &:hover {
        .carousel-control {
            display: block;
        }
    }

    .carousel-inner {
        .item {
            text-align: center;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
    .carousel-thumbnails {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        z-index: 15;
        padding: 2px 40px;
        margin-top: -56px;
        height: 56px;
        // opóźnienie musi być mniejsze niż 1s (inaczej nie zadziała lazy-load)
        transition: margin-top 0.25s ease-in-out 0.75s;

        .content {
            overflow: hidden;
        }
        .indicators {
            list-style: none;
            position: relative;
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            font-size: 0;
            li {
                display: inline-block;
                position: relative;
                padding: 0;
                margin: 0 1px;
                border: 1px solid rgba(0, 0, 0, 0.5);

                &.active {
                    border: 1px solid #FFFFFF;
                }
            }
        }

        &.not-ready {
            margin-top: 0;
        }

        .carousel-thumbnails-control {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 2;
            width: 40px;
            opacity: 0.5;
            filter: alpha(opacity=50);
            font-size: 20px;
            color: #fff;
            text-align: center;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            &:hover {
                opacity: 1;
            }

            .arrow {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                font-size: 20px;

            }

            &.left {
                left: 0;
                .arrow {
                    left: 10px;
                }
            }
            &.right {
                right: 0;
                .arrow {
                    right: 10px;
                }
            }
            &:focus {
                outline: none !important;
                -webkit-box-shadow: none !important;
                -moz-box-shadow: none !important;
                box-shadow: none !important;
                border: 0 !important;
            }
        }
    }
}