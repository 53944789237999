@import "variable/color.less";
@import "variable/screen-resolution.less";

html, body {
    font-family: Roboto;
    height: 100%;
}

a {
    color: @colorMarineGreen;
    &:hover, &:focus {
        color: @colorMarineGreenDark;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: @colorFontGrayDark;
    font-family: Dosis;
    line-height: 1.3;
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .container {
        width: auto;
    }
}

.bg-white {
    background: #fff;
}

.bg-gray {
    background: @colorBgGray;
}

.bg-blue {
    background: @colorBgBlue;
}


.button-marine-green,
.button-green,
.button-white,
.button-gray,
.button-blue,
.button-pink {
    display: inline-block;
    padding: 0px 40px;
    border: none;
    border-radius: 0;
    line-height: 50px;
    height: 50px;
    color: #fff;
    font-size: 13pt;
    font-family: Dosis;
    font-weight: normal;
    cursor: pointer;

    &.btn-block {
        text-align: center;
        padding: 0;
        width: 100%;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }

    &.btn-const {
        width: 230px;
        text-align: center;
    }
    &.btn-xs {
        font-size: 9pt;
        padding: 0px 5px;
        height: 28px;
        line-height: 28px;
        &.btn-const {
            width: 80px;
        }
    }
    &.btn-sm {
        font-size: 11pt;
        padding: 0px 15px;
        height: 30px;
        line-height: 30px;
        &.btn-const {
            width: 80px;
        }
    }
    &.btn-md {
        font-size: 11pt;
        padding: 0px 25px;
        height: 40px;
        line-height: 40px;
        &.btn-const {
            width: 80px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
    }
}

.btn-const + .btn-const {
    margin-top: 5px;
}

.button-pink {
    background: @colorPink;
    border-bottom: 4px solid @colorPinkDarker;
    &:hover {
        background: lighten(@colorPink, 5%);
    }
}

.button-blue {
    background: @colorBlue;
    border-bottom: 4px solid @colorBlueDark;

    &:hover {
        background: lighten(@colorBlue, 5%);
    }
}

.button-gray {
    background: @colorFontGray;
    border-bottom: 4px solid @colorBgGrayDark;
    &:hover {
        background: lighten(@colorFontGray, 5%);
    }
}
.button-white {
    background: @colorWhite;
    border: 1px solid @colorPink;
    color: @colorFontGray;
    &:hover {
        color: @colorWhite;
        background: @colorPink;
    }
}

.button-green {
    background: @colorBgGreen;
    border-bottom: 4px solid darken(@colorBgGreen, 5%);
    &:hover {
        background: lighten(@colorBgGreen, 2%);
    }
}

.button-marine-green {
    background: @colorMarineGreen;
    border-bottom: 4px solid darken(@colorMarineGreen, 5%);
    &:hover {
        background: lighten(@colorMarineGreen, 2%);
    }
}

.button-phone {
    background: @colorGreen;
    color: @colorWhite;
    cursor: pointer;
    i {
        color: @colorWhite;
    }
    &:hover {
        background: @colorGreenLighten;
    }
}

.button-o {
    background: transparent;
    border: 1px solid @colorBorderGrayLight;
    color: @colorFontGray;
    &:hover {
        background: #f1f1f1;
    }
}

.button-close {
    color: @colorFontRed;
    border: none;
    height: 16px;
    width: 16px;
    padding: 0;
    margin: 0;
    line-height: 16px;
    font-size: 12px;
    border-radius: 8px;
    background: @colorBgGray;
    padding-right: 1px;
    &:hover {
        background: lighten(@colorBgGray, 5%);
    }
}

h1.title {
    text-align: center;
    margin: 30px 0 50px;
}

.section-title {
    color: @colorTitleSection;
    font-size: 25px;
    font-weight: normal;
    margin: 25px 0;
    text-align: center;
    position: relative;
    font-family: Dosis;

    &:after {
        border-bottom: 1px solid @colorBorderGray;
        display: block;
        width: 100%;
        content: " ";
        height: 1px;
        line-height: 0px;
        position: absolute;
        margin-top: -13px;
    }

    span {
        background: #fff;
        color: @colorTitleSection;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        a {
            color: inherit;
            text-decoration: inherit;
            &:hover {
                color: @colorFontGrayDark;
            }
        }
    }
    &.left {
        text-align: left;
        span {
            padding-left: 0;
        }
    }
}

h1.section-title {

}

h2.section-title {
    font-size: 25px;
}

h3.section-title {
    font-size: 20px;
    &:after {
        margin-top: -11px;
    }
}

h4.section-title {
    font-size: 18px;
    &:after {
        margin-top: -10px;
    }
}

.arrow-hook {
    margin: 0 auto;
    width: 110px;
    height: 20px;
    margin-top: -10px;
    background: #fff;
    border-radius: 5px;

    .arrow-down {
        margin: 0 auto;
        position: relative;
        top: 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: @colorBorderGray transparent transparent transparent;
        line-height: 0px;
    }


}

&.hook-marine-green {
    position: relative;
    .line-hook {
        position: absolute;
        background: @colorMarineGreen;
        width: 100%;
        height: 15px;
    }
    .arrow-hook {
        background: @colorMarineGreen;
        .arrow-down {
            border-color: @colorWhite transparent transparent transparent;
        }
    }
}

.section-row-title {
    padding: 50px 0;
    h1 {
        text-align: center;
    }
    &.section-bg-green,
    &.section-bg-red {
        h1 {
            color: #fff;
        }
    }
}

.section-bg-gray {
    background: @colorBgGray;
    .section-title {
        span, div {
            background: @colorBgGray;
        }
    }
    .arrow-hook {
        background: @colorBgGray;
        .arrow-down {
            border-color: @colorPink transparent transparent transparent;
        }
    }
}

.section-bg-pink {
    background: @colorPink;
    .section-title {
        color: #fff;
        span, div {
            color: #fff;
            background: @colorPink;
        }
        &:after {
            border-bottom: 1px solid #fff;
        }
    }
    .arrow-hook {
        background: @colorPink;
        .arrow-down {
            border-color: #fff transparent transparent transparent;
        }
    }
}
.section-bg-blue {
    background: @colorBgBlue;
    .section-title {
        color: #fff;
        span, div {
            color: #fff;
            background: @colorBgBlue;
        }
        &:after {
            border-bottom: 1px solid #fff;
        }
    }
    .arrow-hook {
        background: @colorBgBlue;
        .arrow-down {
            border-color: #fff transparent transparent transparent;
        }
    }
}

.section-bg-red {
    background: @colorBgRed;
    .section-title {
        color: #fff;
        span, div {
            color: #fff;
            background: @colorBgRed;
        }
        &:after {
            border-bottom: 1px solid #fff;
        }
    }
    .arrow-hook {
        background: @colorBgRed;
        .arrow-down {
            border-color: #fff transparent transparent transparent;
        }

    }
}

.section-bg-green {
    background: @colorMarineGreen;
    .section-title {
        color: #fff;
        span, div {
            color: #fff;
            background: @colorBgGreen;
        }
        &:after {
            border-bottom: 1px solid #fff;
        }
    }
    .arrow-hook {
        background: @colorBgBlue;
        .arrow-down {
            border-color: #fff transparent transparent transparent;
        }
    }
}

.section-bg-white {
    .section-title {
        span,
        div {
            background: #fff;
        }
    }
}

.menu-bar {
    background: @colorWhite;
    padding: 5px 0;

    button.btn-menu {
        font-size: 25px;
        color: @colorFontGray;
        &:hover {
            color: lighten(@colorFontGray, 10%);
        }
    }
    ul.header-menu {
        float: right;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            line-height: 20px;
            float: left;
            margin: 15px 0;
            margin-right: 14px;
            padding: 0;
            padding-left: 14px;
            font-weight: 300;
            &.line {
                border-left: 1px solid @colorFontGray;
            }

            a {
                color: @colorFontGrayDark;
            }
            i {
                margin-right: 15px;
                color: @colorFontGray;
            }
        }
    }
    img.brand {
        height: 50px;
    }
}

.page-wrapper {
    min-height: 100%;
}

.page-wrapper.gray {
    background: @colorBgGray;
}

.page-wrapper:after {
    content: "";
    display: block;
    margin-bottom: -265px;
}

section.footer, .page-wrapper:after {
    height: 265px;
    .fb-page {
        overflow: hidden;
    }
}

section.footer {
    background: @colorMarineGreenBgDark;
    border-top: 10px solid @colorMarineGreen;
    color: #fff;
    font-weight: 300;
    padding: 20px 0;

    .column-title {
        color: #fff;
        font-weight: normal;
        font-size: 16pt;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-weight: 300;
        li {
            &:before {
                content: "-";
                margin-right: 5px;
            }
            a {
                color: #fff;
            }
        }
    }
    .copyright {
        font-size: 10pt;
    }
    .btn-add-offer {
        margin-top: 10px;
    }
}

section.default-section {
    margin-bottom: 70px;
}

.paginator {
    background: #fff;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    .items {
        display: inline-block;
        span, a {
            display: inline-block;
            border-radius: 13px;
            width: 26px;
            height: 26px;
            line-height: 26px;
            background: @colorWhite;
            color: @colorFontGrayDark;
        }
        a {
            &:hover {
                text-decoration: none;
                color: #fff;
                background: lighten(@colorPink, 10);
            }
            &.active {
                background: @colorPink;
                color: #fff;
                &:hover {
                    background: lighten(@colorPink, 10);
                }
            }
        }
    }
    & > a {
        display: inline-block;
        border-radius: 13px;
        width: 26px;
        height: 26px;
        color: @colorFontGrayDark;
        font-size: 17px;
        &:hover {
            color: #fff;
            background: lighten(@colorPink, 10);
        }
    }
}
.breadcrumbs>li+li:before {
    padding: 0;
    content: normal;
}

.breadcrumbs {
    background: none;
    margin: 20px 0;
    padding: 0;
    color: @colorFontGray;
    font-size: 9pt;
    font-weight: 300;

    > li {
        display: inline-block;
    }
    
    a {
        color: @colorFontGray;
        text-decoration: none;
        &:hover {
            color: @colorFontGrayDark;
        }
    }

    i {
        color: @colorPink;
        font-size: 7pt;
        margin: 0 5px;
        margin-bottom: 3px;

    }

    .breadcrumbs-separator:before {
        font-family: FontAwesome;
        font-style: normal;
        content: "\f054";
    }
}

.panel {
    -webkit-box-shadow: none;
    box-shadow: none;
    &.panel-default {
        border: 1px solid @colorBgGray;
        border-radius: 0;
        .panel-heading {
            background: transparent;
            border: none;
            padding-bottom: 0;
        }
    }
}

.star-rating {
    color: @colorPink;
    font-size: 17px;
    vertical-align: middle;
    .rating-count {
        color: @colorFontGray;
        margin-left: 5px;
        font-size: 85%;
    }
}

ul.pink-round {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 5px;
    li {
        margin: 2px 0;
        padding: 0;
        font-size: 15px;
        font-weight: 300;

        &:before {
            content: " ";
            display: inline-block;
            width: 7px;
            height: 7px;
            background: @colorPink;
            margin-right: 10px;
            margin-bottom: 2px;
            border-radius: 3.5px;
        }

        a {
            text-decoration: none;
            color: @colorFontGray;
            &:hover {
                color: @colorFontGrayDark;
            }
            span {
                color: lighten(@colorFontGray, 15%);
            }
        }

        &.active > a {
            color: @colorFontGrayDark;
            font-weight: 400;
        }
    }

    ul {
        list-style: none;
        padding-left: 20px;
    }
}

.page-title {
    text-align: center;
    padding: 20px 0 30px 0;
}

.progress-circle-indeterminate {
    background: url("/img/progress-circle-master.svg") top left no-repeat;
    width: 50px;
    height: 50px;
    background-size: 100% auto;
    margin: 0 auto
}

.loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 10;
    .progress-circle-indeterminate {
        top: 50%;
        position: absolute;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
    }
}

.validation-error {
    color: @colorWhite;
    font-weight: 200;
    text-align: center;
    white-space: nowrap;
    background-color: @colorRedError;
    padding: 2px 5px;
    font-size: 12px;
}

.no-wrap {
    white-space: nowrap;
}

.alert-marine-green {
    background-color: lighten(@colorMarineGreen, 50);
    color: @colorMarineGreenDark;
    border: 1px solid @colorMarineGreen;
    a {
        color: @colorPink;
        &:hover, &:focus {
            color: @colorPinkDark;
        }
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: @colorFontGrayLight;
}
::-moz-placeholder { /* Firefox 19+ */
    color: @colorFontGrayLight;
}
:-ms-input-placeholder { /* IE 10+ */
    color: @colorFontGrayLight;
}
:-moz-placeholder { /* Firefox 18- */
    color: @colorFontGrayLight;
}

.sidebar-content {
    .section-title {
        font-size: 20px;
        color: @colorFontGrayDark;
        font-family: Dosis;
        line-height: 1.3;
        &:after {
            margin-top: -11px;
        }
    }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
    .validation-error {
        padding: 2px 0px;
    }
}

@media (min-width: @screen-sm-min) {
    .menu-bar {
        .menu-container {
            display: block !important;
        }
    }
}

@media (max-width: @screen-xs-max) {
    section.footer {
        .copyright {
            margin-top: 10px;
        }
    }

    .menu-bar {
        padding-bottom: 0;
        .menu-container {
            display: none;
            background: @colorBgGray;
            padding: 0;
            ul.header-menu {

                float: none;
                li {
                    margin: 0;
                    padding: 0;
                    border-top: 1px solid @colorBorderGray;
                    float: none;
                    &.line {
                        border-left: none;
                    }
                    &:last-child {
                        border-bottom: 1px solid @colorBorderGray;
                    }
                    .fa {
                        display: none;
                    }
                    a {
                        display: block;
                        padding: 10px 15px;
                        &:hover {
                            text-decoration: none;
                            background: lighten(@colorBgGray, 5%);
                        }
                    }
                }
            }
        }
    }

    section.footer {
        height: 340px;
    }

    .page-wrapper:after {
        height: 340px;
        margin-bottom: -340px;
    }
}

@media (max-width: @screen-phone-vertical-max) {
    .section-bg-blue {
        .section-title {
            font-size: 22px;
            span {
                padding: 0;
            }

            &:after {
                border: none;
            }
        }
    }

    section.footer {
        height: 380px;
    }

    .page-wrapper:after {
        height: 380px;
        margin-bottom: -380px;
    }
}