.search-form-horizontal {
    background: @colorMarineGreen;
    padding-bottom: 40px;

    .search-form-container {
        h1 {
            font-size: 24px;
            padding-bottom: 5px;
            color: @colorWhite;
        }
    }
}

@media (min-width: @screen-xs-max) {
    .search-form-horizontal {
        height: 150px;
        position: relative;

        .hero-background {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: @colorMarineGreen;
        }

        .hero {
            height: 100%;
            background: url('/img/hero/hero-short@2x.png') no-repeat left center;
            background-size: 612px 150px;
            margin-left: -216px;
        }
    }
}

@media (min-width: @screen-sm-max) {
    .search-form-horizontal {
        .hero {
            margin-left: -216px;
        }
    }
}

@media (min-width: @screen-md-max) {
    .search-form-horizontal {
        .hero {
            margin-left: 0;
        }
    }
}

@media (min-width: 1364px) {
    .search-form-horizontal {
        .hero {
            width: 1364px;
            margin: auto;
        }
    }
}
