.offer-presentation {
    .offer-main {
        .offer-header-bar {
            padding-bottom: 10px;
            .corner-contact {
                float: right;
                height: 50px;
                margin-top: 16px;
                margin-bottom: 10px;
                margin-left: 5px;
                width: 198px;

                .corner-email,
                .corner-phone,
                .corner-favorite {
                    float: left;
                    font-size: 25px;
                    line-height: 50px;
                    height: 50px;
                    width: 50px;
                    text-align: center;
                    cursor: pointer;

                    .text {
                        display: none;
                    }
                }

                .corner-phone {
                    color: @colorWhite;
                    background: @colorGreen;
                    margin-right: 16px;
                    &:hover {
                        background: @colorGreenLighten;
                    }
                }

                .corner-email {
                    color: @colorPink;
                    background: @colorWhite;
                    border: 1px solid @colorPink;
                    &:hover {
                        background: @colorPinkLightest;
                    }
                }

                .corner-favorite {
                    margin-right: 16px;
                    line-height: 48px;
                    border: 1px solid @colorPink;


                    .favorite-addon {
                        font-size: 12px;
                        color: @colorPink;

                        .favorite-heart-icon {
                            font-size: 20px;
                            vertical-align: middle;
                        }

                        &.active {
                            color: @colorIconRed;
                            .favorite-heart-icon {
                                color: @colorIconRed;
                            }
                        }
                    }

                    &:hover {
                        background: @colorPinkLightest;
                        .favorite-heart-icon {
                            color: @colorIconRed;
                        }
                    }
                }

                .corner-email,
                .corner-phone,
                .corner-favorite {
                    &.disable {
                        color: @colorFontGrayLight;
                        background: @colorWhite;
                        border: 1px solid @colorFontGrayLight;
                        &:hover {
                            background: @colorWhite;
                        }
                    }

                    &.was-sent {
                        color: @colorFontGrayLight;
                        background: @colorBgGrayLighter;
                        border: 1px solid @colorFontGrayLight;
                        &:hover {
                            color: @colorFontGrayLight;
                            background: @colorBgGrayLighter;
                        }
                    }
                }
            }
        }

        #offer-carousel-gallery {
            // https://www.sitepoint.com/maintain-image-aspect-ratios-responsive-web-design/
            position: relative;
            padding-top: 66.62%;
            height: 0;
            overflow: hidden;

            .carousel-inner {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;

                .item {
                    text-align: center;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }

        h1 {
            .offer-main-header {
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: bottom;
                margin-right: 120px;
            }
            .star-rating {
                white-space: nowrap;
                padding: 8px 2px 0px 0px;
            }
            margin: 0;
            padding: 15px 20px 5px 20px;
        }

        .address {
            margin: 0px 20px 0px 20px;
            font-weight: 300;
            a {
                color: @colorBlack;
            }
            span {
                &.open-modal-map {
                    cursor: pointer;
                    color: @colorFontGray;
                    &:hover {
                        color: @colorPink;
                        i {
                            color: @colorPink;
                        }

                    }
                }
            }
        }

        .photo {
            position: relative;
            img {

            }
            .fancybox {
                display: none;
                &.main {
                    display: block;
                }
            }
            .photo-navi {
                background: rgba(0, 0, 0, .4);
                padding: 8px 20px;
                position: absolute;
                bottom: 0;
                color: #fff;
                font-size: 15px;
                line-height: 30px;

                i {
                    margin-top: 5px;
                    margin-right: 12px;
                    font-size: 20px;
                    float: left;
                }
            }
        }
    }

    .offer-info {
        display: table;
        margin-top: 3px;
        width: 100%;
        position: relative;
        border-collapse: collapse;
        .offer-info-row {
            display: table-row;
            .address {
                display: table-cell;
                border-right: 1px solid @colorBgGray;
                vertical-align: middle;
                padding: 15px 20px;
                width: 40%;
                font-weight: 300;
                p {
                    margin-bottom: 10px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                span{
                    color: @colorFontGrayLight;
                }
                a {
                    color: inherit;
                }
            }

            .alert-cell {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                font-size: 15px;
            }

            .offer-owner {
                margin-top: 15px;

                .renew-offer {
                    margin: 15px;
                }
            }

            .contact-cell {
                display: table-cell;
                width: 15%;
                border-right: 1px solid @colorBgGray;
                text-align: center;
                vertical-align: middle;
                &:last-of-type {
                    border-right: none;
                }

                .contact {
                    cursor: pointer;
                    color: @colorPink;
                    margin-bottom: 50px;
                    font-size: 13px;
                    padding: 10px;
                    word-wrap:break-word;
                    &:hover {
                        color: darken(@colorPink, 10%);
                    }
                    i {
                        font-size: 30px;
                        &.fa {
                            font-size: 33px;
                        }
                    }
                    & > div {
                        margin-top: 5px;
                    }

                    .label {
                        background: @colorPink;
                        color: #ffffff;
                        vertical-align: top;
                        line-height: 16px;
                    }
                }
                &:hover {
                    background: @colorPink;
                    .contact {
                        color: #ffffff;
                        .label {
                            background: #ffffff;
                            color: @colorPink;
                        }
                    }
                }
                &.facebook {
                    .contact {
                        padding: 5px;
                        .facebook-action {
                            & > div {
                                margin: 5px auto;
                                display: block;
                                &.fb-save {
                                    width: 62px;
                                    overflow: hidden;
                                    height: 20px;
                                    border: 1px solid @colorBlueDark;
                                    border-radius: 2px;
                                    & > span {
                                        margin-top: -1px;
                                        margin-left: -1px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.disable,&.disable:hover {
                    background: @colorWhite;
                    .contact {
                        color: @colorBorderGrayLight;
                        .label {
                            background: @colorWhite;
                            color: @colorBorderGrayLight;
                        }
                    }
                }

                &.was-sent,&.was-sent:hover {
                    background: @colorBgGrayLighter;
                    .contact {
                        color: @colorFontGray;
                        .label {
                            background: @colorBgGrayLighter;
                            color: @colorFontGray;
                        }
                    }
                }

            }
        }
        .description-row {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 59.9%;
            border-top: 1px solid @colorBgGray;
            background: #FFFFFF;
            padding: 15px;
            font-size: 12px;
            color: @colorFontGray;
            -moz-transition:background-color 0.5s, color 0.5s;
            -webkit-transition:background-color 0.5s, color 0.5s;
            -o-transition:background-color 0.5s, color 0.5s;
            transition:background-color 0.5s, color 0.5s;
            &.highlight {
                background-color: @colorWarning;
                color: @colorRedError;
            }
        }
    }

    .availability {
        padding: 10px;
        .row {
            margin-right: -5px;
            margin-left: -5px;
            & > div {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        input, select {
            height: 50px;
        }
        .styled-select:before {
            top: 18px;
        }
    }

    .offer-price {
        .offer-price-container {
            padding: 15px 10px;
            background: #fff;
            font-size: 19px;
            text-align: left;
        }
        margin-bottom: 40px;
    }
    .room-list {
        margin-bottom: 40px;
        .entity-container {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .title-bar {
            background: @colorPink;
            color: #fff;
            overflow: hidden;
            padding: 5px 9px;
        }
        .booking {
            padding: 15px 10px;
            background: #fff;

            #total-price {
                display: none;
                font-size: 20px;
                text-align: center;
                margin-bottom: 15px;
                span {

                }
            }
        }

        .entity {
            background: #fff;

            .options {
                width: 100%;
                display: table;
                .header-row, .option-row {
                    display: table-row;
                    & > div {
                        display: table-cell;
                        padding: 5px 10px;
                        border-top: 1px solid @colorBgGray;
                        vertical-align: middle;
                    }
                    .conditions {
                        width: 35%;
                        &.no-price {
                            width: 60%;
                        }
                    }
                    .person {
                        width: 17%;
                    }
                    .price {
                        width: 25%;

                        &.check-availability {
                            width: 25%;

                            button {
                                width: 100%;
                            }
                        }
                    }
                    .quantity {
                        width: 23%;
                    }
                }
                .option-row {
                    & > div {
                        padding: 10px;
                    }
                    .conditions {
                        font-size: 20px;
                        line-height: 1.2em;
                        h3 {
                            margin: 0;
                        }
                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            li {
                                margin: 0;
                                padding: 0;
                                &:before {
                                    content: "-";
                                    margin-right: 5px;
                                }
                            }
                        }
                        a {
                            font-size: 12px;
                        }
                    }
                    .person {
                        border-left: 1px solid @colorBgGray;
                        text-align: center;
                        color: @colorFontGray;
                        font-size: 18px;
                        i {
                            font-size: 24px;
                            letter-spacing: -8px;
                            margin-left: -10px;
                            vertical-align: middle;
                            display: inline-block;
                            padding-bottom: 5px;
                        }
                    }
                    .price {
                        border-left: 1px solid @colorBgGray;
                        text-align: right;
                        .total-price {
                            color: @colorPink;
                            font-size: 19px;
                        }
                        .price-per-day {
                            color: @colorFontGray;
                            font-size: 12px;
                        }
                    }
                    .quantity {
                        border-left: 1px solid @colorBgGray;
                        text-align: center;
                    }
                }

                .entity-details-iframe {
                    color: @colorPink;
                    &:hover {
                        color: @colorPinkDarker;
                    }
                }

            }
        }
    }

    .offer-content {
        .nav-offer-content {
            li  {
                display: inline-block;

                a {
                    padding: 15px;
                    text-align: center;
                    border: none;
                    border-radius: 0;
                    background: @colorPink;
                    color: #fff;
                    &:hover {
                        background: @colorPinkDarker;
                    }
                }
                &.active {
                    a {
                        background: #fff;
                        color: @colorFontSpecialGray;
                    }
                }
            }
        }

        .offer-description {
            background: #fff;
            padding: 20px;
            font-weight: 400;
            color: #000;
            overflow: hidden;

            h2 {
                font-size: 20px;
                margin-bottom: 10px;
                margin-top: 15px;
            }
            ul.offer-amenity {
                list-style: none;
                margin: 0;
                padding: 0;
                overflow: hidden;
                li {
                    margin: 0;
                    margin-bottom: 10px;
                    line-height: 25px;
                    padding: 0;
                    padding-right: 10px;
                    float: left;
                    width: 33%;
                    .nc {
                        font-size: 20px;
                        vertical-align: text-bottom ;
                        color: @colorBlue;
                        &:before {
                            color: @colorMarineGreen;
                        }
                    }
                    &:before {
                        margin-right: 5px;
                    }
                }
            }
            .stats-total-views {
                text-align: right;
                font-size: 12px;
            }
        }
        .offer-specials,
        .offer-special-details {
            background: #fff;
            padding: 20px;
            .row {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            h4 {
                margin-top: 0;
                margin-bottom: 5px;
                span {
                    background: #fff;
                }
            }
            img {
                margin: 0 auto;
            }

            .title {
                font-size: 22px;
                line-height: 24px;
            }

            .desc {
                font-weight: 300;
                margin-bottom: 10px;
            }

            .price {
                border: 1px solid @colorBorderGray;
                background: @colorBgGray;
                color: @colorFontGray;
                font-weight: 300;
                text-align: center;
                padding: 20px;
                span {
                    color: @colorPink;
                    font-size: 26px;
                    font-weight: 500;
                }
            }
        }

        .offer-specials {
            .section-title {
                font-size: 14px;
                margin-bottom: 10px;

                span {
                    color: @colorFontBlue;
                    font-weight: 600;
                }
            }
        }

        .offer-special-details {
            position: relative;

            .icon {
                i,
                a {
                    display: inline-block;
                }
            }

            .icon-offer-special,
            .icon-offer-special-wide {
                margin-bottom: 10px;
            }

            .category.title {
                position: absolute;
                top: 0;
                left: 0;

                color: @colorFontBlue;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding: 35px 0 10px 15px;
            }

            .desc-title {
                position: absolute;
                top: 0;
                left: 0;

                font-size: 26px;
                margin: 65px 0 0 0;
                padding: 0 0 10px 15px;
                max-width: 50%;
            }

            .icon-close {
                position: absolute;
                top: 0;
                right: 0;
                width: 30px;
                height: 30px;
                line-height: 30px;
                color: #fff;
                background: @colorBgBlue;
                text-align: center;
                margin-right: 15px;
            }

            .button-phone {
                padding: 5px 35px;
                display: inline-block;
                i {
                    margin-right: 5px;
                    font-size: 15px;
                }
            }
            .offer-contact {
                ul {
                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .offer-neighborhood {
            background: #fff;
            padding: 20px;
            .row {
                margin-bottom: 5px;
            }
            & > .row {
                margin-bottom: 20px;
            }
            h4 {
                margin-top: 0;
                margin-bottom: 5px;
                span {
                    background: #fff;
                }
            }
            img {
                margin: 0 auto;
            }
            font-weight: 300;
            .title {
                font-weight: 500;
            }
        }

    }
    .offer-tags-content {
        margin-top: 20px;
        font-size: 18px;
    }

    .map {
        padding: 5px;
        border: 1px solid @colorBorderGray;
        #gmap {
            width: 100%;
            height: 500px;
        }
    }

    #inquiry-form-container {
        position: relative;
        .loading-overlay {
            background: @colorBgGray;
        }
        form#inquiry-form {
            div[class*=col-] {
                margin-bottom: 10px;
            }
            textarea {
                height: 120px;
            }
            label.error {
                margin-bottom: 0;
            }
        }
    }

    .rating-details {
        .rate {
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 5px;
            line-height: 18px;
            & > .star-rating {
                margin-right: 5px;
                vertical-align: top;
            }
        }
    }
}

.sidebar-recommended {
    .offer-cell {
        margin-bottom: 25px;
        margin-left: -10px;
        margin-right: -10px;
        div[class*=col-] {
            padding-left: 10px;
            padding-right: 10px;
        }

        .description {
            a {
                color: inherit;
            }
            p {
                margin-bottom: 2px;
                font-size: 13px;
            }
            .name {

            }
            .type {
                font-size: 12px;
            }
            .distance {
                color: @colorFontGray;
                font-size: 12px;
            }
        }
    }
}



.offer-availability {
    @keyframes yellow-fade {
        0% {background: #FFFFFF;}
        10% {background: #ffee84;}
        100% {background: #FFFFFF;}
    }
    .highlight {
        animation: yellow-fade 1s ease-in 1;
    }
}


.offer-availability-form {
    padding: 10px;
    margin-bottom: 40px;

    .styled-select {
        display: block;
        &:before {
            top: 18px;
        }
    }

    select, input[type="text"] {
        height: 50px;
    }

    input[type="submit"] {
        width: 100%;
    }
}

@media (min-width: @screen-sm-min) {
    .modal-entity-details {
        .modal-dialog {
            width: 80%;
            max-width: 1200px;
        }
    }
}

@media (max-width: @screen-md-max) {
    .offer-presentation {
        .offer-main {
            .photo {
                img {
                    height: 411px;
                }
            }
        }
        .room-list {
            .entity {
                .photo {
                    img {
                        height: 162px;
                    }
                }
            }
        }
    }

}
@media (max-width: @screen-sm-max) {
    .offer-presentation {
        .offer-main {
            .photo {
                img {
                    height: 314px;
                }
            }
        }
        .room-list {
            .entity {
                .photo {
                    img {
                        height: 121px;
                    }
                }
            }
        }

        .offer-info {
            display: block;
            .offer-info-row {
                display: block;
                overflow: hidden;
                .address {
                    display: block;
                    width: 100%;
                    border-right: none;
                }
                .contact-cell {
                    border-top: 1px solid @colorBgGray;
                    width: 25%;
                    display: block;
                    float: left;
                }

                .alert-cell {
                    display: block;
                    margin: 20px 0;
                    text-align: center;
                }
            }
            .description-row {
                width: 100%;
            }
        }
    }
}

.offer-weather-widget {
    .weather-widget {
        max-width: 210px;
        background: white;
        border: 1px solid @colorBorderGray;
        padding: 15px;
        margin: 15px 0;
        text-align: right;
        .icon {
            float: left;
            margin: 0 5px;
        }
        .temperature {
            font-size: 20pt;
        }
        a {
            margin-top: 10px;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .offer-presentation {
        .offer-main {
            .offer-header-bar {
                .corner-contact {
                    height: 45px;
                    & > div {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            .photo {
                img {
                    height: auto;
                    max-height: 435px;
                }
            }
            h2 {
                font-size: 24px;
            }

            .gallery {
                .carousel-inner {
                    .item {
                        height: 475px;
                    }
                }
            }
        }

        .offer-specials,
        .offer-special-details {
            .section-title {
                &:after {
                    top: 28px;
                }

                .icon-offer-special {
                    margin-right: 5px;
                }
            }
        }

        .offer-content {
            .offer-special-details {
                .category.title {
                    position: relative;
                    padding: 0;
                }

                .desc-title {
                    position: relative;
                    margin: 0;
                    padding: 0;
                    max-width: none;
                }
            }
        }

        .offer-info {
            display: block;
            .offer-info-row {
                display: block;
                overflow: hidden;
                .address {
                    display: block;
                    width: 100%;
                    border-right: none;
                }
                .contact-cell {
                    border-top: 1px solid @colorBgGray;
                    width: 25%;
                    display: block;
                    float: left;
                }

                .alert-cell {
                    display: block;
                    margin: 20px 0;
                    text-align: center;
                }
            }
            .description-row {
                width: 100%;
            }
        }
        .room-list {
            .entity {
                .photo {
                    img {
                        height: auto;
                        max-height: 236px;
                    }
                }
            }
        }
    }
}

@media (max-width: @screen-phone-vertical-max) {
    .offer-presentation {
        .offer-main {
            .offer-header-bar {
                .corner-contact {
                    width: 100%;
                    float: none;
                    margin: 0;
                    height: auto;
                    overflow: auto;
                    text-align: center;

                    .corner-phone {
                        margin: 15px 0 15px 15px;
                    }

                    .corner-email {
                        margin: 15px 15px 15px 0;
                    }

                    .corner-email,
                    .corner-phone {
                        font-size: 18px;
                        width: auto;
                        display: inline-block;
                        padding: 0 15px;
                        height: 40px;
                        line-height: 40px;
                        float: none;

                        .text {
                            display: none;
                        }
                    }

                    .corner-email {
                        margin-left: 15px;
                    }

                    .corner-phone {
                        width: 50px;
                    }

                    .corner-favorite {
                        display: inline-block;
                        float: none;
                        margin: 15px 0 15px 15px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }

            .gallery {
                .carousel-inner {
                    .item {
                        height: 275px;
                    }
                }
            }
        }

        .offer-info {
            .offer-info-row {
                .contact-cell {
                    width: 50%;

                    .contact {
                        margin-bottom: 0;
                    }

                    &:nth-last-of-type(-n + 2) {
                        .contact {
                            margin-bottom: 60px;
                        }
                    }
                }
            }
        }

        .offer-content {
            .nav-offer-content {
                li {
                    a {
                        padding: 15px 5px;
                    }
                }
            }
            .offer-description {
                ul.offer-amenity {
                    li {
                        float: none;
                        width: 100%;
                    }
                }
            }
        }
    }
}