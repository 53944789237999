.dictionary-types {
    .types-list {
        padding-left: 0;
        
        li {
            color: #483f3f;
            list-style-type: none;

            &:nth-child(-n+3) {
                span.link-container {
                    border-top: 1px solid @colorBorderGray;
                }
            }

            a {
                color: #483f3f;
                text-transform: capitalize;
                font-weight: 300;

                &:before {
                    display: inline-block;
                    background: @colorPink;
                    width: 5px;
                    height: 5px;
                    margin: 2pt 20px 2pt 0;
                    content: " ";
                }
            }

            span.link-container {
                display: block;
                border-bottom: 1px solid @colorBorderGray;
                padding: 15px;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .dictionary-types {
        .types-list {
            li:nth-child(-n+3) {
                span.link-container {
                    border-top: none;
                }
            }
        }
    }
}