section.contact {
    margin-bottom: 30px;

    .page-title {
        position: relative;

        hr {
            position: absolute;
            border: none;
            background: @colorBorderGray;
            height: 2px;
            top: 45px;
            left: 0;
            margin: 0;
            width: 100%;
            z-index: -1;
        }

        h1 {
            background: @colorWhite;
            max-width: 200px;
            margin: auto;
        }
    }

    .contact-subtitle {
        font-size: 40px;
        color: @colorMarineGreen;
        font-weight: 300;
    }

    .contact-desc {
        font-size: 24px;
        color: @colorFontGray;
        font-weight: 300;
    }

    .form-contact {
        margin-top: 30px;
        .form-element {
            margin-bottom: 20px;

            input,
            textarea {
                padding: 20px;
            }

            textarea {
                height: auto;
            }
        }

        .form-submit {
            padding-top: 15px;
            input {
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .agreement {
            font-size: 12px;
            font-weight: 300;
            color: #acacac;

            .desc {
                padding: 10px 5px 10px 28px;
                background: url('/img/icons/lock.png') no-repeat left center;
                background-size: 18px;
            }

            label {
                font-weight: 300;

                &.error {
                    font-weight: normal;
                    font-size: 14px;
                }
            }
        }
    }

    .contact-data {
        height: 550px;
        background: url('/img/contact-icons.png') no-repeat left center;
        background-size: 200px 550px;
        color: @colorFontGray;
        font-weight: 300;

        .number,
        .email a {
            font-size: 24px;
            color: @colorPink;
            font-weight: 600;
        }

        .phone {
            font-size: 24px;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .email {
            padding-top: 160px;
            padding-bottom: 130px;
        }
    }

    .phone,
    .email,
    .company-data {
        padding-left: 250px;
    }

}

@media (max-width: @screen-xs-max) {
    section.contact {
        .contact-data {
            margin-top: 30px;
            margin-bottom: 30px;
            height: 375px;
            background: url('/img/contact-icons.png') no-repeat left center;
            background-size: 100px 275px;

            .phone,
            .email,
            .company-data {
                padding-left: 125px;
            }

            .number,
            .email a {
                font-size: 12px;
            }

            .phone {
                font-size: 12px;
                padding-top: 60px;
                padding-bottom: 5px;
            }

            .email {
                padding-top: 80px;
                padding-bottom: 45px;
            }
        }
    }
}