// fancybox prevent scroll to top
.fancybox-lock, .fancybox-lock body {
    height: auto;
}

.ui-helper-hidden-accessible {
    display: none;
}

.date-picker-wrapper .month-wrapper table .day.real-today {
    background-color: @colorWhite !important;
}

.date-picker-wrapper .month-wrapper table .day.checked {
    background-color: @colorPink !important;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.hovering {
    background-color: @colorPinkLightest !important;
}

.date-picker-wrapper .month-wrapper table .day.first-date-selected,
.date-picker-wrapper .month-wrapper table .day.last-date-selected {
    background-color: @colorPinkDarker !important;
}

.date-picker-wrapper table .caption .next,
.date-picker-wrapper table .caption .prev {
    color: @colorPinkDarkest !important;
}