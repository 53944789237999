.street-list {
    ul {
        max-height: 80px;
        overflow: hidden;
        li {
            width: 33.33%;
            float: left;
        }
    }
    .btn-link {
        color: @colorPink;
    }
}

.search-banner {
    background: @colorMarineGreen;
    width: 100%;
    height: 100px;
    position: relative;
    .banner-background {
        background: url('/img/search-banner/background.png') left no-repeat;
        background-size: 305px 100px;
        width: 100%;
        height: 100px;
        position: absolute;
    }
    .banner-text {
        color: @colorWhite;
        font-size: 11pt;
        position: absolute;
        left: 300px;
        top: 30px;
    }
    .banner-button {
        position: absolute;
        right: 20px;
        top: 22px;
        text-align: right;

        & > span {
            display: block;
            color: @colorWhite;
            font-size: 12px;
        }
        & > a {
            padding: 0;
            line-height: 40px;
            height: 40px;
            font-size: 16px;
            width: 200px;
            text-align: center
        }

    }
}

.offer-special-title-link {
    font-size: 15px;
    color: @colorFontGrayDark;
}

.offer-special-header {
    position: relative;
    line-height: 160px;
    .offer-special-background {
        position: absolute;
        width: 100%;
    }
    .offer-special-content {
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        i {
            width: 100px;
            height: 100px;
            background-size: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px;

            &.icon-offer-special-narty {
                margin-left: 25px;
            }
        }

        .offer-special-title {
            display: inline-block;
            vertical-align: middle;
            h1 {
                color: @colorBlue;
                display: block;
                margin: 0;
                font-size: 30px;
            }
            h2 {
                margin: 0;
                font-size: 19px;
                color: @colorFontGrayDark;
            }
        }
    }

}

.search-results-sidebar {
    .section-title {
        font-size: 20px;
    }

    form#search-form {
        margin-bottom: 50px;
        margin-top: 35px;

        .styled-checkbox {
            label {
                &:before {
                    border: 1px solid @colorBlue;
                }
            }
        }
        #fieldset-basic {
            margin-top: 0;
        }
    }
    .weather-widget {
        max-width: 210px;
        background: white;
        border: 1px solid @colorBorderGray;
        padding: 15px;
        margin: 15px auto;
        text-align: right;
        .icon {
            float: left;
            margin: 0 5px;
        }
        .temperature {
            font-size: 20pt;
        }
        a {
            margin-top: 10px;
        }
    }
}

.line-header {
    overflow: hidden;
    &:after{
        margin-left: 70px;
    }
}

.active-filters {
    .filter,
    .reset-filters {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid @colorBorderGray;
        background: none;
        font-weight: 300;
        font-size: 15px;
        color: @colorFontGrayDark;
        margin-bottom: 3px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background: @colorBgOrange;
        }

        & > i {
            color: @colorPink;
            margin-right: 8px;
        }

        .remove-filter {
            color: @colorFontGray;
            margin-left: 5px;
        }
    }
}

.search-results {
    #search-form-sort {
        margin-top: 7px;
    }

    .section-title {
        position: relative;
        .title {
            padding-top: 7px;
            font-size: 25px;
            font-family: Dosis;
            line-height: 1.3;
        }
        .sort-by {
            width: 100%;
            padding-left: 30px;
            z-index: 1;
        }
    }
    .result-list {
        .no-result-container {
            margin-bottom: 48px;
            p:nth-child(1) {
                color: @colorPink;
                font-size: 32px;
                font-weight: 300;
            }
            p:nth-child(2) {
                font-size: 16.5px;
                font-weight: 300;
            }
        }
        .offer {
            background: #fff;
            margin-bottom: 20px;
            .row {
                margin-left: -10px;
                margin-right: -10px;
            }
            div[class*='col'] {
                padding-left: 10px;
                padding-right: 10px;
            }
            .image-partner {
                width: 25%;
                position: absolute;
                height: auto;
                top: -3px;
                left: 3px;
                z-index: 2;
                max-width: 62px;
            }
            .image {
                width: 100%;
                overflow: hidden;
                position: relative;
                .carousel.gallery {
                    .carousel-control {
                        .arrow {
                            padding: 8px 10px;
                        }
                    }
                    .carousel-inner {
                        .loading-overlay {
                            z-index: 1;
                        }
                        & > .item {
                            transition: transform 0.35s ease-in-out;
                        }

                        .item.loader {
                            margin-top: 50%;
                        }
                    }
                }
                img {
                    width: 100%;
                    height: auto;

                    &.progress-circle-indeterminate {
                        width: 50px;
                        height: 50px;
                        display: block;
                        padding-top:50%;
                        margin-top: -50px;
                    }
                }
                .price,
                .photos-navi {
                    background: rgba(23, 183, 155, 0.7);
                    padding: 5px 15px;
                    position: absolute;
                    bottom: 0;
                    color: #fff;
                    font-size: 14px;
                    line-height: 30px;
                    z-index: 2;
                    i {
                        margin-top: 5px;
                        margin-right: 10px;
                        font-size: 20px;
                        float: left;
                    }
                }
                .favorite-addon {
                    background: rgba(23, 183, 155, 0.7);
                    padding: 5px 15px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: #fff;
                    font-size: 14px;
                    line-height: 30px;
                    z-index: 2;
                    i {
                        font-size: 20px;
                        vertical-align: middle;
                    }
                }
                .price {
                    font-size: 16px;
                    span {
                        font-size: 20px;
                    }
                    small {
                        display: block;
                        margin-top: -5px;
                        white-space: nowrap;
                        line-height: 15px;
                        font-size: 12px;
                    }
                }
                .partner {
                    position: absolute;
                    top: -4px;
                    left: -4px;
                }
            }
            .details {
                padding: 10px 0px;
                padding-right: 20px;
                position: relative;
                height: 227px;
                overflow: hidden;

                h3 {
                    margin: 0;
                    color: @colorBlue;
                    font-size: 16pt;
                    margin-bottom: 5px;
                    padding-right: 115px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .price {
                    position: absolute;
                    right: 20px;
                    top: 5px;
                    color: @colorFontGray;
                    width: 115px;
                    text-align: right;
                    font-weight: 300;
                    span {
                        color: @colorPink;
                        font-size: 18pt;
                    }
                    small {
                        display: block;
                        margin-top: -3px;
                        white-space: nowrap;
                    }
                }
                .address {
                    .city,
                    .street-address {
                        white-space: nowrap;
                    }
                }
                .type,
                .modal-map {
                    margin-bottom: 7px;
                    a:first-child {
                        color: inherit;
                    }
                    a, span {
                        color: @colorFontGray;
                        &.open-modal-map {
                            white-space: nowrap;
                            &:hover{
                                color: @colorPink;
                                i {
                                    color: @colorPink;
                                }
                            }
                        }
                    }
                }
                .type {
                    font-weight: 300;
                    font-size: 9pt;
                    margin-bottom: 10px;
                    a:first-of-type{
                        color: @colorBlack;
                    }
                    &.rating-visible {
                        padding-right: 105px;
                    }
                }
                .modal-map:hover {
                    cursor: pointer;
                }

                .rating {
                    float: right;
                    text-align: right;
                    margin-top: -3px;
                    .star-empty {
                        background: url("/img/rating_star_empty.png");
                        float: left;
                        width: 92px;
                        height: 16px;
                        margin-right: 5px;
                        .star {
                            background: url("/img/rating_star.png");
                            width: 72px;
                            height: 16px;
                        }
                    }
                    p {
                        margin: 0;
                        font-size: 10pt;
                        line-height: 18px;
                    }
                }

                .highlight-text {
                    padding: 1px 5px;
                    background: @colorBgRed;
                    color: #fff;
                    margin-bottom: 5px;
                    font-size: 10pt;
                    display: inline-block;
                }

                .offer-tags {
                    margin-bottom: 5px;
                }

                .description {
                    font-weight: 400;
                    font-size: 9pt;
                    line-height: 15pt;
                    max-height: 115px;
                    overflow: hidden;
                    position: relative;
                    color: @colorFontGray;
                }

                .details-footer {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 10px;
                    background: #FFFFFF;

                    .description-fade {
                        position: absolute;
                        height: 30px;
                        top: -30px;
                        width: 100%;
                        .gradient(@startColor: rgba(255,255,255,0), @endColor: rgba(255,255,255,1), @directionFrom: bottom, @directionTo: top, @stopPercent: 100%, @startColorIE: rgba(255,255,255,0), @endColorIE: rgba(255,255,255,1));
                    }

                }

                .entities {
                    font-weight: 400;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    color: lighten(@colorFontGrayDark, 10%);

                    .room-capacity {
                        margin-right: 10px;

                        i {
                            font-size: 25px;
                            color: @colorBorderGray;
                            letter-spacing: -10px;
                            vertical-align: middle;
                            padding-bottom: 5px;
                            display: inline-block;
                        }
                    }
                    p {
                        font-size: 80%;
                        margin: 2px 0 0 0;
                        color: @colorFontGray;
                    }
                }

                .buttons {
                    *[class*=button-] {
                        height: 40px;
                        line-height: 40px;
                        width: 100%;
                        position: relative;
                        text-align: center;
                        i {
                            position: relative;
                            top: 1px;
                            margin-right: 8px;
                            color: @colorPink;
                            font-size: 20px;
                        }
                        &:hover {
                            color: @colorWhite;
                            i {
                                color: @colorWhite;
                            }
                        }
                    }
                    .button-phone {
                        i {
                            color: @colorWhite;
                        }
                    }
                    .was-sent {
                        color: @colorFontGray;
                        background: @colorBorderGrayLight;
                        border: 1px solid @colorFontGrayLight;
                        i {
                            color: @colorFontGray;
                        }
                    }
                }
            }

            &.offer-partner {
                background: @colorBgOfferPartner;

                .details {
                    .details-footer {
                        background: @colorBgOfferPartner;

                        .button-white {
                            background: transparent;
                            border: 1px solid @colorBorderButtonOfferPartner;

                            &:hover {
                                background: @colorBgButtonOfferPartner;
                                color: @colorFontGray;

                                i {
                                    color: @colorPink;
                                }
                            }
                        }

                        .was-sent {
                            color: @colorFontGray;
                            background: @colorPinkLightest;
                            border: 1px solid @colorFontGrayLight;

                            &:hover {
                                i {
                                    color: @colorFontGray;
                                }
                            }
                        }

                        .description-fade {
                            .gradient(@startColor: rgba(255,252,197,0), @endColor: rgba(255,252,197,1), @directionFrom: bottom, @directionTo: top, @stopPercent: 100%, @startColorIE: rgba(255,252,197,0), @endColorIE: rgba(255,252,197,1));
                        }
                    }
                }
            }

            &.special-result-item {
                .details {
                    .type {
                        margin-right: 115px;
                    }
                }
            }
        }
    }
}
@media (max-width: @screen-md-max) {
    .street-list {
        ul {
            li {
                width: 50%;
            }
        }
    }
    .offer-special-header {
        .offer-special-content {
            i {
                width: 80px;
                height: 80px;

                &.icon-offer-special-narty {
                    margin-left: 22px;
                }
            }
        }
    }
    .search-results {
        .result-list {
            .offer {
                .row {
                    margin-left: -5px;
                    margin-right: -5px;
                }
                div[class*='col'] {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                .details {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 10px;
                    height: 241px;
                    .entities {
                        .available-number {
                            max-width: 210px;
                        }
                    }
                    .buttons {
                        *[class*=button-] {
                            font-size: 12pt;
                        }
                    }
                }
            }
        }
    }
    .search-banner {
        .banner-background {
            display: none;
        }
        .banner-text {
            left: 20px;
        }
    }


}

@media (max-width: @screen-sm-max) {
    .offer-special-header {
        .offer-special-content {
            i {
                width: 70px;
                height: 70px;

                &.icon-offer-special-narty {
                    margin-left: 15px;
                }
            }
        }
    }

    .search-banner {
        .banner-text {
            font-size: 18px;
            top: 25px;
        }
        .banner-button {
            & > a {
                width: 160px;
            }
        }
    }

    .search-results {
        .result-list {
            .offer {
                &.special-result-item {
                    .details {
                        .type {
                            margin-right: 0px;
                        }
                    }
                }
                .details {
                    .highlight-text {
                        font-size: 10pt;
                    }
                }
            }
        }
    }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .search-results {
        .result-list {
            .offer {
                .image-partner {
                    left: 2px;
                }
                .details {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 10px;

                    .entities {
                        .available-number {
                            max-width: 210px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: @screen-md-min) {
    .search-results {
        .result-list {
            .offer {
                .details-footer {
                    padding-right: 10px;
                }

                .image {
                    cursor: pointer;
                }
            }
        }
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .search-results {
        .result-list {
            .offer {
                .image-partner {
                    top: -4px;
                    left: 6px;
                }
                .image {

                }
                .details {
                    height: 183px;
                    h3 {
                        padding-right: 0;
                    }
                    .entities {
                        .available-number {
                            max-width: 280px;
                            float: none;
                            margin-top: 5px;
                            margin-left: -7px;
                        }
                    }
                    .buttons {
                        *[class*=button-] {
                            font-size: 10.5pt;
                        }
                    }
                }
                .details-footer {
                    padding-right: 10px;
                }
            }
        }
    }
}

@media (min-width: @screen-lg-min) {
    .search-results {
        .result-list {
            .offer {
                .image-partner {
                    left: 6px;
                }
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .street-list {
        ul {
            li {
                width: 100%;
                float: none;
            }
        }
    }
    .offer-special-header {
        line-height: 100px;
        .offer-special-content {
            i {
                width: 60px;
                height: 60px;
                margin: 0;
            }
        }
    }

    .search-results {
        .result-list {
            .offer {
                height: auto;
                .image {
                    margin-top: -5px;
                    width: auto;
                    height: auto;
                    float: none;
                    img {
                        width: 100%;
                        height: auto;
                    }

                    .price {
                        right: 0;
                    }
                }

                .details {
                    width: auto;
                    height: auto;
                    margin-left: 0;
                    padding: 5px 15px;
                    float: none;
                    h3 {
                        padding-right: 0;
                        font-size: 15pt;
                    }

                    .details-footer {
                        position: static;
                    }
                    .entities {
                        .available-number {
                            max-width: 280px;
                            float: none;
                            margin-top: 5px;
                            margin-left: -7px;
                        }
                    }
                    .buttons {
                        *[class*=button-] {
                            font-size: 11pt;
                            i {
                                font-size: 17px;
                                margin-left: 2px;
                                margin-right: 2px;
                            }
                        }
                    }
                }
                div[class*='col'] {
                    padding-top: 5px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .search-results {
        .result-list {
            .offer {
                .details {
                    .entities {
                        display: none;
                    }
                    .buttons {
                        i {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}