@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Pru33qjShpZSmG3z6VYwnbO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/Xu_FYwbs8k0teWf0uC8JpevvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 400;
  src: url('/build/fonts/bariol/Bariol_Regular.eot?#iefix');
  src: local('Bariol'), local('Bariol-Regular'), url('/build/fonts/bariol/Bariol_Regular.eot?#iefix') format('embedded-opentype'), url('/build/fonts/bariol/Bariol_Regular.otf') format('otf'), url('/build/fonts/bariol/Bariol_Regular.woff') format('woff'), url('/build/fonts/bariol/Bariol_Regular.ttf') format('truetype'), url('/build/fonts/bariol/Bariol_Regular.svg#Bariol-Regular') format('svg');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url('/build/fonts/Dosis/Dosis-Regular.eot?#iefix');
  src: local('Dosis'), local('Dosis-Regular'), url('/build/fonts/Dosis/Dosis-Regular.ttf') format('truetype');
}
html,
body {
  font-family: Roboto;
  height: 100%;
}
a {
  color: #17b79b;
}
a:hover,
a:focus {
  color: #0c5c4e;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-family: Dosis;
  line-height: 1.3;
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: auto;
  }
}
.bg-white {
  background: #fff;
}
.bg-gray {
  background: #f0eeef;
}
.bg-blue {
  background: #44a0d0;
}
.button-marine-green,
.button-green,
.button-white,
.button-gray,
.button-blue,
.button-pink {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
}
.button-marine-green.btn-block,
.button-green.btn-block,
.button-white.btn-block,
.button-gray.btn-block,
.button-blue.btn-block,
.button-pink.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
.button-marine-green:hover,
.button-green:hover,
.button-white:hover,
.button-gray:hover,
.button-blue:hover,
.button-pink:hover {
  text-decoration: none;
  color: #fff;
}
.button-marine-green.btn-const,
.button-green.btn-const,
.button-white.btn-const,
.button-gray.btn-const,
.button-blue.btn-const,
.button-pink.btn-const {
  width: 230px;
  text-align: center;
}
.button-marine-green.btn-xs,
.button-green.btn-xs,
.button-white.btn-xs,
.button-gray.btn-xs,
.button-blue.btn-xs,
.button-pink.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
.button-marine-green.btn-xs.btn-const,
.button-green.btn-xs.btn-const,
.button-white.btn-xs.btn-const,
.button-gray.btn-xs.btn-const,
.button-blue.btn-xs.btn-const,
.button-pink.btn-xs.btn-const {
  width: 80px;
}
.button-marine-green.btn-sm,
.button-green.btn-sm,
.button-white.btn-sm,
.button-gray.btn-sm,
.button-blue.btn-sm,
.button-pink.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
.button-marine-green.btn-sm.btn-const,
.button-green.btn-sm.btn-const,
.button-white.btn-sm.btn-const,
.button-gray.btn-sm.btn-const,
.button-blue.btn-sm.btn-const,
.button-pink.btn-sm.btn-const {
  width: 80px;
}
.button-marine-green.btn-md,
.button-green.btn-md,
.button-white.btn-md,
.button-gray.btn-md,
.button-blue.btn-md,
.button-pink.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
.button-marine-green.btn-md.btn-const,
.button-green.btn-md.btn-const,
.button-white.btn-md.btn-const,
.button-gray.btn-md.btn-const,
.button-blue.btn-md.btn-const,
.button-pink.btn-md.btn-const {
  width: 80px;
}
.button-marine-green[disabled],
.button-green[disabled],
.button-white[disabled],
.button-gray[disabled],
.button-blue[disabled],
.button-pink[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.btn-const + .btn-const {
  margin-top: 5px;
}
.button-pink {
  background: #ef366c;
  border-bottom: 4px solid #dd1c55;
}
.button-pink:hover {
  background: #f14e7d;
}
.button-blue {
  background: #449fd1;
  border-bottom: 4px solid #3b8bb6;
}
.button-blue:hover {
  background: #58aad6;
}
.button-gray {
  background: #736c6c;
  border-bottom: 4px solid #483f3f;
}
.button-gray:hover {
  background: #807878;
}
.button-white {
  background: #ffffff;
  border: 1px solid #ef366c;
  color: #736c6c;
}
.button-white:hover {
  color: #ffffff;
  background: #ef366c;
}
.button-green {
  background: #15C481;
  border-bottom: 4px solid #13ad72;
}
.button-green:hover {
  background: #16cd87;
}
.button-marine-green {
  background: #17b79b;
  border-bottom: 4px solid #14a088;
}
.button-marine-green:hover {
  background: #18c0a3;
}
.button-phone {
  background: #17b79b;
  color: #ffffff;
  cursor: pointer;
}
.button-phone i {
  color: #ffffff;
}
.button-phone:hover {
  background: #1ee3c0;
}
.button-o {
  background: transparent;
  border: 1px solid #d7d2d2;
  color: #736c6c;
}
.button-o:hover {
  background: #f1f1f1;
}
.button-close {
  color: #D8000C;
  border: none;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  line-height: 16px;
  font-size: 12px;
  border-radius: 8px;
  background: #f0eeef;
  padding-right: 1px;
}
.button-close:hover {
  background: #fcfcfc;
}
h1.title {
  text-align: center;
  margin: 30px 0 50px;
}
.section-title {
  color: #5a505a;
  font-size: 25px;
  font-weight: normal;
  margin: 25px 0;
  text-align: center;
  position: relative;
  font-family: Dosis;
}
.section-title:after {
  border-bottom: 1px solid #b9b4b4;
  display: block;
  width: 100%;
  content: " ";
  height: 1px;
  line-height: 0px;
  position: absolute;
  margin-top: -13px;
}
.section-title span {
  background: #fff;
  color: #5a505a;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}
.section-title span a {
  color: inherit;
  text-decoration: inherit;
}
.section-title span a:hover {
  color: #333;
}
.section-title.left {
  text-align: left;
}
.section-title.left span {
  padding-left: 0;
}
h2.section-title {
  font-size: 25px;
}
h3.section-title {
  font-size: 20px;
}
h3.section-title:after {
  margin-top: -11px;
}
h4.section-title {
  font-size: 18px;
}
h4.section-title:after {
  margin-top: -10px;
}
.arrow-hook {
  margin: 0 auto;
  width: 110px;
  height: 20px;
  margin-top: -10px;
  background: #fff;
  border-radius: 5px;
}
.arrow-hook .arrow-down {
  margin: 0 auto;
  position: relative;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #b9b4b4 transparent transparent transparent;
  line-height: 0px;
}
.hook-marine-green {
  position: relative;
}
.hook-marine-green .line-hook {
  position: absolute;
  background: #17b79b;
  width: 100%;
  height: 15px;
}
.hook-marine-green .arrow-hook {
  background: #17b79b;
}
.hook-marine-green .arrow-hook .arrow-down {
  border-color: #ffffff transparent transparent transparent;
}
.section-row-title {
  padding: 50px 0;
}
.section-row-title h1 {
  text-align: center;
}
.section-row-title.section-bg-green h1,
.section-row-title.section-bg-red h1 {
  color: #fff;
}
.section-bg-gray {
  background: #f0eeef;
}
.section-bg-gray .section-title span,
.section-bg-gray .section-title div {
  background: #f0eeef;
}
.section-bg-gray .arrow-hook {
  background: #f0eeef;
}
.section-bg-gray .arrow-hook .arrow-down {
  border-color: #ef366c transparent transparent transparent;
}
.section-bg-pink {
  background: #ef366c;
}
.section-bg-pink .section-title {
  color: #fff;
}
.section-bg-pink .section-title span,
.section-bg-pink .section-title div {
  color: #fff;
  background: #ef366c;
}
.section-bg-pink .section-title:after {
  border-bottom: 1px solid #fff;
}
.section-bg-pink .arrow-hook {
  background: #ef366c;
}
.section-bg-pink .arrow-hook .arrow-down {
  border-color: #fff transparent transparent transparent;
}
.section-bg-blue {
  background: #44a0d0;
}
.section-bg-blue .section-title {
  color: #fff;
}
.section-bg-blue .section-title span,
.section-bg-blue .section-title div {
  color: #fff;
  background: #44a0d0;
}
.section-bg-blue .section-title:after {
  border-bottom: 1px solid #fff;
}
.section-bg-blue .arrow-hook {
  background: #44a0d0;
}
.section-bg-blue .arrow-hook .arrow-down {
  border-color: #fff transparent transparent transparent;
}
.section-bg-red {
  background: #ff4435;
}
.section-bg-red .section-title {
  color: #fff;
}
.section-bg-red .section-title span,
.section-bg-red .section-title div {
  color: #fff;
  background: #ff4435;
}
.section-bg-red .section-title:after {
  border-bottom: 1px solid #fff;
}
.section-bg-red .arrow-hook {
  background: #ff4435;
}
.section-bg-red .arrow-hook .arrow-down {
  border-color: #fff transparent transparent transparent;
}
.section-bg-green {
  background: #17b79b;
}
.section-bg-green .section-title {
  color: #fff;
}
.section-bg-green .section-title span,
.section-bg-green .section-title div {
  color: #fff;
  background: #15C481;
}
.section-bg-green .section-title:after {
  border-bottom: 1px solid #fff;
}
.section-bg-green .arrow-hook {
  background: #44a0d0;
}
.section-bg-green .arrow-hook .arrow-down {
  border-color: #fff transparent transparent transparent;
}
.section-bg-white .section-title span,
.section-bg-white .section-title div {
  background: #fff;
}
.menu-bar {
  background: #ffffff;
  padding: 5px 0;
}
.menu-bar button.btn-menu {
  font-size: 25px;
  color: #736c6c;
}
.menu-bar button.btn-menu:hover {
  color: #8d8585;
}
.menu-bar ul.header-menu {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu-bar ul.header-menu li {
  line-height: 20px;
  float: left;
  margin: 15px 0;
  margin-right: 14px;
  padding: 0;
  padding-left: 14px;
  font-weight: 300;
}
.menu-bar ul.header-menu li.line {
  border-left: 1px solid #736c6c;
}
.menu-bar ul.header-menu li a {
  color: #333;
}
.menu-bar ul.header-menu li i {
  margin-right: 15px;
  color: #736c6c;
}
.menu-bar img.brand {
  height: 50px;
}
.page-wrapper {
  min-height: 100%;
}
.page-wrapper.gray {
  background: #f0eeef;
}
.page-wrapper:after {
  content: "";
  display: block;
  margin-bottom: -265px;
}
section.footer,
.page-wrapper:after {
  height: 265px;
}
section.footer .fb-page,
.page-wrapper:after .fb-page {
  overflow: hidden;
}
section.footer {
  background: #2b4440;
  border-top: 10px solid #17b79b;
  color: #fff;
  font-weight: 300;
  padding: 20px 0;
}
section.footer .column-title {
  color: #fff;
  font-weight: normal;
  font-size: 16pt;
}
section.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
section.footer ul li:before {
  content: "-";
  margin-right: 5px;
}
section.footer ul li a {
  color: #fff;
}
section.footer .copyright {
  font-size: 10pt;
}
section.footer .btn-add-offer {
  margin-top: 10px;
}
section.default-section {
  margin-bottom: 70px;
}
.paginator {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 5px 0;
}
.paginator .items {
  display: inline-block;
}
.paginator .items span,
.paginator .items a {
  display: inline-block;
  border-radius: 13px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  background: #ffffff;
  color: #333;
}
.paginator .items a:hover {
  text-decoration: none;
  color: #fff;
  background: #f3658f;
}
.paginator .items a.active {
  background: #ef366c;
  color: #fff;
}
.paginator .items a.active:hover {
  background: #f3658f;
}
.paginator > a {
  display: inline-block;
  border-radius: 13px;
  width: 26px;
  height: 26px;
  color: #333;
  font-size: 17px;
}
.paginator > a:hover {
  color: #fff;
  background: #f3658f;
}
.breadcrumbs > li + li:before {
  padding: 0;
  content: normal;
}
.breadcrumbs {
  background: none;
  margin: 20px 0;
  padding: 0;
  color: #736c6c;
  font-size: 9pt;
  font-weight: 300;
}
.breadcrumbs > li {
  display: inline-block;
}
.breadcrumbs a {
  color: #736c6c;
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #333;
}
.breadcrumbs i {
  color: #ef366c;
  font-size: 7pt;
  margin: 0 5px;
  margin-bottom: 3px;
}
.breadcrumbs .breadcrumbs-separator:before {
  font-family: FontAwesome;
  font-style: normal;
  content: "\f054";
}
.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel.panel-default {
  border: 1px solid #f0eeef;
  border-radius: 0;
}
.panel.panel-default .panel-heading {
  background: transparent;
  border: none;
  padding-bottom: 0;
}
.star-rating {
  color: #ef366c;
  font-size: 17px;
  vertical-align: middle;
}
.star-rating .rating-count {
  color: #736c6c;
  margin-left: 5px;
  font-size: 85%;
}
ul.pink-round {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 5px;
}
ul.pink-round li {
  margin: 2px 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}
ul.pink-round li:before {
  content: " ";
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #ef366c;
  margin-right: 10px;
  margin-bottom: 2px;
  border-radius: 3.5px;
}
ul.pink-round li a {
  text-decoration: none;
  color: #736c6c;
}
ul.pink-round li a:hover {
  color: #333;
}
ul.pink-round li a span {
  color: #999292;
}
ul.pink-round li.active > a {
  color: #333;
  font-weight: 400;
}
ul.pink-round ul {
  list-style: none;
  padding-left: 20px;
}
.page-title {
  text-align: center;
  padding: 20px 0 30px 0;
}
.progress-circle-indeterminate {
  background: url("/img/progress-circle-master.svg") top left no-repeat;
  width: 50px;
  height: 50px;
  background-size: 100% auto;
  margin: 0 auto;
}
.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 10;
}
.loading-overlay .progress-circle-indeterminate {
  top: 50%;
  position: absolute;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
.validation-error {
  color: #ffffff;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  background-color: #ff4435;
  padding: 2px 5px;
  font-size: 12px;
}
.no-wrap {
  white-space: nowrap;
}
.alert-marine-green {
  background-color: #d3faf3;
  color: #0c5c4e;
  border: 1px solid #17b79b;
}
.alert-marine-green a {
  color: #ef366c;
}
.alert-marine-green a:hover,
.alert-marine-green a:focus {
  color: #f3004c;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a69c9c;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #a69c9c;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #a69c9c;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #a69c9c;
}
.sidebar-content .section-title {
  font-size: 20px;
  color: #333;
  font-family: Dosis;
  line-height: 1.3;
}
.sidebar-content .section-title:after {
  margin-top: -11px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .validation-error {
    padding: 2px 0px;
  }
}
@media (min-width: 768px) {
  .menu-bar .menu-container {
    display: block !important;
  }
}
@media (max-width: 767px) {
  section.footer .copyright {
    margin-top: 10px;
  }
  .menu-bar {
    padding-bottom: 0;
  }
  .menu-bar .menu-container {
    display: none;
    background: #f0eeef;
    padding: 0;
  }
  .menu-bar .menu-container ul.header-menu {
    float: none;
  }
  .menu-bar .menu-container ul.header-menu li {
    margin: 0;
    padding: 0;
    border-top: 1px solid #b9b4b4;
    float: none;
  }
  .menu-bar .menu-container ul.header-menu li.line {
    border-left: none;
  }
  .menu-bar .menu-container ul.header-menu li:last-child {
    border-bottom: 1px solid #b9b4b4;
  }
  .menu-bar .menu-container ul.header-menu li .fa {
    display: none;
  }
  .menu-bar .menu-container ul.header-menu li a {
    display: block;
    padding: 10px 15px;
  }
  .menu-bar .menu-container ul.header-menu li a:hover {
    text-decoration: none;
    background: #fcfcfc;
  }
  section.footer {
    height: 340px;
  }
  .page-wrapper:after {
    height: 340px;
    margin-bottom: -340px;
  }
}
@media (max-width: 480px) {
  .section-bg-blue .section-title {
    font-size: 22px;
  }
  .section-bg-blue .section-title span {
    padding: 0;
  }
  .section-bg-blue .section-title:after {
    border: none;
  }
  section.footer {
    height: 380px;
  }
  .page-wrapper:after {
    height: 380px;
    margin-bottom: -380px;
  }
}
.weather-picture {
  background: url('/img/weather-picture.png');
  width: 80px;
  height: 80px;
}
.weather-picture.icon-01 {
  background-position: 0px 0px;
}
.weather-picture.icon-02 {
  background-position: 0px -80px;
}
.weather-picture.icon-03 {
  background-position: 0px -160px;
}
.weather-picture.icon-04 {
  background-position: 0px -240px;
}
.weather-picture.icon-05 {
  background-position: 0px -320px;
}
.weather-picture.icon-06 {
  background-position: 0px -400px;
}
.weather-picture.icon-07 {
  background-position: 0px -480px;
}
.weather-picture.icon-08 {
  background-position: 0px -560px;
}
.weather-picture.icon-11 {
  background-position: 0px -640px;
}
.weather-picture.icon-12 {
  background-position: 0px -720px;
}
.weather-picture.icon-13 {
  background-position: 0px -800px;
}
.weather-picture.icon-14 {
  background-position: 0px -880px;
}
.weather-picture.icon-15 {
  background-position: 0px -960px;
}
.weather-picture.icon-16 {
  background-position: 0px -1040px;
}
.weather-picture.icon-17 {
  background-position: 0px -1120px;
}
.weather-picture.icon-18 {
  background-position: 0px -1200px;
}
.weather-picture.icon-19 {
  background-position: 0px -1280px;
}
.weather-picture.icon-20 {
  background-position: 0px -1360px;
}
.weather-picture.icon-21 {
  background-position: 0px -1440px;
}
.weather-picture.icon-22 {
  background-position: 0px -1520px;
}
.weather-picture.icon-23 {
  background-position: 0px -1600px;
}
.weather-picture.icon-24 {
  background-position: 0px -1680px;
}
.weather-picture.icon-25 {
  background-position: 0px -1760px;
}
.weather-picture.icon-26 {
  background-position: 0px -1840px;
}
.weather-picture.icon-29 {
  background-position: 0px -1920px;
}
.weather-picture.icon-30 {
  background-position: 0px -2000px;
}
.weather-picture.icon-31 {
  background-position: 0px -2080px;
}
.weather-picture.icon-32 {
  background-position: 0px -2160px;
}
.weather-picture.icon-33 {
  background-position: 0px -2240px;
}
.weather-picture.icon-34 {
  background-position: 0px -2320px;
}
.weather-picture.icon-35 {
  background-position: 0px -2400px;
}
.weather-picture.icon-36 {
  background-position: 0px -2480px;
}
.weather-picture.icon-37 {
  background-position: 0px -2560px;
}
.weather-picture.icon-38 {
  background-position: 0px -2640px;
}
.weather-picture.icon-39 {
  background-position: 0px -2720px;
}
.weather-picture.icon-40 {
  background-position: 0px -2800px;
}
.weather-picture.icon-41 {
  background-position: 0px -2880px;
}
.weather-picture.icon-42 {
  background-position: 0px -2960px;
}
.weather-picture.icon-43 {
  background-position: 0px -3040px;
}
.weather-picture.icon-44 {
  background-position: 0px -3120px;
}
.select2-drop.select2-drop-active {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
  border-top: none !important;
}
.select2-container.select2-dropdown-open.select2-container-active {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
}
.styled-select {
  position: relative;
  z-index: 1;
}
.styled-select:before {
  color: #736c6c;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d7";
  display: block;
  position: absolute;
  right: 15px;
  top: 9px;
  font-size: 16px;
  z-index: 1;
}
.styled-select .select2-container,
.styled-select select {
  display: block;
  border: 1px solid #b9b4b4;
  width: 100%;
  height: 34px;
  line-height: 21px;
  background: #fff;
  color: #5a505a;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 300;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.styled-select .select2-container.error,
.styled-select select.error {
  border-color: #D8000C;
}
.styled-select .select2-container a,
.styled-select select a {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.styled-select .select2-container a .select2-arrow,
.styled-select select a .select2-arrow {
  display: none;
  background: none;
  border: none;
}
.styled-select .select2-container a:focus,
.styled-select select a:focus {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
}
.styled-select .select2-container:focus,
.styled-select select:focus {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
}
input[type="text"],
input[type="password"] {
  display: block;
  border: 1px solid #b9b4b4;
  border-radius: 0;
  width: 100%;
  height: 34px;
  line-height: 21px;
  background: #fff;
  color: #5a505a;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 300;
}
input[type="text"].error,
input[type="password"].error {
  border-color: #D8000C;
}
input[type="text"]:focus,
input[type="password"]:focus {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
}
input[type="text"][disabled],
input[type="password"][disabled] {
  background: #f0eeef;
}
.intl-tel-input {
  width: 100%;
}
textarea {
  display: block;
  border: 1px solid #b9b4b4;
  border-radius: 0;
  width: 100%;
  height: 200px;
  line-height: 21px;
  background: #fff;
  color: #333;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 300;
}
textarea.error {
  border-color: #D8000C;
}
textarea:focus {
  outline: 0px solid #ef366c !important;
  border: 1px solid #ef366c !important;
}
form.greater .form-element input[type='text'] {
  height: 50px;
}
form.greater .form-element .styled-select {
  display: block;
}
form.greater .form-element .styled-select:before {
  top: 18px;
}
form.greater .form-element .styled-select select {
  height: 50px;
}
form.greater .form-element .input-group-btn .btn {
  height: 50px;
}
.styled-radiobox label:not(.error),
.styled-checkbox label:not(.error) {
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 5px;
}
.styled-radiobox label:not(.error):before,
.styled-checkbox label:not(.error):before {
  display: block;
  content: ' ';
  font: normal normal normal 14px/1 FontAwesome;
  line-height: 18px;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #b9b4b4;
  color: #17b79b;
  float: left;
  margin-right: 10px;
  padding-left: 1px;
  cursor: pointer;
}
.styled-radiobox input[type="radio"],
.styled-checkbox input[type="radio"],
.styled-radiobox input[type="checkbox"],
.styled-checkbox input[type="checkbox"] {
  display: none;
}
.styled-radiobox input[type="radio"]:checked + label:before,
.styled-checkbox input[type="radio"]:checked + label:before,
.styled-radiobox input[type="checkbox"]:checked + label:before,
.styled-checkbox input[type="checkbox"]:checked + label:before {
  content: '\f00c';
}
.styled-radiobox.inline,
.styled-checkbox.inline {
  padding-left: 0;
  line-height: 34px;
}
.styled-radiobox.inline label,
.styled-checkbox.inline label {
  margin-bottom: 0;
  margin-right: 20px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}
.ui-datepicker {
  background: #fff;
  width: 210px;
  padding: 5px;
  border: 1px solid #f0eeef;
  display: none;
}
.ui-datepicker .ui-datepicker-group {
  width: 48%;
  float: left;
}
.ui-datepicker .ui-datepicker-group:first-child {
  float: left;
}
.ui-datepicker .ui-datepicker-group:nth-child(2) {
  float: right;
}
.ui-datepicker .ui-datepicker-header {
  padding: 5px 0px;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all {
  text-decoration: none;
  color: #ef366c;
  width: 20px;
  height: 20px;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all:hover {
  color: #dd1c55;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all:after {
  display: block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 20px;
  height: 20px;
  font-size: 20px;
  background: #fff;
  text-align: center;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all span {
  display: none;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev {
  float: left;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev:after {
  content: "\f0d9";
}
.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next {
  float: right;
}
.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next:after {
  content: "\f0da";
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  text-align: center;
}
.ui-datepicker .ui-datepicker-calendar {
  width: 100%;
}
.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
  font-weight: 300;
  width: 28px;
}
.ui-datepicker .ui-datepicker-calendar td.selected-range {
  background-color: #44a0d0;
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.selected-range a {
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.start-range {
  background-color: #176192;
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.start-range > a.ui-state-active,
.ui-datepicker .ui-datepicker-calendar td.start-range > a.ui-state-default {
  background-color: #176192;
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.end-range {
  background-color: #176192;
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.end-range > a.ui-state-active,
.ui-datepicker .ui-datepicker-calendar td.end-range > a.ui-state-default {
  background-color: #176192;
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a {
  background: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td.ui-state-disabled {
  color: #f0eeef;
}
.ui-datepicker .ui-datepicker-calendar td a {
  display: block;
  padding: 2px 0;
  color: #736c6c;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-calendar td a.ui-state-active {
  background: #449fd1;
  color: #fff;
}
.ui-datepicker .ui-datepicker-calendar td a:hover {
  background: #ef366c;
  color: #fff;
}
form ul.errors {
  list-style: none;
  margin: 0;
  padding: 0;
}
form ul.errors li {
  margin: 2px 5px;
  color: #D8000C;
}
form ul.errors li i {
  margin-right: 5px;
}
form label.error {
  color: #D8000C;
  display: block;
  font-weight: normal;
  margin-top: 0.5em;
}
form .form-element .description {
  font-size: 13px;
  color: #a69c9c;
}
form .control-label {
  font-weight: normal;
}
form div.control-label {
  line-height: 34px;
}
form div.control-label label {
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  margin: 0;
  font-size: 15px;
}
form .input-group-addon {
  border-radius: 0;
  border-color: #b9b4b4;
  background: #ef366c;
  color: #ffffff;
}
form .input-group-btn .btn {
  border-color: #b9b4b4;
  border-radius: 0;
  height: 34px;
}
form .placeholder .placeholder-text {
  display: none;
}
form .placeholder .placeholder-value {
  display: none;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  form .placeholder {
    position: relative;
    border: 1px solid #b9b4b4;
    width: 100%;
    height: 34px;
    line-height: 21px;
    background: #fff;
    color: #333;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 300;
  }
  form .placeholder input {
    display: none;
  }
  form .placeholder .placeholder-text {
    display: inline;
    color: #a69c9c;
    position: absolute;
  }
  form .placeholder .placeholder-value {
    display: inline;
    background: #fff;
    position: absolute;
  }
}
.carousel.gallery .carousel-control {
  display: none;
  background: none;
}
.carousel.gallery .carousel-control .arrow {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -20px;
  font-size: 35px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.5);
}
.carousel.gallery .carousel-control.left .arrow {
  left: 0;
}
.carousel.gallery .carousel-control.right .arrow {
  right: 0;
}
.carousel.gallery .carousel-control:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
}
.carousel.gallery:hover .carousel-control {
  display: block;
}
.carousel.gallery .carousel-inner .item {
  text-align: center;
}
.carousel.gallery .carousel-inner img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.carousel.gallery .carousel-thumbnails {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 15;
  padding: 2px 40px;
  margin-top: -56px;
  height: 56px;
  transition: margin-top 0.25s ease-in-out 0.75s;
}
.carousel.gallery .carousel-thumbnails .content {
  overflow: hidden;
}
.carousel.gallery .carousel-thumbnails .indicators {
  list-style: none;
  position: relative;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.carousel.gallery .carousel-thumbnails .indicators li {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0 1px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.carousel.gallery .carousel-thumbnails .indicators li.active {
  border: 1px solid #FFFFFF;
}
.carousel.gallery .carousel-thumbnails.not-ready {
  margin-top: 0;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 40px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control:hover {
  opacity: 1;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control .arrow {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  font-size: 20px;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control.left {
  left: 0;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control.left .arrow {
  left: 10px;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control.right {
  right: 0;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control.right .arrow {
  right: 10px;
}
.carousel.gallery .carousel-thumbnails .carousel-thumbnails-control:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
}
.modal .modal-content {
  box-shadow: none;
  border: none;
  border-radius: 0px;
  background-clip: border-box;
  color: #626262;
}
.modal .modal-content .modal-header {
  background: #f0eeef;
  border-bottom: none;
  padding: 15px 25px 10px;
}
.modal .modal-content .modal-header .close i {
  font-size: 14px;
}
.modal .modal-content .modal-header h4.modal-title {
  margin: 0;
  font-weight: 300;
  color: #2c2c2c;
  font-size: 18px;
  line-height: 25.88px;
}
.modal .modal-content .modal-body {
  padding: 25px;
  box-shadow: none;
  text-align: center;
}
.modal .modal-content .modal-body h2.message-title {
  margin-bottom: 0px;
}
.modal .modal-content .modal-footer {
  border-top: none;
  box-shadow: none;
  margin-top: 0;
  padding: 25px;
  padding-top: 0;
  text-align: center;
}
.modal .modal-content .modal-footer .button-modal-action {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ef366c;
  color: #736c6c;
  width: 230px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.modal .modal-content .modal-footer .button-modal-action.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
.modal .modal-content .modal-footer .button-modal-action:hover {
  text-decoration: none;
  color: #fff;
}
.modal .modal-content .modal-footer .button-modal-action.btn-const {
  width: 230px;
  text-align: center;
}
.modal .modal-content .modal-footer .button-modal-action.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
.modal .modal-content .modal-footer .button-modal-action.btn-xs.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-action.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
.modal .modal-content .modal-footer .button-modal-action.btn-sm.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-action.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
.modal .modal-content .modal-footer .button-modal-action.btn-md.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-action[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.modal .modal-content .modal-footer .button-modal-action:hover {
  color: #ffffff;
  background: #ef366c;
}
.modal .modal-content .modal-footer .button-modal-cancel {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ef366c;
  color: #736c6c;
  width: 230px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
.modal .modal-content .modal-footer .button-modal-cancel:hover {
  text-decoration: none;
  color: #fff;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-const {
  width: 230px;
  text-align: center;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-xs.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-sm.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
.modal .modal-content .modal-footer .button-modal-cancel.btn-md.btn-const {
  width: 80px;
}
.modal .modal-content .modal-footer .button-modal-cancel[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.modal .modal-content .modal-footer .button-modal-cancel:hover {
  color: #ffffff;
  background: #ef366c;
}
.modal.modal-success .modal-header {
  background: #17b79b;
}
.modal.modal-success .modal-header h4.modal-title {
  color: #FFFFFF;
}
.modal.modal-success .modal-body {
  padding: 50px 25px;
}
.modal.modal-success .modal-body h2 {
  color: #17b79b;
}
.modal.modal-warning .modal-header {
  background: #ef366c;
}
.modal.modal-warning .modal-header h4.modal-title {
  color: #FFFFFF;
}
.modal.modal-warning .modal-body {
  padding: 50px 25px;
}
.modal.modal-warning .modal-body h2 {
  color: #ef366c;
}
.modal.modal-error .modal-header {
  background: #ff4435;
}
.modal.modal-error .modal-header h4.modal-title {
  color: #FFFFFF;
}
.modal.modal-error .modal-body {
  padding: 50px 25px;
}
.modal.modal-error .modal-body h2 {
  color: #ff4435;
}
.modal.modal-confirm .modal-body {
  padding: 50px 25px;
}
.modal.modal-confirm .modal-footer .button-modal-action {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
  background: #ef366c;
  border-bottom: 4px solid #dd1c55;
  margin-right: 15px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
.modal.modal-confirm .modal-footer .button-modal-action:hover {
  text-decoration: none;
  color: #fff;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-const {
  width: 230px;
  text-align: center;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-xs.btn-const {
  width: 80px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-sm.btn-const {
  width: 80px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
.modal.modal-confirm .modal-footer .button-modal-action.btn-md.btn-const {
  width: 80px;
}
.modal.modal-confirm .modal-footer .button-modal-action[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.modal.modal-confirm .modal-footer .button-modal-action:hover {
  background: #f14e7d;
}
.modal-backdrop {
  position: fixed;
  bottom: 0;
}
@media (max-width: 767px) {
  .modal {
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  .modal .modal-dialog {
    margin: 0;
    height: 100%;
  }
  .modal .modal-dialog .modal-content-wrapper {
    width: 100%;
    height: 100%;
  }
  .modal .modal-dialog .modal-content {
    width: 100%;
    height: 100%;
  }
  .modal .modal-dialog .modal-content .modal-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    height: 50px;
  }
  .modal .modal-dialog .modal-content .modal-body {
    overflow-y: auto;
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
  }
  .modal .modal-dialog .modal-content .loading-overlay {
    top: 0;
    bottom: 0;
    position: fixed;
  }
  .modal .modal-dialog .modal-content .modal-footer {
    bottom: 0;
    position: fixed;
    width: 100%;
  }
  .modal.datepicker-modal input.datepicker {
    display: none;
  }
  .modal.datepicker-modal .date-picker-wrapper.inline-wrapper {
    position: static;
    border: none;
    display: block !important;
  }
  .modal.datepicker-modal .date-picker-wrapper.inline-wrapper .month-wrapper {
    width: 100% !important;
  }
  .modal.datepicker-modal .date-picker-wrapper.inline-wrapper .month-wrapper table {
    float: none;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
  .modal.datepicker-modal .date-picker-wrapper.inline-wrapper .month-wrapper .gap {
    display: none;
  }
  .modal.autocomplete-modal .modal-body {
    padding: 0;
  }
  .modal.autocomplete-modal .modal-body input.autocomplete {
    width: 100%;
    padding: 0 20px;
    height: 50px;
  }
  .modal.autocomplete-modal .modal-body .search-location-autocomplete.on-modal {
    position: absolute !important;
    top: 50px !important;
    bottom: 0px !important;
    max-height: none;
    max-width: none;
    border-top: 0;
    border-bottom: 0;
    margin: 0;
    text-align: left;
  }
}
.modal.iframe .modal-dialog {
  height: auto;
}
.modal.iframe .modal-body {
  padding: 0;
  margin: 0;
  line-height: 0;
}
.modal.iframe .modal-body.loading {
  padding: 150px 0;
}
.modal.iframe .modal-content {
  border: none;
}
.modal.iframe .modal-content .modal-header {
  min-height: 0;
  height: 0;
  padding: 0;
}
.modal.iframe .modal-content .modal-header .modal-title {
  display: none;
}
.modal.iframe .modal-content button.close {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 2;
}
.modal.iframe .modal-content button.close i {
  font-size: 14px;
}
.modal.iframe .modal-footer {
  display: none;
}
.iframe-content {
  overflow: hidden;
}
@media (max-width: 767px) {
  .modal.iframe .modal-dialog {
    margin: 0;
    height: 100%;
  }
  .modal.iframe .modal-dialog .modal-content .modal-header {
    height: auto;
    padding: 15px 25px 10px;
  }
  .modal.iframe .modal-dialog .modal-content .modal-header .modal-title {
    display: block;
  }
  .modal.iframe .modal-dialog .modal-content button.close {
    position: static;
  }
  .modal.iframe .modal-dialog .modal-content iframe {
    overflow: hidden;
  }
}
.street-list ul {
  max-height: 80px;
  overflow: hidden;
}
.street-list ul li {
  width: 33.33%;
  float: left;
}
.street-list .btn-link {
  color: #ef366c;
}
.search-banner {
  background: #17b79b;
  width: 100%;
  height: 100px;
  position: relative;
}
.search-banner .banner-background {
  background: url('/img/search-banner/background.png') left no-repeat;
  background-size: 305px 100px;
  width: 100%;
  height: 100px;
  position: absolute;
}
.search-banner .banner-text {
  color: #ffffff;
  font-size: 11pt;
  position: absolute;
  left: 300px;
  top: 30px;
}
.search-banner .banner-button {
  position: absolute;
  right: 20px;
  top: 22px;
  text-align: right;
}
.search-banner .banner-button > span {
  display: block;
  color: #ffffff;
  font-size: 12px;
}
.search-banner .banner-button > a {
  padding: 0;
  line-height: 40px;
  height: 40px;
  font-size: 16px;
  width: 200px;
  text-align: center;
}
.offer-special-title-link {
  font-size: 15px;
  color: #333;
}
.offer-special-header {
  position: relative;
  line-height: 160px;
}
.offer-special-header .offer-special-background {
  position: absolute;
  width: 100%;
}
.offer-special-header .offer-special-content {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.offer-special-header .offer-special-content i {
  width: 100px;
  height: 100px;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}
.offer-special-header .offer-special-content i.icon-offer-special-narty {
  margin-left: 25px;
}
.offer-special-header .offer-special-content .offer-special-title {
  display: inline-block;
  vertical-align: middle;
}
.offer-special-header .offer-special-content .offer-special-title h1 {
  color: #449fd1;
  display: block;
  margin: 0;
  font-size: 30px;
}
.offer-special-header .offer-special-content .offer-special-title h2 {
  margin: 0;
  font-size: 19px;
  color: #333;
}
.search-results-sidebar .section-title {
  font-size: 20px;
}
.search-results-sidebar form#search-form {
  margin-bottom: 50px;
  margin-top: 35px;
}
.search-results-sidebar form#search-form .styled-checkbox label:before {
  border: 1px solid #449fd1;
}
.search-results-sidebar form#search-form #fieldset-basic {
  margin-top: 0;
}
.search-results-sidebar .weather-widget {
  max-width: 210px;
  background: white;
  border: 1px solid #b9b4b4;
  padding: 15px;
  margin: 15px auto;
  text-align: right;
}
.search-results-sidebar .weather-widget .icon {
  float: left;
  margin: 0 5px;
}
.search-results-sidebar .weather-widget .temperature {
  font-size: 20pt;
}
.search-results-sidebar .weather-widget a {
  margin-top: 10px;
}
.line-header {
  overflow: hidden;
}
.line-header:after {
  margin-left: 70px;
}
.active-filters .filter,
.active-filters .reset-filters {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #b9b4b4;
  background: none;
  font-weight: 300;
  font-size: 15px;
  color: #333;
  margin-bottom: 3px;
  cursor: pointer;
  text-decoration: none;
}
.active-filters .filter:hover,
.active-filters .reset-filters:hover {
  background: #fff4e7;
}
.active-filters .filter > i,
.active-filters .reset-filters > i {
  color: #ef366c;
  margin-right: 8px;
}
.active-filters .filter .remove-filter,
.active-filters .reset-filters .remove-filter {
  color: #736c6c;
  margin-left: 5px;
}
.search-results #search-form-sort {
  margin-top: 7px;
}
.search-results .section-title {
  position: relative;
}
.search-results .section-title .title {
  padding-top: 7px;
  font-size: 25px;
  font-family: Dosis;
  line-height: 1.3;
}
.search-results .section-title .sort-by {
  width: 100%;
  padding-left: 30px;
  z-index: 1;
}
.search-results .result-list .no-result-container {
  margin-bottom: 48px;
}
.search-results .result-list .no-result-container p:nth-child(1) {
  color: #ef366c;
  font-size: 32px;
  font-weight: 300;
}
.search-results .result-list .no-result-container p:nth-child(2) {
  font-size: 16.5px;
  font-weight: 300;
}
.search-results .result-list .offer {
  background: #fff;
  margin-bottom: 20px;
}
.search-results .result-list .offer .row {
  margin-left: -10px;
  margin-right: -10px;
}
.search-results .result-list .offer div[class*='col'] {
  padding-left: 10px;
  padding-right: 10px;
}
.search-results .result-list .offer .image-partner {
  width: 25%;
  position: absolute;
  height: auto;
  top: -3px;
  left: 3px;
  z-index: 2;
  max-width: 62px;
}
.search-results .result-list .offer .image {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.search-results .result-list .offer .image .carousel.gallery .carousel-control .arrow {
  padding: 8px 10px;
}
.search-results .result-list .offer .image .carousel.gallery .carousel-inner .loading-overlay {
  z-index: 1;
}
.search-results .result-list .offer .image .carousel.gallery .carousel-inner > .item {
  transition: transform 0.35s ease-in-out;
}
.search-results .result-list .offer .image .carousel.gallery .carousel-inner .item.loader {
  margin-top: 50%;
}
.search-results .result-list .offer .image img {
  width: 100%;
  height: auto;
}
.search-results .result-list .offer .image img.progress-circle-indeterminate {
  width: 50px;
  height: 50px;
  display: block;
  padding-top: 50%;
  margin-top: -50px;
}
.search-results .result-list .offer .image .price,
.search-results .result-list .offer .image .photos-navi {
  background: rgba(23, 183, 155, 0.7);
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  z-index: 2;
}
.search-results .result-list .offer .image .price i,
.search-results .result-list .offer .image .photos-navi i {
  margin-top: 5px;
  margin-right: 10px;
  font-size: 20px;
  float: left;
}
.search-results .result-list .offer .image .favorite-addon {
  background: rgba(23, 183, 155, 0.7);
  padding: 5px 15px;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  z-index: 2;
}
.search-results .result-list .offer .image .favorite-addon i {
  font-size: 20px;
  vertical-align: middle;
}
.search-results .result-list .offer .image .price {
  font-size: 16px;
}
.search-results .result-list .offer .image .price span {
  font-size: 20px;
}
.search-results .result-list .offer .image .price small {
  display: block;
  margin-top: -5px;
  white-space: nowrap;
  line-height: 15px;
  font-size: 12px;
}
.search-results .result-list .offer .image .partner {
  position: absolute;
  top: -4px;
  left: -4px;
}
.search-results .result-list .offer .details {
  padding: 10px 0px;
  padding-right: 20px;
  position: relative;
  height: 227px;
  overflow: hidden;
}
.search-results .result-list .offer .details h3 {
  margin: 0;
  color: #449fd1;
  font-size: 16pt;
  margin-bottom: 5px;
  padding-right: 115px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.search-results .result-list .offer .details .price {
  position: absolute;
  right: 20px;
  top: 5px;
  color: #736c6c;
  width: 115px;
  text-align: right;
  font-weight: 300;
}
.search-results .result-list .offer .details .price span {
  color: #ef366c;
  font-size: 18pt;
}
.search-results .result-list .offer .details .price small {
  display: block;
  margin-top: -3px;
  white-space: nowrap;
}
.search-results .result-list .offer .details .address .city,
.search-results .result-list .offer .details .address .street-address {
  white-space: nowrap;
}
.search-results .result-list .offer .details .type,
.search-results .result-list .offer .details .modal-map {
  margin-bottom: 7px;
}
.search-results .result-list .offer .details .type a:first-child,
.search-results .result-list .offer .details .modal-map a:first-child {
  color: inherit;
}
.search-results .result-list .offer .details .type a,
.search-results .result-list .offer .details .modal-map a,
.search-results .result-list .offer .details .type span,
.search-results .result-list .offer .details .modal-map span {
  color: #736c6c;
}
.search-results .result-list .offer .details .type a.open-modal-map,
.search-results .result-list .offer .details .modal-map a.open-modal-map,
.search-results .result-list .offer .details .type span.open-modal-map,
.search-results .result-list .offer .details .modal-map span.open-modal-map {
  white-space: nowrap;
}
.search-results .result-list .offer .details .type a.open-modal-map:hover,
.search-results .result-list .offer .details .modal-map a.open-modal-map:hover,
.search-results .result-list .offer .details .type span.open-modal-map:hover,
.search-results .result-list .offer .details .modal-map span.open-modal-map:hover {
  color: #ef366c;
}
.search-results .result-list .offer .details .type a.open-modal-map:hover i,
.search-results .result-list .offer .details .modal-map a.open-modal-map:hover i,
.search-results .result-list .offer .details .type span.open-modal-map:hover i,
.search-results .result-list .offer .details .modal-map span.open-modal-map:hover i {
  color: #ef366c;
}
.search-results .result-list .offer .details .type {
  font-weight: 300;
  font-size: 9pt;
  margin-bottom: 10px;
}
.search-results .result-list .offer .details .type a:first-of-type {
  color: #000000;
}
.search-results .result-list .offer .details .type.rating-visible {
  padding-right: 105px;
}
.search-results .result-list .offer .details .modal-map:hover {
  cursor: pointer;
}
.search-results .result-list .offer .details .rating {
  float: right;
  text-align: right;
  margin-top: -3px;
}
.search-results .result-list .offer .details .rating .star-empty {
  background: url("/img/rating_star_empty.png");
  float: left;
  width: 92px;
  height: 16px;
  margin-right: 5px;
}
.search-results .result-list .offer .details .rating .star-empty .star {
  background: url("/img/rating_star.png");
  width: 72px;
  height: 16px;
}
.search-results .result-list .offer .details .rating p {
  margin: 0;
  font-size: 10pt;
  line-height: 18px;
}
.search-results .result-list .offer .details .highlight-text {
  padding: 1px 5px;
  background: #ff4435;
  color: #fff;
  margin-bottom: 5px;
  font-size: 10pt;
  display: inline-block;
}
.search-results .result-list .offer .details .offer-tags {
  margin-bottom: 5px;
}
.search-results .result-list .offer .details .description {
  font-weight: 400;
  font-size: 9pt;
  line-height: 15pt;
  max-height: 115px;
  overflow: hidden;
  position: relative;
  color: #736c6c;
}
.search-results .result-list .offer .details .details-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  background: #FFFFFF;
}
.search-results .result-list .offer .details .details-footer .description-fade {
  position: absolute;
  height: 30px;
  top: -30px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorStr=rgba(255, 255, 255, 0), endColorStr=#ffffff);
  /* IE6-9 brak obsługi RGBA */
}
.search-results .result-list .offer .details .entities {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #4d4d4d;
}
.search-results .result-list .offer .details .entities .room-capacity {
  margin-right: 10px;
}
.search-results .result-list .offer .details .entities .room-capacity i {
  font-size: 25px;
  color: #b9b4b4;
  letter-spacing: -10px;
  vertical-align: middle;
  padding-bottom: 5px;
  display: inline-block;
}
.search-results .result-list .offer .details .entities p {
  font-size: 80%;
  margin: 2px 0 0 0;
  color: #736c6c;
}
.search-results .result-list .offer .details .buttons *[class*=button-] {
  height: 40px;
  line-height: 40px;
  width: 100%;
  position: relative;
  text-align: center;
}
.search-results .result-list .offer .details .buttons *[class*=button-] i {
  position: relative;
  top: 1px;
  margin-right: 8px;
  color: #ef366c;
  font-size: 20px;
}
.search-results .result-list .offer .details .buttons *[class*=button-]:hover {
  color: #ffffff;
}
.search-results .result-list .offer .details .buttons *[class*=button-]:hover i {
  color: #ffffff;
}
.search-results .result-list .offer .details .buttons .button-phone i {
  color: #ffffff;
}
.search-results .result-list .offer .details .buttons .was-sent {
  color: #736c6c;
  background: #d7d2d2;
  border: 1px solid #a69c9c;
}
.search-results .result-list .offer .details .buttons .was-sent i {
  color: #736c6c;
}
.search-results .result-list .offer.offer-partner {
  background: #fffcc5;
}
.search-results .result-list .offer.offer-partner .details .details-footer {
  background: #fffcc5;
}
.search-results .result-list .offer.offer-partner .details .details-footer .button-white {
  background: transparent;
  border: 1px solid #f694b1;
}
.search-results .result-list .offer.offer-partner .details .details-footer .button-white:hover {
  background: #f8dbe3;
  color: #736c6c;
}
.search-results .result-list .offer.offer-partner .details .details-footer .button-white:hover i {
  color: #ef366c;
}
.search-results .result-list .offer.offer-partner .details .details-footer .was-sent {
  color: #736c6c;
  background: #f8dbe3;
  border: 1px solid #a69c9c;
}
.search-results .result-list .offer.offer-partner .details .details-footer .was-sent:hover i {
  color: #736c6c;
}
.search-results .result-list .offer.offer-partner .details .details-footer .description-fade {
  background-color: rgba(255, 252, 197, 0);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 252, 197, 0)), color-stop(100%, #fffcc5));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 252, 197, 0) 0%, #fffcc5 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(255, 252, 197, 0) 0%, #fffcc5 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(255, 252, 197, 0) 0%, #fffcc5 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(255, 252, 197, 0) 0%, #fffcc5 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(255, 252, 197, 0) 0%, #fffcc5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorStr=rgba(255, 252, 197, 0), endColorStr=#fffcc5);
  /* IE6-9 brak obsługi RGBA */
}
.search-results .result-list .offer.special-result-item .details .type {
  margin-right: 115px;
}
@media (max-width: 1199px) {
  .street-list ul li {
    width: 50%;
  }
  .offer-special-header .offer-special-content i {
    width: 80px;
    height: 80px;
  }
  .offer-special-header .offer-special-content i.icon-offer-special-narty {
    margin-left: 22px;
  }
  .search-results .result-list .offer .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .search-results .result-list .offer div[class*='col'] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .search-results .result-list .offer .details {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    height: 241px;
  }
  .search-results .result-list .offer .details .entities .available-number {
    max-width: 210px;
  }
  .search-results .result-list .offer .details .buttons *[class*=button-] {
    font-size: 12pt;
  }
  .search-banner .banner-background {
    display: none;
  }
  .search-banner .banner-text {
    left: 20px;
  }
}
@media (max-width: 991px) {
  .offer-special-header .offer-special-content i {
    width: 70px;
    height: 70px;
  }
  .offer-special-header .offer-special-content i.icon-offer-special-narty {
    margin-left: 15px;
  }
  .search-banner .banner-text {
    font-size: 18px;
    top: 25px;
  }
  .search-banner .banner-button > a {
    width: 160px;
  }
  .search-results .result-list .offer.special-result-item .details .type {
    margin-right: 0px;
  }
  .search-results .result-list .offer .details .highlight-text {
    font-size: 10pt;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .search-results .result-list .offer .image-partner {
    left: 2px;
  }
  .search-results .result-list .offer .details {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .search-results .result-list .offer .details .entities .available-number {
    max-width: 210px;
  }
}
@media (min-width: 992px) {
  .search-results .result-list .offer .details-footer {
    padding-right: 10px;
  }
  .search-results .result-list .offer .image {
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .search-results .result-list .offer .image-partner {
    top: -4px;
    left: 6px;
  }
  .search-results .result-list .offer .details {
    height: 183px;
  }
  .search-results .result-list .offer .details h3 {
    padding-right: 0;
  }
  .search-results .result-list .offer .details .entities .available-number {
    max-width: 280px;
    float: none;
    margin-top: 5px;
    margin-left: -7px;
  }
  .search-results .result-list .offer .details .buttons *[class*=button-] {
    font-size: 10.5pt;
  }
  .search-results .result-list .offer .details-footer {
    padding-right: 10px;
  }
}
@media (min-width: 1200px) {
  .search-results .result-list .offer .image-partner {
    left: 6px;
  }
}
@media (max-width: 767px) {
  .street-list ul li {
    width: 100%;
    float: none;
  }
  .offer-special-header {
    line-height: 100px;
  }
  .offer-special-header .offer-special-content i {
    width: 60px;
    height: 60px;
    margin: 0;
  }
  .search-results .result-list .offer {
    height: auto;
  }
  .search-results .result-list .offer .image {
    margin-top: -5px;
    width: auto;
    height: auto;
    float: none;
  }
  .search-results .result-list .offer .image img {
    width: 100%;
    height: auto;
  }
  .search-results .result-list .offer .image .price {
    right: 0;
  }
  .search-results .result-list .offer .details {
    width: auto;
    height: auto;
    margin-left: 0;
    padding: 5px 15px;
    float: none;
  }
  .search-results .result-list .offer .details h3 {
    padding-right: 0;
    font-size: 15pt;
  }
  .search-results .result-list .offer .details .details-footer {
    position: static;
  }
  .search-results .result-list .offer .details .entities .available-number {
    max-width: 280px;
    float: none;
    margin-top: 5px;
    margin-left: -7px;
  }
  .search-results .result-list .offer .details .buttons *[class*=button-] {
    font-size: 11pt;
  }
  .search-results .result-list .offer .details .buttons *[class*=button-] i {
    font-size: 17px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .search-results .result-list .offer div[class*='col'] {
    padding-top: 5px;
  }
}
@media (max-width: 500px) {
  .search-results .result-list .offer .details .entities {
    display: none;
  }
  .search-results .result-list .offer .details .buttons i {
    display: none;
  }
}
section.booking-navi {
  padding: 20px 0;
}
section.booking-navi ul.navi-items {
  list-style: none;
  margin: 0;
  padding: 0;
}
section.booking-navi ul.navi-items li {
  display: block;
  width: 20%;
  padding: 0 10px;
  float: left;
  text-align: center;
  position: relative;
  color: #736c6c;
  font-size: 12px;
  text-decoration: none;
}
section.booking-navi ul.navi-items li span.step {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border: 1px solid #736c6c;
  border-radius: 15px;
  margin: 0 auto;
  margin-bottom: 5px;
}
section.booking-navi ul.navi-items li span.step i {
  color: #17b79b;
}
section.booking-navi ul.navi-items li:hover span.step {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
}
section.booking-navi ul.navi-items li.active span.step {
  border-color: #ef366c;
  color: #ef366c;
}
section.booking-navi ul.navi-items li.active:hover span.step {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(239, 54, 108, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(239, 54, 108, 0.75);
  box-shadow: 0px 0px 2px 0px rgba(239, 54, 108, 0.75);
}
section.booking-navi ul.navi-items li:after {
  content: "\f054";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  display: block;
  position: absolute;
  right: -5px;
  top: 10px;
  color: #736c6c;
}
section.booking-navi ul.navi-items li:last-child:after {
  display: none;
}
section.reservation .booking {
  margin-bottom: 20px;
}
section.reservation .booking .offer-name {
  margin: 0;
}
section.reservation .booking .address {
  margin-bottom: 10px;
}
section.reservation .booking .address span {
  color: #736c6c;
}
section.reservation .booking .dates {
  margin-bottom: 10px;
}
section.reservation .booking .dates dt {
  float: left;
  width: 100px;
  font-weight: normal;
}
section.reservation .booking .dates dd {
  margin-left: 110px;
}
section.reservation .booking .user-data {
  margin-bottom: 10px;
}
section.reservation .booking .user-data dt {
  float: left;
  width: 150px;
  font-weight: normal;
}
section.reservation .booking .user-data dd {
  margin-left: 160px;
}
section.reservation .booking .entities {
  margin-top: 20px;
  margin-bottom: 20px;
}
section.reservation .booking .total-price {
  margin-top: 20px;
  background: #44a0d0;
  padding: 10px 20px;
  font-size: 25px;
  color: #fff;
}
section.reservation .booking .total-price .price {
  font-weight: bold;
  text-align: right;
}
section.reservation form#form-booking {
  margin-bottom: 50px;
}
section.reservation form#form-booking fieldset {
  margin: 0;
}
section.reservation form#form-booking .form-element {
  margin-bottom: 20px;
}
section.reservation form#form-booking input[type="text"],
section.reservation form#form-booking input[type="password"] {
  height: 50px;
}
section.reservation form#form-booking div.control-label {
  line-height: 50px;
}
section.reservation form#form-booking .styled-radiobox.inline,
section.reservation form#form-booking .styled-checkbox.inline {
  line-height: 50px;
}
.summary-hidden-form {
  margin-bottom: 40px;
}
.summary-hidden-form fieldset,
.summary-hidden-form .form-element {
  display: none;
}
section.reservation .done {
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 300;
}
section.reservation .done .booking-letter {
  text-align: center;
  font-size: 20px;
  color: #17b79b;
  background: url("/img/letter.png") no-repeat center center;
  height: 120px;
}
section.reservation .done .booking-icon {
  text-align: center;
  font-size: 35px;
  font-weight: 300;
  color: #17b79b;
}
section.reservation .done h3 {
  color: #17b79b;
  text-align: center;
  font-size: 21px;
}
section.reservation .done .booking-code {
  text-align: center;
  border: 1px solid #e4e0e2;
  background: #f0eeef;
  padding: 20px;
  margin: 30px 0;
  font-size: 21px;
  color: #17b79b;
}
section.reservation .done .booking-code span {
  color: #ef366c;
}
section.reservation .done p {
  margin-bottom: 20px;
  font-size: 14px;
}
section.reservation .done p a {
  color: #17b79b;
}
section.reservation .done.error .booking-icon,
section.reservation .done.error h3 {
  color: #D8000C;
}
.search-form-horizontal {
  background: #17b79b;
  padding-bottom: 40px;
}
.search-form-horizontal .search-form-container h1 {
  font-size: 24px;
  padding-bottom: 5px;
  color: #ffffff;
}
@media (min-width: 767px) {
  .search-form-horizontal {
    height: 150px;
    position: relative;
  }
  .search-form-horizontal .hero-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #17b79b;
  }
  .search-form-horizontal .hero {
    height: 100%;
    background: url('/img/hero/hero-short@2x.png') no-repeat left center;
    background-size: 612px 150px;
    margin-left: -216px;
  }
}
@media (min-width: 991px) {
  .search-form-horizontal .hero {
    margin-left: -216px;
  }
}
@media (min-width: 1199px) {
  .search-form-horizontal .hero {
    margin-left: 0;
  }
}
@media (min-width: 1364px) {
  .search-form-horizontal .hero {
    width: 1364px;
    margin: auto;
  }
}
.favorite-addon .favorite-heart-icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.favorite-addon .favorite-heart-icon:after {
  content: "\f08a";
  position: absolute;
  top: 0;
  left: 0;
}
.favorite-addon.active .favorite-heart-icon:before {
  content: "\f004";
  position: absolute;
  top: 1px;
  left: 1px;
  color: #ff695d;
  font-size: 18px;
}
.favorite-icon-badge {
  color: #736c6c;
  position: relative;
}
.favorite-icon-badge .label-as-badge {
  position: absolute;
  top: -2px;
  left: 12px;
  padding: .2em .4em .3em;
  background: #ff695d;
  border-radius: 1em;
  font-size: 10px;
}
span.favorite-icon-badge .label-as-badge {
  left: auto;
  top: 0;
  margin-left: 5px;
}
.favorite-search-button {
  margin-top: 30px;
  margin-bottom: 30px;
}
.favorite-no-found {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
}
.favorite-search-icon {
  background: url('/img/favorite-search.png') no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: center;
  min-height: 135px;
}
.offer-favorites .result-list {
  max-width: 848px;
  margin: auto;
}
.offer-favorites .paginator {
  max-width: 848px;
  margin: 0 auto 20px auto;
}
.offer-favorites .page-title .fa-heart {
  color: #ff695d;
  font-size: 0.8em;
}
#search-form.horizontal .location input,
#search-availability-form .location input,
#search-form.sidebar .location input {
  padding-right: 30px;
}
#search-form.horizontal .location:before,
#search-availability-form .location:before,
#search-form.sidebar .location:before {
  font-family: e-nocleg-icons;
  content: '\e604';
  float: left;
  font-size: 18px;
  top: 12px;
  right: 10px;
  color: #17b79b;
  position: absolute;
  pointer-events: none;
}
#search-form.horizontal .styled-select::before,
#search-availability-form .styled-select::before,
#search-form.sidebar .styled-select::before {
  color: #17b79b;
}
#search-form.sidebar .location:before {
  top: 6px;
}
#search-form.horizontal .date input,
#search-availability-form .date input {
  padding-right: 30px;
}
#search-form.horizontal .date:before,
#search-availability-form .date:before {
  font-family: e-nocleg-icons;
  content: '\e603';
  float: right;
  font-size: 18px;
  color: #17b79b;
  top: 12px;
  right: 10px;
  position: absolute;
  z-index: 2;
  pointer-events: none;
}
.modal.modal-autocomplete .modal-content .modal-body {
  padding: 0px;
}
.modal.modal-autocomplete .modal-content .modal-body input {
  height: 50px;
}
#search-form.horizontal {
  display: block;
}
#search-form.horizontal .row {
  margin-left: -2px;
  margin-right: -2px;
}
#search-form.horizontal div[class*="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}
#search-form.horizontal .form-element input[type='text'] {
  height: 50px;
}
#search-form.horizontal .form-element .styled-select {
  display: block;
}
#search-form.horizontal .form-element .styled-select:before {
  top: 18px;
}
#search-form.horizontal .form-element .styled-select select {
  height: 50px;
}
#search-form.horizontal .submit-element input {
  width: 100%;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  #search-form.horizontal .form-element {
    margin-bottom: 15px;
  }
  #search-form.horizontal .form-element .placeholder {
    height: 50px;
    line-height: 40px;
  }
  #search-form.horizontal .form-element .placeholder .placeholder-text {
    color: #a69c9c;
  }
  #search-form.horizontal .form-element.date .placeholder {
    padding-right: 30px;
  }
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/*! =========================================================
 * bootstrap-slider.js
 *
 * Maintainers:
 *		Kyle Kemp
 *			- Twitter: @seiyria
 *			- Github:  seiyria
 *		Rohit Kalkur
 *			- Twitter: @Rovolutionary
 *			- Github:  rovolution
 *
 * =========================================================
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================= */
.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.slider.slider-horizontal {
  width: 210px;
  height: 20px;
}
.slider.slider-horizontal .slider-track {
  height: 10px;
  width: 100%;
  margin-top: -5px;
  top: 50%;
  left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
  margin-left: -10px;
  margin-top: -5px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: #0480be;
  margin-top: 0;
}
.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 4px;
  display: inline-block;
  text-align: center;
}
.slider.slider-vertical {
  height: 210px;
  width: 20px;
}
.slider.slider-vertical .slider-track {
  width: 10px;
  height: 100%;
  margin-left: -5px;
  left: 50%;
  top: 0;
}
.slider.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
  width: 100%;
  left: 0;
  right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
  margin-left: -5px;
  margin-top: -10px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
  border-width: 10px 0 10px 10px;
  width: 1px;
  height: 1px;
  border-left-color: #0480be;
  margin-left: 0;
}
.slider.slider-disabled .slider-handle {
  background-image: -webkit-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
  background-image: -o-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
  background-image: linear-gradient(to bottom, #dfdfdf 0%, #bebebe 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf', endColorstr='#ffbebebe', GradientType=0);
}
.slider.slider-disabled .slider-track {
  background-image: -webkit-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
  background-image: -o-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #e9e9e9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#ffe9e9e9', GradientType=0);
  cursor: not-allowed;
}
.slider input {
  display: none;
}
.slider .tooltip.top {
  margin-top: -36px;
}
.slider .tooltip-inner {
  white-space: nowrap;
}
.slider .hide {
  display: none;
}
.slider-track {
  position: absolute;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #f9f9f9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.slider-selection {
  position: absolute;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.slider-selection.tick-slider-selection {
  background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
  background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
  background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
}
.slider-track-low,
.slider-track-high {
  position: absolute;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #337ab7;
  background-image: -webkit-linear-gradient(top, #149bdf 0%, #0480be 100%);
  background-image: -o-linear-gradient(top, #149bdf 0%, #0480be 100%);
  background-image: linear-gradient(to bottom, #149bdf 0%, #0480be 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  filter: none;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 0px solid transparent;
}
.slider-handle.round {
  border-radius: 50%;
}
.slider-handle.triangle {
  background: transparent none;
}
.slider-handle.custom {
  background: transparent none;
}
.slider-handle.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  filter: none;
  opacity: 0.8;
  border: 0px solid transparent;
}
.slider-tick.round {
  border-radius: 50%;
}
.slider-tick.triangle {
  background: transparent none;
}
.slider-tick.custom {
  background: transparent none;
}
.slider-tick.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick.in-selection {
  background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
  background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
  background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
  opacity: 1;
}
#search-form.sidebar .location input {
  padding-left: 5px;
}
#search-form.sidebar .input-group-addon {
  border-radius: 0;
}
#search-form.sidebar fieldset {
  margin-top: 40px;
}
#search-form.sidebar fieldset#fieldset-basic {
  margin-top: 0px;
}
#search-form.sidebar fieldset:first-child {
  margin-top: 0px;
}
#search-form.sidebar fieldset legend {
  color: #5a505a;
  font-size: 20px;
  line-height: 1.1;
  font-family: Dosis;
  font-weight: normal;
  border: none;
  margin: 0;
  margin-bottom: 25px;
  text-align: left;
  position: relative;
  -webkit-margin-collapse: separate;
}
#search-form.sidebar fieldset legend:after {
  border-bottom: 1px solid #b9b4b4;
  display: block;
  width: 100%;
  content: " ";
  height: 1px;
  line-height: 0px;
  position: absolute;
  margin-top: -11px;
}
#search-form.sidebar fieldset legend span {
  background: #f0eeef;
  color: #5a505a;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 1;
}
#search-form.sidebar fieldset legend.left {
  text-align: left;
}
#search-form.sidebar fieldset legend.left span {
  padding-left: 0;
}
#search-form.sidebar fieldset#fieldset-price .validation-error {
  position: relative;
  z-index: 2;
  display: inline-block;
}
#search-form.sidebar .inline-row {
  margin: 0 -5px;
}
#search-form.sidebar .inline-row div[class*='col-'] {
  padding: 0 5px;
}
#search-form.sidebar .inline-row label {
  color: #333;
  font-weight: 300;
  margin-top: 5px;
}
#search-form.sidebar .inline-row #basic-date_start,
#search-form.sidebar .inline-row #basic-date_end {
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
#search-form.sidebar .inline-row .guest .styled-select #basic-guest {
  font-size: 14px;
  text-align: center;
  padding: 5px 5px 5px 28px;
}
#search-form.sidebar .inline-row .guest .styled-select:before {
  right: 7px;
}
#search-form.sidebar .inline-row .guest:before {
  font-size: 16px;
  margin-top: 36px;
  margin-left: 8px;
}
#search-form.sidebar .button-pink {
  margin-top: 20px;
}
#search-form.sidebar #fieldset-type label,
#search-form.sidebar #fieldset-amenity label {
  font-weight: 300;
  color: #333;
  font-size: 15px;
  padding-left: 25px;
}
#search-form.sidebar #fieldset-type label a,
#search-form.sidebar #fieldset-amenity label a {
  color: #333;
  text-decoration: none;
}
#search-form.sidebar #fieldset-type label a:hover,
#search-form.sidebar #fieldset-amenity label a:hover {
  text-decoration: none;
}
#search-form.sidebar #fieldset-type label:before,
#search-form.sidebar #fieldset-amenity label:before {
  margin-left: -25px;
  margin-right: 0;
}
#search-form.sidebar.sidebar-long #fieldset-basic {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  #search-form.sidebar .location:before {
    content: '';
  }
  #search-form.sidebar #basic-location {
    font-size: 13px;
    height: 30px;
    line-height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    /* firefox 19+ */
    /* ie */
  }
  #search-form.sidebar #basic-location::-webkit-input-placeholder::before {
    content: '';
  }
  #search-form.sidebar #basic-location::-moz-placeholder::before {
    content: '';
  }
  #search-form.sidebar #basic-location:-ms-input-placeholder::before {
    content: '';
  }
  #search-form.sidebar #basic-location:-moz-placeholder::before {
    content: '';
  }
  #search-form.sidebar .inline-row #basic-date_start,
  #search-form.sidebar .inline-row #basic-date_end,
  #search-form.sidebar .inline-row #basic-guest {
    font-size: 12px;
    height: 30px;
    line-height: 18px;
  }
  #search-form.sidebar .inline-row select {
    font-size: 12px;
    height: 30px;
    line-height: 18px;
  }
  #search-form.sidebar .inline-row .guest:before {
    font-size: 14px;
    margin-top: 35px;
  }
  #search-form.sidebar #fieldset-type {
    display: inline-block;
    vertical-align: top;
  }
  #search-form.sidebar #fieldset-amenity {
    display: inline-block;
    vertical-align: top;
  }
}
@media (max-width: 767px) {
  .form-booking-search-sidebar {
    margin-bottom: 15px;
  }
  .form-booking-search-sidebar .form-search-info {
    margin-bottom: 15px;
    border: 1px solid #b9b4b4;
    background: #ffffff;
    padding: 10px;
    cursor: pointer;
    line-height: 21px;
    font-size: 16px;
    font-weight: 300;
  }
  .form-booking-search-sidebar .form-search-info .location {
    font-weight: 400;
  }
  .form-booking-search-sidebar .form-search-info .guest {
    margin-top: 5px;
  }
  .form-booking-search-sidebar .toggle-form-search {
    color: #ef366c;
  }
  .form-booking-search-sidebar.form-closed #search-form {
    display: none;
  }
  .form-booking-search-sidebar.form-closed .toggle-form-search i.fa-chevron-up {
    display: none;
  }
  .form-booking-search-sidebar.form-opened .toggle-form-search i.fa-chevron-down {
    display: none;
  }
  #search-form.sidebar .dates .date:before {
    font-family: e-nocleg-icons;
    content: '\e603';
    float: left;
    font-size: 16px;
    margin-top: 36px;
    margin-left: 8px;
    color: #736c6c;
    position: absolute;
    pointer-events: none;
  }
}
.search-results-header h2.section-title {
  font-size: 20px;
  float: left;
  margin: 17px 20px 0 0;
}
.search-results-header h2.section-title:after {
  border: none;
}
.form-booking-search-header {
  background: #ffffff;
  padding: 15px;
  border-bottom: 5px solid #ef366c;
  margin-bottom: 20px;
}
.form-booking-search-header .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.form-booking-search-header #search-form input[type="text"],
.form-booking-search-header #search-form select {
  border: 1px solid #f694b1;
}
@media (max-width: 767px) {
  .form-booking-search-header {
    margin: 0 -15px;
    border-bottom: 3px solid #ef366c;
  }
  .form-booking-search-header .form-search-info {
    margin-bottom: 15px;
    background: #ffffff;
    cursor: pointer;
    line-height: 21px;
    font-size: 16px;
    font-weight: 300;
  }
  .form-booking-search-header .form-search-info > div {
    margin-bottom: 5px;
  }
  .form-booking-search-header .form-search-info > div > i {
    margin-right: 5px;
  }
  .form-booking-search-header .form-search-info .location {
    font-weight: 400;
  }
  .form-booking-search-header .form-search-info .guest a {
    color: #ef366c;
  }
  .form-booking-search-header.form-closed #search-form {
    display: none;
  }
  .form-booking-search-header.form-opened #search-form {
    display: block;
  }
}
.location.after:before {
  position: absolute;
  border: 13px solid transparent;
  border-bottom-color: #bdbdbd;
  left: 25px;
  top: 38px;
  z-index: 120;
  content: ' ';
}
.location.after:after {
  position: absolute;
  border: 12px solid transparent;
  border-bottom-color: #FFF;
  left: 26px;
  top: 40px;
  z-index: 120;
  content: ' ';
}
.search-location-autocomplete {
  background: #fff;
  border: 1px solid #b9b4b4;
  max-height: 300px;
  overflow-y: auto;
  z-index: 9999;
  max-width: 480px;
  list-style: none;
  padding: 0;
}
.search-location-autocomplete li {
  padding: 10px 10px;
  border-bottom: 1px solid #e5e5e5;
}
.search-location-autocomplete li p {
  font-size: 80%;
  color: #736c6c;
  line-height: 80%;
  margin: 0;
}
.search-location-autocomplete li.ui-state-focus {
  color: #ffffff;
  background: #ef366c;
}
.search-location-autocomplete li.ui-state-focus p {
  color: #ffffff;
}
@media (min-width: 767px) {
  section.search-bar .hero-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #17b79b;
  }
}
@media (min-width: 991px) {
  section.search-bar .hero-background .container {
    height: 100%;
  }
  section.search-bar .hero-background .container .hero {
    height: 100%;
    background: url('/img/hero/hero@2x.png') no-repeat left center;
    background-size: 1490px 404px;
    margin-left: -465px;
  }
}
.search-bar {
  height: 400px;
  position: relative;
}
.search-bar .search-form-container {
  margin-top: 80px;
  padding: 0px 0px;
}
.search-bar .search-form-container h1 {
  display: inline-block;
  margin: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 25pt;
}
.search-bar .search-form-container form {
  padding: 15px;
}
.search-bar .search-form-container form input {
  margin-bottom: 20px;
}
.offer-teaser {
  margin-bottom: 20px;
  background: #fff;
  max-height: 338px;
  margin-right: 0px;
  margin-left: 0px;
}
.offer-teaser > a {
  text-decoration: none;
  color: inherit;
}
.offer-teaser .image {
  display: block;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}
.offer-teaser .image .price-box {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(23, 183, 155, 0.7);
  color: #fff;
  padding: 5px 15px;
  font-size: 14pt;
}
.offer-teaser .image .price-box span {
  font-size: 70%;
  color: #ddd;
}
.offer-teaser .offer-teaser-container {
  display: block;
  padding: 10px 12px;
}
.offer-teaser .offer-teaser-container .offer-teaser-content {
  margin-bottom: 5px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
}
.offer-teaser .offer-teaser-container .offer-teaser-content.description {
  margin-top: 5px;
  white-space: inherit;
}
.offer-teaser .offer-teaser-container .offer-teaser-content.description .transparent-block {
  position: relative;
  display: block;
  max-height: 79px;
  color: #333;
}
.offer-teaser .offer-teaser-container .offer-teaser-content.description .transparent-block:after {
  content: " ";
  z-index: 2;
  display: block;
  position: absolute;
  height: 25%;
  left: 0;
  top: 66px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 70%);
}
.offer-teaser .offer-teaser-container .offer-teaser-content.inline-header {
  font-size: 15pt;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 3px;
}
.offer-teaser .offer-teaser-container .offer-teaser-content span {
  color: #736c6c;
}
.offer-teaser .offer-teaser-container .read-more {
  display: block;
  color: #17b79b;
}
.offer-teaser .offer-teaser-container p {
  margin-bottom: 0;
}
.offer-teaser .offer-teaser-container p span {
  color: #736c6c;
}
.recommended {
  padding-bottom: 40px;
}
.recommended .new-object .image {
  border: 3px solid #b9b4b4;
  margin-bottom: 10px;
  width: 100%;
  height: 156px;
  display: block;
}
.recommended .new-object .image .cross {
  background: #b9b4b4;
  display: block;
  height: 52px;
  position: relative;
  width: 7px;
  left: 49%;
  top: 33%;
}
.recommended .new-object .image .cross:after {
  background: #b9b4b4;
  content: "";
  height: 7px;
  left: -22px;
  position: absolute;
  top: 24px;
  width: 52px;
}
section.provinces-and-regions .icon-map-poland {
  width: 100%;
  height: auto;
  margin: 15px 0 45px 0;
}
section.provinces-and-regions .location-list {
  margin-bottom: 30px;
  width: 49%;
  display: inline-block;
}
section.provinces-and-regions .location-list ul {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
section.provinces-and-regions .location-list ul li {
  font-size: 11pt;
  font-weight: 300;
  color: #483f3f;
  border-top: 1px solid #b9b4b4;
  padding: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
section.provinces-and-regions .location-list ul li:last-child {
  border-bottom: 1px solid #b9b4b4;
}
section.provinces-and-regions .location-list ul li:before {
  display: inline-block;
  background: #ef366c;
  width: 5px;
  height: 5px;
  margin: 2pt 10px 2pt 0;
  content: " ";
}
section.provinces-and-regions .location-list ul li a {
  color: #483f3f;
}
section.special-offers .holiday {
  text-align: center;
  margin-bottom: 40px;
}
section.special-offers .holiday .icon-offer-special {
  display: block;
  margin: auto;
}
section.special-offers .holiday h3 {
  color: #483f3f;
  margin: 30px 0px;
  font-weight: normal;
}
section.popular {
  padding-bottom: 40px;
}
section.popular i {
  font-size: 70px;
  color: #ef366c;
}
section.popular h3 {
  color: #ef366c;
  margin: 30px 0px;
  font-weight: normal;
}
section.popular ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
section.popular ul li {
  font-size: 12pt;
  font-weight: 300;
  color: #fff;
  padding: 10px 0;
}
section.popular ul li a {
  color: #483f3f;
}
section.guides {
  padding-bottom: 40px;
}
section.guides h2.section-title {
  overflow: hidden;
  margin-left: 75px;
  margin-right: 80px;
}
section.guides #carousel-guide {
  padding: 40px 45px 0;
}
section.guides #carousel-guide .carousel-indicators {
  top: 0;
  height: 20px;
}
section.guides #carousel-guide .carousel-indicators li {
  border: none;
  background: #DDDDDD;
}
section.guides #carousel-guide .carousel-indicators li.active {
  background: #ef366c;
}
section.guides #carousel-guide .carousel-guide-control {
  position: absolute;
  top: 0;
  height: 100%;
  color: #736c6c;
  text-decoration: none;
}
section.guides #carousel-guide .carousel-guide-control.left {
  left: 0;
}
section.guides #carousel-guide .carousel-guide-control.right {
  right: 0;
}
section.guides #carousel-guide .carousel-guide-control > img {
  top: 50%;
  position: relative;
  margin-top: -30px;
}
section.guides #carousel-guide .carousel-inner .offer-teaser {
  margin-bottom: 0;
}
section.guides #carousel-guide .carousel-inner .offer-teaser .offer-teaser-container {
  height: 180px;
}
section.guides #carousel-guide .carousel-inner .offer-teaser .offer-teaser-container .description {
  margin-top: 15px;
}
section.guides #carousel-guide .carousel-inner .offer-teaser .offer-teaser-container .offer-teaser-content.inline-header {
  margin: -11px -13px 0;
  padding: 15px;
  font-size: 11pt;
  font-weight: normal;
  color: #ffffff;
  background: #ef366c;
}
section.guides #carousel-guide .carousel-inner .offer-teaser .offer-teaser-container .offer-teaser-content.inline-header a {
  color: inherit;
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .search-bar .hero-background.hero-1 {
    background: url('/img/nc-hero/nc-hero-01.jpg') no-repeat center;
    background-size: cover;
  }
  .search-bar .hero-background.hero-2 {
    background: url("/img/nc-hero/nc-hero-02.jpg") no-repeat center;
    background-size: cover;
  }
  .search-bar .hero-background.hero-3 {
    background: url("/img/nc-hero/nc-hero-03.jpg") no-repeat center;
    background-size: cover;
  }
  .search-bar .hero-background.hero-4 {
    background: url("/img/nc-hero/nc-hero-04.jpg") no-repeat center;
    background-size: cover;
  }
  .search-bar .hero-background.hero-5 {
    background: url("/img/nc-hero/nc-hero-05.jpg") no-repeat center;
    background-size: cover;
  }
  .search-bar .search-form-container {
    padding: 0px 50px;
  }
}
@media (max-width: 1199px) {
  .recommended .new-object .image {
    height: 128px;
  }
  .recommended .new-object .image .cross {
    left: 48%;
    top: 29%;
  }
  section.provinces-and-regions .location-list ul li {
    font-size: 9pt;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  section.special-offers .holiday ul li {
    padding-left: 10px;
    padding-right: 10px;
  }
  section.special-offers .holiday ul li:before {
    margin-right: 10px;
  }
}
@media (max-width: 991px) {
  .recommended .new-object .image {
    height: 132px;
  }
}
@media (max-width: 767px) {
  .offer-teaser .image img {
    width: 100%;
  }
  .recommended .new-object .image {
    height: 157px;
  }
  .recommended .new-object .image .cross {
    left: 49%;
    top: 34%;
  }
  section.search-bar {
    background: #f0eeef;
    height: auto;
  }
  section.search-bar .search-form-container h1 {
    color: #333;
    background: none;
  }
  section.search-bar .search-form-container #search-form {
    background: none;
  }
  section.search-bar .hero-background {
    background: none;
  }
  section.search-bar .hero-background .hero-opacity {
    background: none;
    opacity: 0;
  }
  section.search-bar .search-form-container {
    margin-top: 0;
  }
  section.search-bar .search-form-container h1 {
    font-size: 26px;
    display: block;
    text-align: center;
    padding-bottom: 0;
  }
  section.special-offers .holiday {
    margin-bottom: 20px;
  }
  section.provinces-and-regions .location-list ul {
    padding: 0 15px;
    border: 1px solid #b9b4b4;
  }
  section.provinces-and-regions .location-list ul li {
    padding: 10px 0;
  }
  section.provinces-and-regions .location-list ul li:first-child {
    border-top: none;
  }
  section.provinces-and-regions .locations {
    position: relative;
    min-height: 350px;
    overflow: hidden;
  }
  section.provinces-and-regions .locations .location-list {
    position: absolute;
    display: block;
    width: 100%;
    transition: all 0.25s ease;
    left: 0;
  }
  section.provinces-and-regions .locations .location-list ul li {
    font-size: 8pt;
  }
  section.provinces-and-regions .locations .location-list.left {
    left: -90%;
    padding: 0;
  }
  section.provinces-and-regions .locations .location-list.visible {
    left: 0;
    padding: 0 45px;
  }
  section.provinces-and-regions .locations .location-list.right {
    left: 90%;
    padding: 0;
  }
  section.provinces-and-regions .locations .location-list.left,
  section.provinces-and-regions .locations .location-list.right {
    transform: scale(0.9);
    opacity: 0.5;
  }
}
@media (max-width: 550px) {
  .offer-teaser .offer-teaser-container .read-more {
    display: none;
  }
  .offer-teaser .offer-teaser-container .offer-teaser-content.description {
    display: none;
  }
}
@media (max-width: 480px) {
  section.search-bar .search-form-container h1 {
    font-size: 20px;
  }
  .offer-teaser .image,
  .offer-teaser .offer-teaser-container {
    width: 100%;
  }
  section.special-offers .holiday h3 {
    margin: 15px 0;
  }
}
section.landing-page {
  overflow: hidden;
}
section.landing-page .landing-hero {
  background: #17b79b;
  position: relative;
}
section.landing-page .landing-hero .road-top {
  position: absolute;
  bottom: 53px;
  width: 100%;
  height: 61px;
  background: #a0a0a0;
  border-top: 9px solid #8de00b;
}
section.landing-page .landing-hero .road-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 53px;
  background: #af753c;
  border-bottom: 32px solid #8de00b;
}
section.landing-page .landing-hero .container {
  position: relative;
  height: 570px;
}
section.landing-page .landing-hero .container .title {
  margin-right: 600px;
  margin-top: 100px;
}
section.landing-page .landing-hero .container .title h1 {
  font-family: Roboto;
  color: #ffffff;
  font-size: 27pt;
  font-weight: bold;
  line-height: 55px;
}
section.landing-page .landing-hero .container .title h1 span {
  font-size: 37pt;
}
section.landing-page .landing-hero .container .banner {
  float: right;
  width: 590px;
  margin-top: 100px;
  position: relative;
  z-index: 2;
}
section.landing-page .landing-hero .container .banner .banner-button a {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
  background: #ef366c;
  border-bottom: 4px solid #dd1c55;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
section.landing-page .landing-hero .container .banner .banner-button a:hover {
  text-decoration: none;
  color: #fff;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-const {
  width: 230px;
  text-align: center;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-xs.btn-const {
  width: 80px;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-sm.btn-const {
  width: 80px;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
section.landing-page .landing-hero .container .banner .banner-button a.btn-md.btn-const {
  width: 80px;
}
section.landing-page .landing-hero .container .banner .banner-button a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
section.landing-page .landing-hero .container .banner .banner-button a:hover {
  background: #f14e7d;
}
section.landing-page .landing-hero .container .hero {
  position: absolute;
  left: 50%;
  margin-left: -665px;
  bottom: -58px;
  width: 1331px;
  height: 413px;
  background: url('/img/landingpage_car.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1331px 413px;
}
.why-us {
  padding-bottom: 90px;
}
.why-us .title {
  font-weight: bold;
}
.why-us img {
  border-radius: 72px;
}
.why-us h3 {
  font-size: 25pt;
  font-weight: bold;
}
.why-us h3 span {
  margin-top: 5px;
  display: block;
  font-size: 17pt;
}
.why-us .button-box {
  margin-top: 50px;
}
.landing-reviews {
  padding: 30px 0 20px;
}
.landing-reviews .container {
  background: url('/img/landingpage_hotel@x2.svg') right center no-repeat;
  background-size: 526px 260px;
}
.landing-reviews .carousel {
  height: 380px;
}
.landing-reviews .carousel .item {
  color: #ffffff;
}
.landing-reviews .carousel .item .review-header {
  height: 150px;
}
.landing-reviews .carousel .item .review-header .photo {
  float: left;
  margin-right: 50px;
}
.landing-reviews .carousel .item .review-header .photo .oval-photo {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  overflow: hidden;
  margin: 0 auto;
}
.landing-reviews .carousel .item .review-header .signature {
  display: table;
  height: 150px;
}
.landing-reviews .carousel .item .review-header .signature > p {
  display: table-cell;
  vertical-align: middle;
  font-size: 15pt;
  line-height: 18pt;
}
.landing-reviews .carousel .item .review-header .signature > p > span {
  display: block;
  font-size: 12pt;
}
.landing-reviews .carousel .item .review-cell {
  margin-top: 30px;
  font-size: 11pt;
  font-style: italic;
}
.landing-reviews .carousel-indicators {
  position: static;
  width: 100%;
  margin: 10px 0;
}
.landing-reviews .carousel-indicators li {
  background: #b9b4b4;
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 3px;
}
.landing-reviews .carousel-indicators li.active {
  background: #ffffff;
}
.landing-reviews .read-more {
  text-align: right;
  font-size: 14pt;
  margin-top: -35px;
}
.landing-reviews .read-more a {
  color: #ffffff;
}
section.viewership {
  padding: 50px 0 70px;
}
section.viewership .title {
  font-weight: bold;
}
section.viewership .viewership-list {
  padding: 0;
  list-style: none;
  font-size: 16pt;
  max-width: 325px;
  margin: 20px auto;
}
section.viewership .viewership-list li {
  margin-bottom: 10px;
}
section.viewership .viewership-list li i {
  color: #ef366c;
  margin-right: 20px;
  font-size: 20pt;
}
section.landingpage-panel {
  background: #f6f8f8;
  border-top: 1px solid #d5ccbd;
  border-bottom: 1px solid #d5ccbd;
  padding-bottom: 50px;
}
section.landingpage-panel .title {
  font-weight: bold;
}
section.landingpage-panel .panel-background {
  background: url('/img/landingpage_macbook@2x.svg') right top no-repeat;
  background-size: 705px 425px;
  padding-top: 60px;
}
section.landingpage-panel .item {
  margin-bottom: 75px;
  position: relative;
}
section.landingpage-panel .item .photo {
  float: left;
  margin-right: 20px;
}
section.landingpage-panel .item h4 {
  font-size: 17pt;
  font-weight: bold;
  margin-bottom: 8px;
}
section.landingpage-panel .item p {
  font-size: 12pt;
  font-weight: 100;
}
section.landingpage-panel .item .line {
  width: 100px;
  height: 2px;
  background: #b9b4b4;
  position: absolute;
  top: 23px;
  left: 400px;
}
section.landingpage-panel .info {
  font-family: Dosis;
  font-size: 17pt;
  text-align: center;
}
section.landingpage-panel .info img {
  margin-right: 12px;
}
section.subscription {
  padding-bottom: 70px;
}
section.subscription .btn-specification {
  font-size: 10pt;
}
section.subscription .short-subscription-link {
  font-size: 15pt;
  margin: 30px 0;
}
section.subscription .short-subscription-table {
  display: none;
}
section.free-try {
  padding: 30px 0;
  overflow: hidden;
}
section.free-try .container {
  position: relative;
}
section.free-try .container .background {
  position: absolute;
  right: -330px;
  bottom: -93px;
  width: 839px;
  height: 652px;
  background: url('/img/landingpage_balloon@2x.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 839px 652px;
}
section.free-try .title {
  font-family: Roboto;
  text-align: left;
  color: #ffffff;
  font-weight: bold;
  font-size: 25pt;
  line-height: 35pt;
  margin: 20px 0 80px;
}
section.free-try .title span {
  font-size: 30pt;
}
section.free-try .banner {
  float: left;
  position: relative;
  z-index: 2;
}
section.free-try .trial-banner .free-things .free-days,
section.free-try .trial-banner .free-things .free-points {
  background: #17b79b;
}
section.free-try .trial-banner .free-things .zero {
  background: #ef366c;
}
section.free-try .trial-banner .free-things .zero .zero-content {
  border: 8px solid #17b79b;
}
@media (max-width: 1199px) {
  section.landing-page .landing-hero .container .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
  }
  section.landing-page .landing-hero .container .banner {
    float: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    margin: 0 auto;
  }
  section.landingpage-panel .item .line {
    display: none;
  }
}
@media (max-width: 767px) {
  section.landing-page .landing-hero .container .banner {
    width: 100%;
    padding: 0 10px;
  }
  section.landing-page .landing-hero .container .title {
    width: 100%;
    padding: 0 10px;
    margin: 0;
  }
  section.landing-page .landing-hero .container .title h1 {
    font-size: 21pt;
  }
  section.landing-page .landing-hero .container .title h1 span {
    font-size: 25pt;
  }
  .landing-reviews .container {
    background: none;
  }
  section.landingpage-panel .panel-background {
    background: none;
    padding-top: 0px;
  }
  section.free-try .title {
    text-align: center;
  }
  section.free-try .container .background {
    display: none;
  }
  section.free-try .banner {
    float: none;
  }
  section.viewership .viewership-list {
    font-size: 14pt;
  }
  section.viewership .viewership-list li i {
    margin-right: 10px;
    font-size: 15pt;
  }
}
section.contact {
  margin-bottom: 30px;
}
section.contact .page-title {
  position: relative;
}
section.contact .page-title hr {
  position: absolute;
  border: none;
  background: #b9b4b4;
  height: 2px;
  top: 45px;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: -1;
}
section.contact .page-title h1 {
  background: #ffffff;
  max-width: 200px;
  margin: auto;
}
section.contact .contact-subtitle {
  font-size: 40px;
  color: #17b79b;
  font-weight: 300;
}
section.contact .contact-desc {
  font-size: 24px;
  color: #736c6c;
  font-weight: 300;
}
section.contact .form-contact {
  margin-top: 30px;
}
section.contact .form-contact .form-element {
  margin-bottom: 20px;
}
section.contact .form-contact .form-element input,
section.contact .form-contact .form-element textarea {
  padding: 20px;
}
section.contact .form-contact .form-element textarea {
  height: auto;
}
section.contact .form-contact .form-submit {
  padding-top: 15px;
}
section.contact .form-contact .form-submit input {
  text-transform: uppercase;
  font-weight: 600;
}
section.contact .form-contact .agreement {
  font-size: 12px;
  font-weight: 300;
  color: #acacac;
}
section.contact .form-contact .agreement .desc {
  padding: 10px 5px 10px 28px;
  background: url('/img/icons/lock.png') no-repeat left center;
  background-size: 18px;
}
section.contact .form-contact .agreement label {
  font-weight: 300;
}
section.contact .form-contact .agreement label.error {
  font-weight: normal;
  font-size: 14px;
}
section.contact .contact-data {
  height: 550px;
  background: url('/img/contact-icons.png') no-repeat left center;
  background-size: 200px 550px;
  color: #736c6c;
  font-weight: 300;
}
section.contact .contact-data .number,
section.contact .contact-data .email a {
  font-size: 24px;
  color: #ef366c;
  font-weight: 600;
}
section.contact .contact-data .phone {
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}
section.contact .contact-data .email {
  padding-top: 160px;
  padding-bottom: 130px;
}
section.contact .phone,
section.contact .email,
section.contact .company-data {
  padding-left: 250px;
}
@media (max-width: 767px) {
  section.contact .contact-data {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 375px;
    background: url('/img/contact-icons.png') no-repeat left center;
    background-size: 100px 275px;
  }
  section.contact .contact-data .phone,
  section.contact .contact-data .email,
  section.contact .contact-data .company-data {
    padding-left: 125px;
  }
  section.contact .contact-data .number,
  section.contact .contact-data .email a {
    font-size: 12px;
  }
  section.contact .contact-data .phone {
    font-size: 12px;
    padding-top: 60px;
    padding-bottom: 5px;
  }
  section.contact .contact-data .email {
    padding-top: 80px;
    padding-bottom: 45px;
  }
}
div.reviews .related-offer img {
  margin-bottom: 5px;
}
div.reviews .related-offer .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  margin-bottom: 5px;
}
div.reviews .related-offer .type {
  font-size: 12px;
}
div.reviews .related-offer .type span {
  color: #736c6c;
}
div.reviews .comment {
  font-weight: 300;
}
div.reviews .reply-comment {
  position: relative;
  background: #f0eeef;
  padding: 15px;
  margin-top: 20px;
  font-weight: 300;
}
div.reviews .reply-comment:before {
  content: " ";
  position: absolute;
  top: -10px;
  left: 20px;
  width: 0;
  height: 0;
  border-bottom: 10px solid #f0eeef;
  border-right: 10px solid transparent;
}
div.reviews .author {
  font-weight: 500;
  margin-bottom: 0;
  line-height: 50px;
}
div.reviews .author span.date,
div.reviews .author span.author-location {
  font-weight: 300;
  color: #736c6c;
}
div.reviews .author .review-author-photo {
  display: block;
  border-radius: 25px;
  float: left;
  overflow: hidden ;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
div.reviews .author .review-author-photo img {
  width: 50px;
  height: 50px;
}
div.reviews .average {
  font-size: 80px;
  font-weight: 300;
  float: left;
  margin-right: 20px;
  line-height: 125px;
}
div.reviews .rating .rating-details .rate {
  color: #736c6c;
  font-weight: 400;
}
div.reviews .rating .rating-details .rate .star-rating {
  font-size: 12px;
  margin-right: 2px;
  line-height: 1.1em;
}
div.reviews .review-separation-line {
  border-bottom: 1px solid #b9b4b4;
  margin: 20px 0;
}
div.reviews .alert {
  margin: 0;
}
@media (max-width: 767px) {
  div.reviews .rating {
    margin-top: 20px;
  }
}
@media (max-width: 480px) {
  div.reviews .rating {
    text-align: center;
  }
  div.reviews .average {
    float: none;
    display: inline-block;
    margin-right: 5px;
    font-size: 60px;
    vertical-align: top;
    line-height: 100px;
  }
  div.reviews .rating-details {
    display: inline-block;
    text-align: left;
  }
}
section.pricing {
  background-color: #ef366c;
  padding-bottom: 50px;
}
section.pricing h1 {
  color: #ffffff;
}
section.pricing .trial-banner {
  margin-top: 100px;
}
section.pricing .trial-banner .free-things .free-days,
section.pricing .trial-banner .free-things .free-points {
  background: #17b79b;
}
section.pricing .trial-banner .free-things .zero {
  background: #ef366c;
}
section.pricing .trial-banner .free-things .zero .zero-content {
  border: 8px solid #17b79b;
}
.partner-info .banner-select {
  margin-bottom: 20px;
}
.partner-info .banner-select .styled-select {
  margin-bottom: 10px;
  max-width: 400px;
}
.partner-info .code {
  transition: background-color 0.1s, color 0.1s;
}
.partner-info .code.highlight {
  background-color: #f9dc6b;
}
.customer-reviews .comments .comment {
  border-bottom: 1px solid #b9b4b4;
  padding: 20px 0;
  min-height: 150px;
}
.customer-reviews .comments .comment .image {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  float: left;
}
.customer-reviews .comments .comment .image img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
.customer-reviews .comments .comment .review {
  margin-left: 115px;
}
.customer-reviews .comments .comment .review blockquote {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: 300;
  border: none;
}
.customer-reviews .comments .comment .review .author {
  padding-top: 15px;
}
.customer-reviews .comments .comment .review .author .name {
  display: inline-block;
}
.customer-reviews .comments .comment .review .author .location {
  font-weight: 500;
}
.customer-reviews .comments .comment .review .author .location::before {
  content: " - ";
}
section.register-title {
  padding: 50px 0;
}
section.register-title h1 {
  text-align: center;
  font-weight: 300;
}
section.register-navi {
  padding: 2px 0;
}
section.register-navi.photos .logged-company {
  max-width: 1000px;
}
section.register-navi.entity-section .logged-company {
  max-width: 700px;
}
section.register-navi ul.navi-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}
section.register-navi ul.navi-items li {
  display: table-cell;
  padding: 0 10px;
  text-align: center;
  position: relative;
  background: #15C481;
  max-width: 150px;
}
section.register-navi ul.navi-items li div,
section.register-navi ul.navi-items li a {
  display: block;
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
}
section.register-navi ul.navi-items li div span.step,
section.register-navi ul.navi-items li a span.step {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border: 1px solid #ffffff;
  border-radius: 15px;
  margin: 16px auto 16px 10px;
}
section.register-navi ul.navi-items li div span.step i,
section.register-navi ul.navi-items li a span.step i {
  color: #ffffff;
}
section.register-navi ul.navi-items li div span.title,
section.register-navi ul.navi-items li a span.title {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: 300;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.register-navi ul.navi-items li.active {
  background: #ffffff;
}
section.register-navi ul.navi-items li.active div span.step,
section.register-navi ul.navi-items li.active a span.step {
  border-color: #ef366c;
  color: #ef366c;
}
section.register-navi ul.navi-items li.active div span.step i,
section.register-navi ul.navi-items li.active a span.step i {
  color: #ef366c;
}
section.register-navi ul.navi-items li.active div span.title,
section.register-navi ul.navi-items li.active a span.title {
  color: #ef366c;
}
section.register-navi ul.navi-items li.active:after {
  content: "\0020";
  display: block;
  border-left: 13px solid #ffffff;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  position: absolute;
  top: 29%;
  z-index: 3;
  transform: scale(1, 2.5);
  -ms-transform: scale(1, 2.5);
  -webkit-transform: scale(1, 2.5);
}
section.register-navi ul.navi-items li.active ~ li {
  color: #736c6c;
  background: #f0eeef;
}
section.register-navi ul.navi-items li.active ~ li div,
section.register-navi ul.navi-items li.active ~ li a {
  color: #736c6c;
}
section.register-navi ul.navi-items li.active ~ li div span.step,
section.register-navi ul.navi-items li.active ~ li a span.step {
  border: 1px solid #736c6c;
}
section.register-navi ul.navi-items li.active ~ li div span.step i:before,
section.register-navi ul.navi-items li.active ~ li a span.step i:before {
  color: #17b79b;
}
section.register-navi ul.navi-items li.active ~ li:after {
  content: "\0020";
  display: block;
  border-left: 13px solid #ffffff;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  position: absolute;
  top: 29%;
  right: -12px;
  z-index: 2;
  transform: scale(1, 2.5);
  -ms-transform: scale(1, 2.5);
  -webkit-transform: scale(1, 2.5);
}
section.register-navi ul.navi-items li.active ~ li:before {
  content: "\0020";
  display: block;
  border-left: 13px solid #f0eeef;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  position: absolute;
  top: 29%;
  right: -10px;
  z-index: 3;
  transform: scale(1, 2.5);
  -ms-transform: scale(1, 2.5);
  -webkit-transform: scale(1, 2.5);
}
section.register-navi ul.navi-items li.active ~ li:last-child:after,
section.register-navi ul.navi-items li.active ~ li:last-child:before {
  display: none;
}
section.register-navi ul.navi-items li:after {
  content: "\0020";
  display: block;
  border-left: 13px solid #15C481;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  position: absolute;
  top: 29%;
  right: -12px;
  z-index: 2;
  transform: scale(1, 2.5);
  -ms-transform: scale(1, 2.5);
  -webkit-transform: scale(1, 2.5);
}
section.register-navi ul.navi-items li:last-child:after {
  display: none;
}
section.register-navi ul.navi-items li:last-child:after {
  display: none;
}
section.register-navi .logged-company {
  margin: 10px auto -45px auto;
  font-size: 13px;
  max-width: 650px;
}
section.register-navi .logged-company .alert {
  border: 0;
  border-radius: 0;
}
section.register-navi .logged-company .alert small {
  display: block;
}
@media (max-width: 1400px) {
  section.register-navi ul.navi-items li div span.title,
  section.register-navi ul.navi-items li a span.title {
    max-width: 150px;
  }
}
@media (max-width: 1200px) {
  section.register-navi ul.navi-items li div span.title,
  section.register-navi ul.navi-items li a span.title {
    max-width: 100px;
  }
}
section.register.register-block {
  margin: 0px;
}
section.register.register-block .form-register-block {
  padding-bottom: 70px;
}
section.register {
  margin: 70px 0;
  font-size: 16px;
  font-weight: 300;
}
section.register .container {
  max-width: 650px;
}
section.register.entity-section .container {
  max-width: 700px;
}
section.register.register-company .container {
  max-width: 850px;
}
section.register.photos .container {
  max-width: 1000px;
}
section.register.photos .container .main-star {
  color: #ef366c;
}
section.register.photos .container .moxie-shim {
  display: none;
}
section.register strong {
  font-weight: 500;
}
section.register h1.page-title {
  font-size: 25px;
  margin: 0 0 40px 0;
  padding: 0;
}
section.register .account-block {
  text-align: center;
  padding: 30px;
}
section.register .account-block h1 {
  margin: 0;
}
section.register .form-register-block {
  border-right: 1px solid #d7d2d2;
}
section.register .form-register-block #form-new-company {
  margin-top: 20px;
}
section.register .form-register-block #form-new-company legend:after {
  display: none;
}
section.register .form-register-block > div {
  margin-right: 40px;
}
section.register .register-request {
  border-top: 1px solid #d7d2d2;
  padding-top: 20px;
  margin-bottom: 20px;
}
section.register form fieldset {
  margin: 40px 0;
}
section.register form fieldset:first-child {
  margin-top: 0px;
}
section.register form fieldset:last-child {
  margin-bottom: 0px;
}
section.register form fieldset legend {
  color: #5a505a;
  font-size: 25px;
  line-height: 1.1;
  font-family: Dosis;
  font-weight: normal;
  border: none;
  margin: 0;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
  -webkit-margin-collapse: separate;
}
section.register form fieldset legend:after {
  border-bottom: 1px solid #b9b4b4;
  display: block;
  width: 100%;
  content: " ";
  height: 1px;
  line-height: 0px;
  position: absolute;
  margin-top: -13px;
}
section.register form fieldset legend span {
  background: #fff;
  color: #5a505a;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 1;
}
section.register form fieldset legend.left {
  text-align: left;
}
section.register form fieldset legend.left span {
  padding-left: 0;
}
section.register form fieldset .form-element {
  margin-bottom: 20px;
}
section.register form input[type="text"],
section.register form input[type="password"] {
  height: 50px;
}
section.register form .styled-select .select2-container,
section.register form .styled-select select {
  height: 50px;
}
section.register form .styled-select .select2-container a,
section.register form .styled-select select a {
  height: 38px;
  line-height: 38px;
}
section.register form .styled-select:before {
  top: 17px;
}
section.register form div.control-label {
  line-height: 50px;
}
section.register form .styled-radiobox.inline,
section.register form .styled-checkbox.inline {
  line-height: 50px;
}
section.register form .form-submit .btn-const {
  margin: 0;
}
section.register .panel .thumbnail .thumb-container {
  line-height: 0;
}
section.register .panel .thumbnail canvas {
  width: 100%;
}
section.register .panel .thumbnail .description {
  width: 100%;
  position: relative;
}
section.register .panel .thumbnail .description .gradient-gray {
  position: absolute;
  width: 100%;
  height: 45px;
  bottom: 0px;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);
}
section.register .panel .thumbnail .description .gradient-gray .main-photo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
}
section.register .panel .thumbnail .description .gradient-gray .main-photo .fa-star {
  color: #ef366c;
}
section.register .panel .thumbnail .description .gradient-gray .delete {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #fff;
}
section.register .panel .thumbnail .description .gradient-gray .delete:hover {
  color: #f2f2f2;
}
section.register .panel h3 {
  margin-top: 10px;
  font-size: 27px;
  line-height: 35px;
}
section.register .panel h3 p {
  margin-bottom: 0;
  font-size: 14px;
}
section.register .panel h4 {
  margin-top: 5px;
  font-size: 22px;
  line-height: 31px;
}
section.register .panel .summary-link-edit {
  font-size: 12px;
  margin-left: 5px;
  white-space: nowrap;
}
section.register .panel #summary-gmap {
  height: 200px;
}
section.register .panel .thumbnail-list {
  margin-top: 10px;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}
section.register .panel .thumbnail-list img {
  float: left;
  margin: 0px 5px;
  margin-bottom: 10px;
}
section.register .panel .entity-price h3 p {
  font-size: 18px;
  text-align: right;
}
section.register .login-into-panel {
  text-align: center;
}
section.register .login-into-panel h2 {
  margin-top: 100px;
  margin-bottom: 20px;
  font-size: 25px;
}
section.register .login-into-panel > div {
  margin-left: 40px;
}
section.register .loading {
  height: 100%;
}
section.register .loading .loading-box {
  padding-bottom: 75%;
  margin-bottom: 3px;
}
section.register .loading .loading-box .box-middle {
  position: absolute;
  top: 50%;
  left: 50%;
}
section.register .loading .loading-box .box-middle .centered {
  position: absolute;
  top: -25px;
  left: -25px;
}
section.register .error-message {
  text-align: center;
}
@media (max-width: 767px) {
  section.register .form-register-block {
    border-right: none;
  }
  section.register .form-register-block #form-new-company {
    padding-top: 20px;
    padding-bottom: 40px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  section.register .form-register-block #form-new-company .form-submit {
    text-align: center;
  }
  section.register .form-register-block > div {
    margin-right: 0;
  }
  section.register .login-into-panel {
    margin-top: -130px;
  }
  section.register .login-into-panel > div {
    margin-left: 0;
  }
  section.register .register-request {
    margin-top: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
  }
}
#form-offer-basic #basic-location-gmap {
  height: 350px;
}
#form-offer-basic #fieldset-contact .form-element.phone {
  position: relative;
}
#form-offer-basic #fieldset-contact .form-element.phone .button-close {
  position: absolute;
  background: none;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
}
#form-offer-basic .map-warning {
  color: #ff4435;
  margin-top: -5px;
  margin-bottom: 5px;
}
#form-offer-basic #fieldset-price .description {
  margin-top: -15px;
  font-size: 13px;
  color: #a69c9c;
}
#form-offer-details .form-submit {
  margin-top: 30px;
}
#form-offer-entity .label-inline {
  position: absolute;
  line-height: 50px;
}
#form-offer-entity .input-label-inline {
  width: 100%;
  padding-left: 35px;
  padding-right: 23px;
}
#form-offer-entity .control-label .button-close {
  margin-left: 10px;
}
#form-offer-entity .short-input {
  max-width: 150px;
}
#form-offer-entity .bed-row button {
  color: #ff4435;
  background: none;
  position: absolute;
  right: 10px;
  height: 50px;
}
#form-offer-entity .bed-row:not(.bed-row-delete) button {
  display: none;
}
#modal-register-login .alert {
  font-size: 13px;
}
#modal-register-login .alert button.close {
  display: none;
}
#modal-register-login form {
  max-width: 400px;
  margin: 0 auto 20px auto;
}
#modal-register-login form label.error {
  text-align: left;
}
#modal-register-request .modal-content .modal-body {
  text-align: left;
}
#modal-register-request .modal-content .modal-body .form-submit {
  text-align: center;
}
.form-register-photos .actions .btn-const {
  margin: 0;
}
@media (max-width: 767px) {
  section.register-navi ul.navi-items li {
    width: 32%;
    margin-bottom: 10px;
  }
  #form-offer-entity .label-inline {
    margin-left: 10px;
  }
  #form-offer-entity .form-submit .btn-const {
    width: 180px;
  }
  #form-offer-entity .short-input {
    max-width: none;
  }
  #form-offer-entity .form-submit .btn-const {
    padding: 0 20px;
    width: 140px;
  }
  section.register .panel .entity-title h3 {
    margin: 0px;
  }
  section.register .panel .entity-price h3 {
    margin: 0px;
  }
  section.register .panel .entity-price h3 p {
    text-align: left;
  }
  section.register .panel #summary-gmap {
    margin-top: 10px;
  }
  #form-offer-basic .form-submit .btn-const {
    padding: 0 20px;
    width: 140px;
  }
  .form-register-photos .actions .btn-const {
    padding: 0 20px;
    width: 140px;
  }
}
section.nearby-locations-and-objects {
  padding: 20px 0 50px 0;
}
section.nearby-locations-and-objects h3 {
  font-size: 21px;
}
section.nearby-locations-and-objects h3:after {
  margin-top: -11px;
}
section.register.entity-list .entity-card {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #b9b4b4;
  padding: 5px;
  min-height: 217px;
}
section.register.entity-list .entity-card .title {
  text-align: center;
  font-weight: bold;
}
section.register.entity-list .entity-card .bed-list {
  margin-bottom: 10px;
  text-align: center;
}
section.register.entity-list .entity-card .bed-list .bed {
  width: 40px;
  height: 46px;
  vertical-align: top;
  display: inline-block;
  font-size: 11px;
}
section.register.entity-list .entity-card .bed-list .bed i {
  font-size: 35px;
}
section.register.entity-list .entity-card .details {
  font-size: 12px;
  margin-bottom: 30px;
}
section.register.entity-list .entity-card .actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 5px;
}
section.register.entity-list .entity-card-new-button {
  min-height: 217px;
  margin-bottom: 10px;
  border: 1px solid #b9b4b4;
  text-align: center;
  padding: 85px 0;
  width: 100%;
}
section.register.entity-list .actions {
  margin-top: 20px;
}
section.register.entity-list .actions .btn-const {
  margin-top: 0;
}
@media (max-width: 767px) {
  section.register.entity-list .entity-card {
    min-height: 0;
  }
  section.register.entity-list .entity-card-new-button {
    min-height: 150px;
    padding: 55px 0;
  }
  section.register.entity-list .actions .btn-const {
    width: 140px;
    padding: 0 20px;
  }
}
section.register-navi.register-summary .logged-company {
  max-width: 750px;
}
section.register.register-summary .container {
  max-width: 750px;
}
section.register.register-summary .main-picture img {
  min-height: 200px;
}
section.register.register-summary .pictures img {
  min-height: 100px;
}
section.register.register-summary .edits {
  text-align: center;
}
section.register.register-summary .edits .button-white {
  margin-bottom: 5px;
}
section.register.register-summary .actions {
  margin-top: 20px;
}
section.register.register-summary .actions .btn-const {
  margin-top: 0;
}
section.register-navi.subscription .logged-company {
  max-width: 1000px;
}
section.register-navi.payment .logged-company {
  max-width: 750px;
}
section.register.subscription .container {
  max-width: 1000px;
}
#form-order {
  margin-top: 30px;
}
#form-order fieldset {
  margin-bottom: 10px;
}
#form-order fieldset#fieldset-ord_proof_type legend {
  margin-bottom: 10px;
}
#form-order fieldset .form-element,
#form-order fieldset .styled-checkbox {
  padding-top: 7px;
}
.payment .payu {
  margin-top: 50px;
}
.payment .payu p {
  margin-bottom: 20px;
}
.payment .payment-summary > div {
  margin-bottom: 3px;
}
.payment .trial {
  margin-top: 70px;
  font-size: 20px;
}
.payment .trial .entity-limit-reached {
  margin-top: 5px;
  font-size: 10pt;
}
.payment .reorder {
  margin-top: 30px;
}
.payment .choose-payment-title {
  margin-top: 40px;
}
.payment .order-payment-methods input[type="radio"]:checked + label {
  background: #ffffff;
  border: 1px solid #b9b4b4;
}
.payment .order-payment-methods label {
  display: block;
  border: 1px solid #f0eeef;
  border-radius: 5px;
  padding: 10px;
  padding-left: 45px !important;
  margin: 0;
  color: #736c6c;
  font-size: 15px;
  min-height: 78px;
  font-weight: bold;
}
.payment .order-payment-methods label:before {
  bottom: 50%;
  position: absolute;
  left: 30px;
  margin-bottom: -9px;
  border: 1px solid #f0eeef;
}
.payment .order-payment-methods label small {
  color: #736c6c;
  display: block;
  margin-right: 80px;
  font-size: 11px;
  font-weight: normal;
}
.payment .order-payment-methods label .payment-logo {
  width: 60px;
  height: auto;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -15px;
}
.payment .order-payment-methods label .payment-logo img {
  width: 60px;
  height: auto;
}
.payment .order-payment-methods label .payment-logo.transfer {
  text-align: right;
  margin-top: -10px;
}
.payment .order-payment-methods label .payment-logo.transfer i {
  font-size: 20px;
}
.payment .order-payment-button {
  margin-top: 20px;
}
.payment .bank-transfer .info {
  margin-bottom: 20px;
}
.payment .bank-transfer .action {
  margin-top: 10px;
}
.payment .bank-transfer .alert {
  margin-top: 20px;
}
.offer-presentation .offer-main .offer-header-bar {
  padding-bottom: 10px;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact {
  float: right;
  height: 50px;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 5px;
  width: 198px;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite {
  float: left;
  font-size: 25px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  cursor: pointer;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email .text,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone .text,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite .text {
  display: none;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone {
  color: #ffffff;
  background: #17b79b;
  margin-right: 16px;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone:hover {
  background: #1ee3c0;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email {
  color: #ef366c;
  background: #ffffff;
  border: 1px solid #ef366c;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email:hover {
  background: #f8dbe3;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite {
  margin-right: 16px;
  line-height: 48px;
  border: 1px solid #ef366c;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite .favorite-addon {
  font-size: 12px;
  color: #ef366c;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite .favorite-addon .favorite-heart-icon {
  font-size: 20px;
  vertical-align: middle;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite .favorite-addon.active {
  color: #ff695d;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite .favorite-addon.active .favorite-heart-icon {
  color: #ff695d;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite:hover {
  background: #f8dbe3;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite:hover .favorite-heart-icon {
  color: #ff695d;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email.disable,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone.disable,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite.disable {
  color: #a69c9c;
  background: #ffffff;
  border: 1px solid #a69c9c;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email.disable:hover,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone.disable:hover,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite.disable:hover {
  background: #ffffff;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email.was-sent,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone.was-sent,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite.was-sent {
  color: #a69c9c;
  background: #f0f0f0;
  border: 1px solid #a69c9c;
}
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email.was-sent:hover,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone.was-sent:hover,
.offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite.was-sent:hover {
  color: #a69c9c;
  background: #f0f0f0;
}
.offer-presentation .offer-main #offer-carousel-gallery {
  position: relative;
  padding-top: 66.62%;
  height: 0;
  overflow: hidden;
}
.offer-presentation .offer-main #offer-carousel-gallery .carousel-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.offer-presentation .offer-main #offer-carousel-gallery .carousel-inner .item {
  text-align: center;
}
.offer-presentation .offer-main #offer-carousel-gallery .carousel-inner img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.offer-presentation .offer-main h1 {
  margin: 0;
  padding: 15px 20px 5px 20px;
}
.offer-presentation .offer-main h1 .offer-main-header {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
  margin-right: 120px;
}
.offer-presentation .offer-main h1 .star-rating {
  white-space: nowrap;
  padding: 8px 2px 0px 0px;
}
.offer-presentation .offer-main .address {
  margin: 0px 20px 0px 20px;
  font-weight: 300;
}
.offer-presentation .offer-main .address a {
  color: #000000;
}
.offer-presentation .offer-main .address span.open-modal-map {
  cursor: pointer;
  color: #736c6c;
}
.offer-presentation .offer-main .address span.open-modal-map:hover {
  color: #ef366c;
}
.offer-presentation .offer-main .address span.open-modal-map:hover i {
  color: #ef366c;
}
.offer-presentation .offer-main .photo {
  position: relative;
}
.offer-presentation .offer-main .photo .fancybox {
  display: none;
}
.offer-presentation .offer-main .photo .fancybox.main {
  display: block;
}
.offer-presentation .offer-main .photo .photo-navi {
  background: rgba(0, 0, 0, 0.4);
  padding: 8px 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
}
.offer-presentation .offer-main .photo .photo-navi i {
  margin-top: 5px;
  margin-right: 12px;
  font-size: 20px;
  float: left;
}
.offer-presentation .offer-info {
  display: table;
  margin-top: 3px;
  width: 100%;
  position: relative;
  border-collapse: collapse;
}
.offer-presentation .offer-info .offer-info-row {
  display: table-row;
}
.offer-presentation .offer-info .offer-info-row .address {
  display: table-cell;
  border-right: 1px solid #f0eeef;
  vertical-align: middle;
  padding: 15px 20px;
  width: 40%;
  font-weight: 300;
}
.offer-presentation .offer-info .offer-info-row .address p {
  margin-bottom: 10px;
}
.offer-presentation .offer-info .offer-info-row .address p:last-of-type {
  margin-bottom: 0;
}
.offer-presentation .offer-info .offer-info-row .address span {
  color: #a69c9c;
}
.offer-presentation .offer-info .offer-info-row .address a {
  color: inherit;
}
.offer-presentation .offer-info .offer-info-row .alert-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
}
.offer-presentation .offer-info .offer-info-row .offer-owner {
  margin-top: 15px;
}
.offer-presentation .offer-info .offer-info-row .offer-owner .renew-offer {
  margin: 15px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell {
  display: table-cell;
  width: 15%;
  border-right: 1px solid #f0eeef;
  text-align: center;
  vertical-align: middle;
}
.offer-presentation .offer-info .offer-info-row .contact-cell:last-of-type {
  border-right: none;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact {
  cursor: pointer;
  color: #ef366c;
  margin-bottom: 50px;
  font-size: 13px;
  padding: 10px;
  word-wrap: break-word;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact:hover {
  color: #e0124e;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact i {
  font-size: 30px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact i.fa {
  font-size: 33px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact > div {
  margin-top: 5px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell .contact .label {
  background: #ef366c;
  color: #ffffff;
  vertical-align: top;
  line-height: 16px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell:hover {
  background: #ef366c;
}
.offer-presentation .offer-info .offer-info-row .contact-cell:hover .contact {
  color: #ffffff;
}
.offer-presentation .offer-info .offer-info-row .contact-cell:hover .contact .label {
  background: #ffffff;
  color: #ef366c;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.facebook .contact {
  padding: 5px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.facebook .contact .facebook-action > div {
  margin: 5px auto;
  display: block;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.facebook .contact .facebook-action > div.fb-save {
  width: 62px;
  overflow: hidden;
  height: 20px;
  border: 1px solid #3b8bb6;
  border-radius: 2px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.facebook .contact .facebook-action > div.fb-save > span {
  margin-top: -1px;
  margin-left: -1px;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.disable,
.offer-presentation .offer-info .offer-info-row .contact-cell.disable:hover {
  background: #ffffff;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.disable .contact,
.offer-presentation .offer-info .offer-info-row .contact-cell.disable:hover .contact {
  color: #d7d2d2;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.disable .contact .label,
.offer-presentation .offer-info .offer-info-row .contact-cell.disable:hover .contact .label {
  background: #ffffff;
  color: #d7d2d2;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent,
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent:hover {
  background: #f0f0f0;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent .contact,
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent:hover .contact {
  color: #736c6c;
}
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent .contact .label,
.offer-presentation .offer-info .offer-info-row .contact-cell.was-sent:hover .contact .label {
  background: #f0f0f0;
  color: #736c6c;
}
.offer-presentation .offer-info .description-row {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 59.9%;
  border-top: 1px solid #f0eeef;
  background: #FFFFFF;
  padding: 15px;
  font-size: 12px;
  color: #736c6c;
  -moz-transition: background-color 0.5s, color 0.5s;
  -webkit-transition: background-color 0.5s, color 0.5s;
  -o-transition: background-color 0.5s, color 0.5s;
  transition: background-color 0.5s, color 0.5s;
}
.offer-presentation .offer-info .description-row.highlight {
  background-color: #f9dc6b;
  color: #ff4435;
}
.offer-presentation .availability {
  padding: 10px;
}
.offer-presentation .availability .row {
  margin-right: -5px;
  margin-left: -5px;
}
.offer-presentation .availability .row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.offer-presentation .availability input,
.offer-presentation .availability select {
  height: 50px;
}
.offer-presentation .availability .styled-select:before {
  top: 18px;
}
.offer-presentation .offer-price {
  margin-bottom: 40px;
}
.offer-presentation .offer-price .offer-price-container {
  padding: 15px 10px;
  background: #fff;
  font-size: 19px;
  text-align: left;
}
.offer-presentation .room-list {
  margin-bottom: 40px;
}
.offer-presentation .room-list .entity-container {
  margin-bottom: 20px;
}
.offer-presentation .room-list .entity-container:last-child {
  margin-bottom: 0;
}
.offer-presentation .room-list .title-bar {
  background: #ef366c;
  color: #fff;
  overflow: hidden;
  padding: 5px 9px;
}
.offer-presentation .room-list .booking {
  padding: 15px 10px;
  background: #fff;
}
.offer-presentation .room-list .booking #total-price {
  display: none;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}
.offer-presentation .room-list .entity {
  background: #fff;
}
.offer-presentation .room-list .entity .options {
  width: 100%;
  display: table;
}
.offer-presentation .room-list .entity .options .header-row,
.offer-presentation .room-list .entity .options .option-row {
  display: table-row;
}
.offer-presentation .room-list .entity .options .header-row > div,
.offer-presentation .room-list .entity .options .option-row > div {
  display: table-cell;
  padding: 5px 10px;
  border-top: 1px solid #f0eeef;
  vertical-align: middle;
}
.offer-presentation .room-list .entity .options .header-row .conditions,
.offer-presentation .room-list .entity .options .option-row .conditions {
  width: 35%;
}
.offer-presentation .room-list .entity .options .header-row .conditions.no-price,
.offer-presentation .room-list .entity .options .option-row .conditions.no-price {
  width: 60%;
}
.offer-presentation .room-list .entity .options .header-row .person,
.offer-presentation .room-list .entity .options .option-row .person {
  width: 17%;
}
.offer-presentation .room-list .entity .options .header-row .price,
.offer-presentation .room-list .entity .options .option-row .price {
  width: 25%;
}
.offer-presentation .room-list .entity .options .header-row .price.check-availability,
.offer-presentation .room-list .entity .options .option-row .price.check-availability {
  width: 25%;
}
.offer-presentation .room-list .entity .options .header-row .price.check-availability button,
.offer-presentation .room-list .entity .options .option-row .price.check-availability button {
  width: 100%;
}
.offer-presentation .room-list .entity .options .header-row .quantity,
.offer-presentation .room-list .entity .options .option-row .quantity {
  width: 23%;
}
.offer-presentation .room-list .entity .options .option-row > div {
  padding: 10px;
}
.offer-presentation .room-list .entity .options .option-row .conditions {
  font-size: 20px;
  line-height: 1.2em;
}
.offer-presentation .room-list .entity .options .option-row .conditions h3 {
  margin: 0;
}
.offer-presentation .room-list .entity .options .option-row .conditions ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.offer-presentation .room-list .entity .options .option-row .conditions ul li {
  margin: 0;
  padding: 0;
}
.offer-presentation .room-list .entity .options .option-row .conditions ul li:before {
  content: "-";
  margin-right: 5px;
}
.offer-presentation .room-list .entity .options .option-row .conditions a {
  font-size: 12px;
}
.offer-presentation .room-list .entity .options .option-row .person {
  border-left: 1px solid #f0eeef;
  text-align: center;
  color: #736c6c;
  font-size: 18px;
}
.offer-presentation .room-list .entity .options .option-row .person i {
  font-size: 24px;
  letter-spacing: -8px;
  margin-left: -10px;
  vertical-align: middle;
  display: inline-block;
  padding-bottom: 5px;
}
.offer-presentation .room-list .entity .options .option-row .price {
  border-left: 1px solid #f0eeef;
  text-align: right;
}
.offer-presentation .room-list .entity .options .option-row .price .total-price {
  color: #ef366c;
  font-size: 19px;
}
.offer-presentation .room-list .entity .options .option-row .price .price-per-day {
  color: #736c6c;
  font-size: 12px;
}
.offer-presentation .room-list .entity .options .option-row .quantity {
  border-left: 1px solid #f0eeef;
  text-align: center;
}
.offer-presentation .room-list .entity .options .entity-details-iframe {
  color: #ef366c;
}
.offer-presentation .room-list .entity .options .entity-details-iframe:hover {
  color: #dd1c55;
}
.offer-presentation .offer-content .nav-offer-content li {
  display: inline-block;
}
.offer-presentation .offer-content .nav-offer-content li a {
  padding: 15px;
  text-align: center;
  border: none;
  border-radius: 0;
  background: #ef366c;
  color: #fff;
}
.offer-presentation .offer-content .nav-offer-content li a:hover {
  background: #dd1c55;
}
.offer-presentation .offer-content .nav-offer-content li.active a {
  background: #fff;
  color: #483f3f;
}
.offer-presentation .offer-content .offer-description {
  background: #fff;
  padding: 20px;
  font-weight: 400;
  color: #000;
  overflow: hidden;
}
.offer-presentation .offer-content .offer-description h2 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.offer-presentation .offer-content .offer-description ul.offer-amenity {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.offer-presentation .offer-content .offer-description ul.offer-amenity li {
  margin: 0;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 0;
  padding-right: 10px;
  float: left;
  width: 33%;
}
.offer-presentation .offer-content .offer-description ul.offer-amenity li .nc {
  font-size: 20px;
  vertical-align: text-bottom;
  color: #449fd1;
}
.offer-presentation .offer-content .offer-description ul.offer-amenity li .nc:before {
  color: #17b79b;
}
.offer-presentation .offer-content .offer-description ul.offer-amenity li:before {
  margin-right: 5px;
}
.offer-presentation .offer-content .offer-description .stats-total-views {
  text-align: right;
  font-size: 12px;
}
.offer-presentation .offer-content .offer-specials,
.offer-presentation .offer-content .offer-special-details {
  background: #fff;
  padding: 20px;
}
.offer-presentation .offer-content .offer-specials .row,
.offer-presentation .offer-content .offer-special-details .row {
  margin-bottom: 10px;
}
.offer-presentation .offer-content .offer-specials .row:last-child,
.offer-presentation .offer-content .offer-special-details .row:last-child {
  margin-bottom: 0;
}
.offer-presentation .offer-content .offer-specials h4,
.offer-presentation .offer-content .offer-special-details h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.offer-presentation .offer-content .offer-specials h4 span,
.offer-presentation .offer-content .offer-special-details h4 span {
  background: #fff;
}
.offer-presentation .offer-content .offer-specials img,
.offer-presentation .offer-content .offer-special-details img {
  margin: 0 auto;
}
.offer-presentation .offer-content .offer-specials .title,
.offer-presentation .offer-content .offer-special-details .title {
  font-size: 22px;
  line-height: 24px;
}
.offer-presentation .offer-content .offer-specials .desc,
.offer-presentation .offer-content .offer-special-details .desc {
  font-weight: 300;
  margin-bottom: 10px;
}
.offer-presentation .offer-content .offer-specials .price,
.offer-presentation .offer-content .offer-special-details .price {
  border: 1px solid #b9b4b4;
  background: #f0eeef;
  color: #736c6c;
  font-weight: 300;
  text-align: center;
  padding: 20px;
}
.offer-presentation .offer-content .offer-specials .price span,
.offer-presentation .offer-content .offer-special-details .price span {
  color: #ef366c;
  font-size: 26px;
  font-weight: 500;
}
.offer-presentation .offer-content .offer-specials .section-title {
  font-size: 14px;
  margin-bottom: 10px;
}
.offer-presentation .offer-content .offer-specials .section-title span {
  color: #44a0d1;
  font-weight: 600;
}
.offer-presentation .offer-content .offer-special-details {
  position: relative;
}
.offer-presentation .offer-content .offer-special-details .icon i,
.offer-presentation .offer-content .offer-special-details .icon a {
  display: inline-block;
}
.offer-presentation .offer-content .offer-special-details .icon-offer-special,
.offer-presentation .offer-content .offer-special-details .icon-offer-special-wide {
  margin-bottom: 10px;
}
.offer-presentation .offer-content .offer-special-details .category.title {
  position: absolute;
  top: 0;
  left: 0;
  color: #44a0d1;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 35px 0 10px 15px;
}
.offer-presentation .offer-content .offer-special-details .desc-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 26px;
  margin: 65px 0 0 0;
  padding: 0 0 10px 15px;
  max-width: 50%;
}
.offer-presentation .offer-content .offer-special-details .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #44a0d0;
  text-align: center;
  margin-right: 15px;
}
.offer-presentation .offer-content .offer-special-details .button-phone {
  padding: 5px 35px;
  display: inline-block;
}
.offer-presentation .offer-content .offer-special-details .button-phone i {
  margin-right: 5px;
  font-size: 15px;
}
.offer-presentation .offer-content .offer-special-details .offer-contact ul li {
  margin-bottom: 10px;
}
.offer-presentation .offer-content .offer-neighborhood {
  background: #fff;
  padding: 20px;
  font-weight: 300;
}
.offer-presentation .offer-content .offer-neighborhood .row {
  margin-bottom: 5px;
}
.offer-presentation .offer-content .offer-neighborhood > .row {
  margin-bottom: 20px;
}
.offer-presentation .offer-content .offer-neighborhood h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.offer-presentation .offer-content .offer-neighborhood h4 span {
  background: #fff;
}
.offer-presentation .offer-content .offer-neighborhood img {
  margin: 0 auto;
}
.offer-presentation .offer-content .offer-neighborhood .title {
  font-weight: 500;
}
.offer-presentation .offer-tags-content {
  margin-top: 20px;
  font-size: 18px;
}
.offer-presentation .map {
  padding: 5px;
  border: 1px solid #b9b4b4;
}
.offer-presentation .map #gmap {
  width: 100%;
  height: 500px;
}
.offer-presentation #inquiry-form-container {
  position: relative;
}
.offer-presentation #inquiry-form-container .loading-overlay {
  background: #f0eeef;
}
.offer-presentation #inquiry-form-container form#inquiry-form div[class*=col-] {
  margin-bottom: 10px;
}
.offer-presentation #inquiry-form-container form#inquiry-form textarea {
  height: 120px;
}
.offer-presentation #inquiry-form-container form#inquiry-form label.error {
  margin-bottom: 0;
}
.offer-presentation .rating-details .rate {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 18px;
}
.offer-presentation .rating-details .rate > .star-rating {
  margin-right: 5px;
  vertical-align: top;
}
.sidebar-recommended .offer-cell {
  margin-bottom: 25px;
  margin-left: -10px;
  margin-right: -10px;
}
.sidebar-recommended .offer-cell div[class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar-recommended .offer-cell .description a {
  color: inherit;
}
.sidebar-recommended .offer-cell .description p {
  margin-bottom: 2px;
  font-size: 13px;
}
.sidebar-recommended .offer-cell .description .type {
  font-size: 12px;
}
.sidebar-recommended .offer-cell .description .distance {
  color: #736c6c;
  font-size: 12px;
}
@keyframes yellow-fade {
  0% {
    background: #FFFFFF;
  }
  10% {
    background: #ffee84;
  }
  100% {
    background: #FFFFFF;
  }
}
.offer-availability .highlight {
  animation: yellow-fade 1s ease-in 1;
}
.offer-availability-form {
  padding: 10px;
  margin-bottom: 40px;
}
.offer-availability-form .styled-select {
  display: block;
}
.offer-availability-form .styled-select:before {
  top: 18px;
}
.offer-availability-form select,
.offer-availability-form input[type="text"] {
  height: 50px;
}
.offer-availability-form input[type="submit"] {
  width: 100%;
}
@media (min-width: 768px) {
  .modal-entity-details .modal-dialog {
    width: 80%;
    max-width: 1200px;
  }
}
@media (max-width: 1199px) {
  .offer-presentation .offer-main .photo img {
    height: 411px;
  }
  .offer-presentation .room-list .entity .photo img {
    height: 162px;
  }
}
@media (max-width: 991px) {
  .offer-presentation .offer-main .photo img {
    height: 314px;
  }
  .offer-presentation .room-list .entity .photo img {
    height: 121px;
  }
  .offer-presentation .offer-info {
    display: block;
  }
  .offer-presentation .offer-info .offer-info-row {
    display: block;
    overflow: hidden;
  }
  .offer-presentation .offer-info .offer-info-row .address {
    display: block;
    width: 100%;
    border-right: none;
  }
  .offer-presentation .offer-info .offer-info-row .contact-cell {
    border-top: 1px solid #f0eeef;
    width: 25%;
    display: block;
    float: left;
  }
  .offer-presentation .offer-info .offer-info-row .alert-cell {
    display: block;
    margin: 20px 0;
    text-align: center;
  }
  .offer-presentation .offer-info .description-row {
    width: 100%;
  }
}
.offer-weather-widget .weather-widget {
  max-width: 210px;
  background: white;
  border: 1px solid #b9b4b4;
  padding: 15px;
  margin: 15px 0;
  text-align: right;
}
.offer-weather-widget .weather-widget .icon {
  float: left;
  margin: 0 5px;
}
.offer-weather-widget .weather-widget .temperature {
  font-size: 20pt;
}
.offer-weather-widget .weather-widget a {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .offer-presentation .offer-main .offer-header-bar .corner-contact {
    height: 45px;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact > div {
    width: 45px;
    height: 45px;
  }
  .offer-presentation .offer-main .photo img {
    height: auto;
    max-height: 435px;
  }
  .offer-presentation .offer-main h2 {
    font-size: 24px;
  }
  .offer-presentation .offer-main .gallery .carousel-inner .item {
    height: 475px;
  }
  .offer-presentation .offer-specials .section-title:after,
  .offer-presentation .offer-special-details .section-title:after {
    top: 28px;
  }
  .offer-presentation .offer-specials .section-title .icon-offer-special,
  .offer-presentation .offer-special-details .section-title .icon-offer-special {
    margin-right: 5px;
  }
  .offer-presentation .offer-content .offer-special-details .category.title {
    position: relative;
    padding: 0;
  }
  .offer-presentation .offer-content .offer-special-details .desc-title {
    position: relative;
    margin: 0;
    padding: 0;
    max-width: none;
  }
  .offer-presentation .offer-info {
    display: block;
  }
  .offer-presentation .offer-info .offer-info-row {
    display: block;
    overflow: hidden;
  }
  .offer-presentation .offer-info .offer-info-row .address {
    display: block;
    width: 100%;
    border-right: none;
  }
  .offer-presentation .offer-info .offer-info-row .contact-cell {
    border-top: 1px solid #f0eeef;
    width: 25%;
    display: block;
    float: left;
  }
  .offer-presentation .offer-info .offer-info-row .alert-cell {
    display: block;
    margin: 20px 0;
    text-align: center;
  }
  .offer-presentation .offer-info .description-row {
    width: 100%;
  }
  .offer-presentation .room-list .entity .photo img {
    height: auto;
    max-height: 236px;
  }
}
@media (max-width: 480px) {
  .offer-presentation .offer-main .offer-header-bar .corner-contact {
    width: 100%;
    float: none;
    margin: 0;
    height: auto;
    overflow: auto;
    text-align: center;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone {
    margin: 15px 0 15px 15px;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email {
    margin: 15px 15px 15px 0;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email,
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone {
    font-size: 18px;
    width: auto;
    display: inline-block;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    float: none;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email .text,
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone .text {
    display: none;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-email {
    margin-left: 15px;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-phone {
    width: 50px;
  }
  .offer-presentation .offer-main .offer-header-bar .corner-contact .corner-favorite {
    display: inline-block;
    float: none;
    margin: 15px 0 15px 15px;
    height: 40px;
    line-height: 40px;
  }
  .offer-presentation .offer-main .gallery .carousel-inner .item {
    height: 275px;
  }
  .offer-presentation .offer-info .offer-info-row .contact-cell {
    width: 50%;
  }
  .offer-presentation .offer-info .offer-info-row .contact-cell .contact {
    margin-bottom: 0;
  }
  .offer-presentation .offer-info .offer-info-row .contact-cell:nth-last-of-type(-n + 2) .contact {
    margin-bottom: 60px;
  }
  .offer-presentation .offer-content .nav-offer-content li a {
    padding: 15px 5px;
  }
  .offer-presentation .offer-content .offer-description ul.offer-amenity li {
    float: none;
    width: 100%;
  }
}
.offer-reviews {
  background: #fff;
  padding: 20px;
}
.review-form {
  position: relative;
}
#form-review {
  display: none;
  margin-bottom: 30px;
  border-bottom: 1px solid #b9b4b4;
  padding-bottom: 30px;
}
#form-review fieldset > div.row > div {
  margin-bottom: 5px;
}
#form-review .rating-star {
  color: #ef366c;
}
#form-review .rating-star select {
  display: none;
}
#form-review .actions {
  text-align: right;
  margin-top: 20px;
}
#form-review .actions .btn-const {
  margin: 0;
}
#form-review .name,
#form-review .location {
  line-height: 34px;
}
#form-review .name label,
#form-review .location label {
  margin: 0;
}
.entity-details-content .row {
  margin-left: 0;
  margin-right: 0;
}
.entity-details-content .row > div[class*="col"] {
  padding-left: 0;
  padding-right: 0;
}
.entity-details-content .entity-title {
  margin: 0;
  background: #F0EEEF;
  padding: 15px 30px 15px 15px;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.entity-details-content .entity-details {
  padding: 5px 20px;
}
.entity-details-content .entity-details > p {
  margin-bottom: 15px;
  font-weight: 300;
}
.entity-details-content .entity-details > p .paragraph-title {
  font-weight: 500;
}
.entity-details-content .entity-details > p > i {
  font-size: 28px;
  padding: 5px;
  vertical-align: middle;
  color: #918787;
}
.entity-details-content .entity-details .entity-amenities {
  list-style: none;
  margin: 0;
  padding: 0;
}
.entity-details-content .entity-details .entity-amenities li {
  display: inline-block;
  white-space: nowrap;
  line-height: 20px;
  margin: 5px 10px 5px 0px;
}
.entity-details-content .entity-details .entity-amenities li .nc {
  margin-right: 2px;
  font-size: 20px;
  vertical-align: text-bottom;
  color: #449fd1;
}
@media (max-width: 767px) {
  .modal.iframe.modal-entity-details .modal-dialog .modal-content .modal-body {
    top: 0;
  }
  .modal.iframe.modal-entity-details .modal-dialog .modal-content button.close {
    position: absolute;
    opacity: 1;
    color: #ffffff;
  }
  .iframe-content .entity-details-content {
    margin-top: 50px;
  }
  .iframe-content .entity-details-content .entity-title {
    position: fixed;
    top: 0;
    width: 100%;
  }
}
#inquiry-form-container form#inquiry-form .row {
  margin-left: -5px;
  margin-right: 5px;
}
#inquiry-form-container form#inquiry-form .row div[class*=col-] {
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
#inquiry-form-container form#inquiry-form .row div[class*=col-sm-6],
#inquiry-form-container form#inquiry-form .row div[class*=col-sm-4] {
  width: 100%;
}
#inquiry-form-container form#inquiry-form .row div[class*=col-sm-3] {
  width: 50%;
}
form#inquiry-form-short .date-icon input,
form#inquiry-form .date-icon input,
form#inquiry-form-short .name-icon input,
form#inquiry-form .name-icon input,
form#inquiry-form-short .email-icon input,
form#inquiry-form .email-icon input,
form#inquiry-form-short .phone-icon input,
form#inquiry-form .phone-icon input {
  padding-left: 35px;
}
form#inquiry-form-short .date-icon::before,
form#inquiry-form .date-icon::before,
form#inquiry-form-short .name-icon::before,
form#inquiry-form .name-icon::before,
form#inquiry-form-short .email-icon::before,
form#inquiry-form .email-icon::before,
form#inquiry-form-short .phone-icon::before,
form#inquiry-form .phone-icon::before {
  font-family: e-nocleg-icons;
  float: left;
  font-size: 18px;
  color: #b9b4b4;
  display: block;
  position: absolute;
  margin-top: 8px;
  margin-left: 8px;
  z-index: 2;
}
form#inquiry-form-short .date-icon:before,
form#inquiry-form .date-icon:before {
  content: '\e603';
}
form#inquiry-form-short .name-icon:before,
form#inquiry-form .name-icon:before {
  content: '\e90f';
}
form#inquiry-form-short .email-icon:before,
form#inquiry-form .email-icon:before {
  content: '\e601';
}
form#inquiry-form-short .phone-icon:before,
form#inquiry-form .phone-icon:before {
  content: '\e600';
}
form#inquiry-form-short .placeholder,
form#inquiry-form .placeholder {
  height: 40px;
  line-height: 30px;
}
form#inquiry-form-short input[type="text"],
form#inquiry-form input[type="text"] {
  height: 40px;
}
form#inquiry-form-short .input-group-btn .btn,
form#inquiry-form .input-group-btn .btn {
  height: 40px;
}
form#inquiry-form-short .form-element label.control-label,
form#inquiry-form .form-element label.control-label {
  float: left;
  font-size: 16px;
  line-height: 40px;
  margin: 0 3px 0 0;
  font-weight: 300;
}
form#inquiry-form-short .form-element label.error,
form#inquiry-form .form-element label.error {
  clear: both;
}
form#inquiry-form-short .form-element .number-control,
form#inquiry-form .form-element .number-control {
  float: left;
  width: 108px;
}
form#inquiry-form-short .form-element .number-control input,
form#inquiry-form .form-element .number-control input {
  text-align: center;
}
form#inquiry-form-short .form-element .number-control .btn,
form#inquiry-form .form-element .number-control .btn {
  width: 30px;
  padding: 0;
  margin-right: 0;
  margin-left: 0;
}
form#inquiry-form-short .form-element .number-control .input-group-btn:first-child .btn,
form#inquiry-form .form-element .number-control .input-group-btn:first-child .btn {
  border-right: 0;
}
form#inquiry-form-short .form-element .number-control .input-group-btn:last-child .btn,
form#inquiry-form .form-element .number-control .input-group-btn:last-child .btn {
  border-left: 0;
}
form#inquiry-form-short {
  background: #ffffff;
  padding: 10px;
  margin-bottom: 40px;
}
form#inquiry-form-short div[class*=col-] {
  margin-bottom: 10px;
}
form#inquiry-form-short .placeholder {
  height: 50px;
  line-height: 40px;
}
form#inquiry-form-short input[type="text"] {
  height: 50px;
}
form#inquiry-form-short .date-icon:before,
form#inquiry-form-short .name-icon:before,
form#inquiry-form-short .email-icon:before,
form#inquiry-form-short .phone-icon:before {
  margin-top: 13px;
}
.phone-modal .modal-dialog,
.inquiry-modal .modal-dialog {
  width: auto;
  max-width: 730px;
}
.phone-modal .modal-content .modal-header .modal-title,
.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 18px;
  line-height: 25.88px;
}
.phone-modal .modal-content .modal-body,
.inquiry-modal .modal-content .modal-body {
  padding: 25px;
  text-align: left;
}
.phone-modal .modal-content .modal-body .offer-name,
.inquiry-modal .modal-content .modal-body .offer-name {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.phone-modal .modal-content .modal-body .address span,
.inquiry-modal .modal-content .modal-body .address span {
  color: #736c6c;
  display: block;
}
.phone-modal .modal-content .modal-body .show-phone,
.inquiry-modal .modal-content .modal-body .show-phone {
  height: 40px;
  line-height: 40px;
  padding: 0px 25px;
  font-size: 15px;
}
.phone-modal .modal-content .modal-body .show-phone i,
.inquiry-modal .modal-content .modal-body .show-phone i {
  position: relative;
  top: 1px;
  margin-right: 15px;
  margin-left: -5px;
  color: #449fd1;
  font-size: 18px;
}
.phone-modal .modal-content .modal-body form#inquiry-form,
.inquiry-modal .modal-content .modal-body form#inquiry-form {
  margin-top: 10px;
}
.phone-modal .modal-content .modal-body form#inquiry-form .row,
.inquiry-modal .modal-content .modal-body form#inquiry-form .row {
  margin-left: -5px;
  margin-right: 5px;
}
.phone-modal .modal-content .modal-body form#inquiry-form .row .form-element textarea,
.inquiry-modal .modal-content .modal-body form#inquiry-form .row .form-element textarea {
  height: 130px;
}
.phone-modal .modal-content .modal-body form#inquiry-form .row div[class*=col-],
.inquiry-modal .modal-content .modal-body form#inquiry-form .row div[class*=col-] {
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.phone-modal .modal-dialog {
  width: auto;
  max-width: 530px;
}
.phone-modal .modal-content .modal-body h1 {
  margin-top: 0;
}
.phone-modal .modal-content .modal-body .phone-section .title {
  float: left;
  margin-top: 13px;
  width: 170px;
  font-size: 24px;
  line-height: 1.3;
  color: #333;
  font-family: Dosis;
}
.phone-modal .modal-content .modal-body .phone-section .phone-list {
  margin: 10px 0;
  margin-left: 170px;
}
.phone-modal .modal-content .modal-body .phone-section .phone-list a {
  margin-bottom: 5px;
  margin-right: 5px;
}
.phone-modal .modal-content .modal-body .disclaimer {
  margin: 0;
  margin-top: 10px;
}
.phone-modal .modal-content .modal-body .disclaimer .fa {
  font-size: 20px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  form#inquiry-form-short .date-icon .placeholder,
  form#inquiry-form .date-icon .placeholder {
    padding-left: 35px;
  }
  .phone-modal .modal-dialog,
  .inquiry-modal .modal-dialog {
    width: auto;
  }
  .phone-modal .modal-content .modal-body h1 {
    margin-top: 10px;
  }
  .phone-modal .modal-content .modal-body .phone-section .phone-list {
    margin: 0;
  }
}
.offer-availability-tab {
  background: #fff;
  padding-bottom: 15px;
}
.offer-availability-tab .legend-container {
  padding: 30px 0 30px 55px;
  font-weight: 300;
}
.offer-availability-tab .legend-container span {
  padding-left: 15px;
}
.offer-availability-tab .legend {
  width: 30px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}
.offer-availability-tab .legend.legend-white {
  background: #fff;
  border: 1px solid #b9b4b4;
}
.offer-availability-tab .legend.legend-orange {
  background: #ef366c;
  border: 1px solid #ef366c;
}
.offer-availability-tab .calendar-container {
  text-align: center;
}
.offer-availability-tab .calendar-month {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #f0eeee;
  border-collapse: collapse;
  overflow: hidden;
  margin: 5px;
}
.offer-availability-tab .calendar-month th {
  text-transform: capitalize;
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;
}
.offer-availability-tab .calendar-month th.sunday {
  color: #D8000C;
}
.offer-availability-tab .calendar-month thead tr:last-child {
  color: #449fd1;
}
.offer-availability-tab .calendar-month .title {
  text-align: center;
  text-transform: capitalize;
  font-size: 10pt;
  line-height: 36px;
  background: transparent;
  color: #918787;
}
.offer-availability-tab .calendar-month td {
  min-width: 30px;
  min-height: 30px;
  line-height: 24px;
  margin: 1px;
  padding: 2px 5px;
  text-align: center;
  border: 1px solid #fff;
  font-size: 12px;
}
.offer-availability-tab .calendar-month td.past {
  color: #736c6c;
}
.offer-availability-tab .calendar-month td.saturday {
  background: #f0f0f0;
}
.offer-availability-tab .calendar-month td.sunday {
  color: #D8000C;
  background: #f0f0f0;
}
.offer-availability-tab .calendar-month td.selected {
  background: #ef366c;
  color: #fff;
}
.modal-offer-preview.modal.iframe button.close {
  top: 20px;
}
.iframe-offer-preview,
.iframe-entity-preview {
  overflow: hidden;
}
.offer-preview-content .photos {
  float: left;
  width: 848px;
  min-height: 500px;
}
.offer-preview-content .photos .carousel.gallery .carousel-thumbnails {
  margin: 0;
  position: relative;
  background: #000;
}
.offer-preview-content .details {
  float: left;
  width: 352px;
  position: relative;
  min-height: 556px;
}
.offer-preview-content .details .address span {
  color: #736c6c;
  display: block;
}
.offer-preview-content .details .phone-section {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.25s ease-in-out;
}
.offer-preview-content .details .phone-section .title {
  margin-top: 13px;
}
.offer-preview-content .details .phone-section .phone-list {
  margin: 10px 0;
}
.offer-preview-content .details .phone-section .phone-list a {
  margin-bottom: 5px;
  margin-right: 5px;
}
.offer-preview-content .details .phone-section.not-visible {
  visibility: hidden;
  opacity: 0;
}
.offer-preview-content .details .disclaimer {
  margin: 10px;
}
.offer-preview-content .details .disclaimer .fa {
  font-size: 20px;
  margin-right: 5px;
}
.offer-preview-content .details .call-us {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #ffffff;
}
.offer-preview-content .offer-title {
  margin: 0;
  background: #F0EEEF;
  padding: 15px 40px 15px 15px;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer-preview-content .offer-details {
  padding: 5px 20px;
}
.offer-preview-content .offer-details > p {
  margin-bottom: 15px;
  font-weight: 300;
}
.offer-preview-content .offer-details > p .paragraph-title {
  font-weight: 500;
}
.offer-preview-content .offer-details > p i {
  font-size: 28px;
  padding: 5px;
  vertical-align: middle;
  color: #918787;
}
.payment.order {
  margin-bottom: 40px;
}
.payment.order .container {
  max-width: 750px;
}
.payment.order .order-summary-table tbody tr:last-of-type {
  border-bottom: 2px solid #ddd;
}
.payment.order .order-payment-summary dl dt {
  float: left;
  width: 150px;
  text-align: left;
  font-weight: normal;
}
.payment.order .order-payment-summary dl dd {
  margin-left: 150px;
  text-align: right;
  font-weight: bold;
}
.payment.order .order-payment-table {
  margin-bottom: 15px;
}
.payment.order .bonus {
  white-space: nowrap;
  color: #17b79b;
}
.payment.order .help.promo {
  background: #f0eeef;
  padding: 1em;
}
.payment.order .help .fa {
  font-size: 2em;
  float: left;
  margin-top: 0em;
  margin-bottom: 1em;
  margin-left: 0.2em;
  margin-right: 0.5em;
  padding: 0.2em;
  color: #449fd1;
}
.payment.order #form-payment-charge .has-error .control-label {
  color: #D8000C;
}
.payment.order #form-payment-charge .input-group {
  margin-right: 10px;
}
.payment .alert.message {
  margin: 40px 0;
}
.payment.balance #form-payment-charge .form-element {
  width: 100px;
  display: inline-block;
}
.payment.balance #form-payment-charge label {
  display: inline-block;
  vertical-align: top;
  margin: 8px 10px 8px 0;
}
.payment.balance #form-payment-charge .help {
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
}
.payment.balance #form-payment-charge .actions input {
  width: 100%;
}
.payment .offer-info {
  max-width: 750px;
  margin: 40px 0;
}
.payment .offer-info .offer-name {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
}
.payment .offer-info .address span {
  display: block;
  color: #736c6c;
}
.payment .order-header .form-group {
  margin-left: 0;
  margin-right: 0;
}
.payment .order-header .form-group > div.item {
  padding-top: 7px;
}
.payment.subscription .container {
  max-width: 1000px;
}
#form-order fieldset {
  margin-bottom: 15px;
}
#form-order fieldset#fieldset-bill {
  margin-bottom: 0;
}
#form-order fieldset#fieldset-ship {
  margin-top: 10px;
  margin-bottom: 10px;
}
#form-order .form-group.invoice {
  margin-bottom: 0;
}
#form-order .no-show {
  display: none;
}
.btn-track-transaction-next {
  animation: 3s buttonTrackTransactionNextAnimation;
  animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 0;
}
@keyframes buttonTrackTransactionNextAnimation {
  80% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
#form-increase-priority .daily-priority,
#form-increase-priority .offer-position {
  display: inline-block;
  margin-bottom: 15px;
}
#form-increase-priority .decrement-increment {
  display: inline-block;
  vertical-align: middle;
}
#form-increase-priority .decrement-increment .number-control {
  max-width: 123px;
  margin: 0 5px;
}
#form-increase-priority .decrement-increment .number-control input {
  text-align: center;
}
.priority-error {
  margin-top: 15px;
}
.priority-error .btn {
  margin-bottom: 15px;
}
section.section-guide .guide-image {
  position: relative;
}
section.section-guide .guide-image .desc {
  bottom: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 15px;
  font-size: 25px;
  font-weight: 300;
}
section.section-guide .content {
  padding: 20px;
  font-weight: 300;
}
section.section-guide .content strong {
  font-weight: 500;
}
section.section-guide .content .guide-button {
  margin: 0;
  margin-top: 10px;
}
.guide-page a,
.guide-attractions a {
  color: #333;
}
.guide-photos-preview .photo {
  margin-bottom: 30px;
}
.guide-weather-preview .weather {
  background: #ef366c;
  color: #fff;
  padding: 20px;
}
.guide-weather-preview .weather h4 {
  color: #fff;
}
.guide-weather-preview .weather table {
  width: 100%;
}
.guide-weather-preview .weather table .picture {
  width: 80px;
}
.guide-weather-preview .weather table .temperature {
  width: 35px;
  font-size: 20px;
}
.guide-weather-preview .weather table .details {
  border-left: 1px solid #fff;
  padding-left: 15px;
}
.guide-map .map,
.guide-map-preview .map {
  padding: 5px;
  border: 1px solid #b9b4b4;
  margin-bottom: 10px;
}
.guide-map .map #guide-gmap,
.guide-map-preview .map #guide-gmap {
  height: 400px;
}
.guide-map .description,
.guide-map-preview .description {
  color: #736c6c;
  font-size: 12px;
  line-height: 40px;
}
.guide-map #guide-gmap {
  height: 500px;
}
.guide-content-preview {
  font-weight: 300;
}
.guide-content-preview .photos-preview .photos {
  margin-left: -5px;
  margin-right: -5px;
}
.guide-content-preview .photos-preview .photos div[class*=col-] {
  padding: 5px;
}
.guide-content-preview .description {
  color: #736c6c;
  font-size: 12px;
}
.guide-content-preview .guide-link {
  text-align: right;
  font-size: 15px;
  margin: 10px 0;
  font-weight: normal;
}
.guide-content-preview .guide-link a {
  color: #ef366c;
  text-decoration: none;
  text-align: right;
}
.guide-content-preview .guide-link a:hover {
  color: #dd1c55;
}
.guide-content-preview .map-preview .map {
  padding: 5px;
  border: 1px solid #b9b4b4;
}
.guide-content-preview .map-preview .map #guide-preview-gmap {
  height: 200px;
}
.guide-weather .forecast .weather,
.weather-preview .forecast .weather {
  background: #ef366c;
  color: #fff;
  padding: 20px;
}
.guide-weather .forecast .weather h4,
.weather-preview .forecast .weather h4 {
  color: #fff;
}
.guide-weather .forecast .weather .picture,
.weather-preview .forecast .weather .picture {
  font-size: 30px;
}
section.guide-weather .current-weather {
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 30px;
}
section.guide-weather .current-weather .current-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 210px;
  background: #ef366c;
  color: #fff;
  padding: 0px 15px;
}
section.guide-weather .current-weather .current-info .weather-picture {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}
section.guide-weather .current-weather .current-info .temp {
  font-size: 24px;
  margin-top: 10px;
}
section.guide-weather .current-weather .current-info .temp-realfeel {
  font-size: 16px;
  margin-bottom: 10px;
}
section.guide-weather .current-weather .title {
  color: #ef366c;
  font-size: 18px;
  padding-right: 210px;
  min-height: 76px;
}
section.guide-weather .current-weather table {
  width: 100%;
}
section.guide-weather .current-weather table tbody th {
  width: 70%;
}
section.guide-weather .current-weather table tbody td {
  width: 30%;
}
section.guide-weather .current-weather table tbody td,
section.guide-weather .current-weather table tbody th {
  font-weight: 300;
  padding: 5px 2px;
  font-size: 13px;
}
section.guide-weather .forecast .weather {
  margin-bottom: 30px;
}
section.guide-weather .forecast .accuWeather {
  text-align: right;
  color: #736c6c;
  font-size: 12px;
}
.guide-photos .guide-image {
  margin-bottom: 30px;
}
.guide-photos .photos > div {
  margin-bottom: 30px;
}
.guide-photos .content-info {
  text-align: right;
  color: #736c6c;
  font-size: 12px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  section.guide-weather .current-weather .current-info {
    position: relative;
    width: 100%;
  }
  section.guide-weather .current-weather .title {
    min-height: 0;
    padding-right: 0;
  }
}
.error-message {
  padding-top: 5em;
  padding-bottom: 5em;
}
.error-action {
  text-align: center;
  margin-bottom: 3em;
}
.error-debug {
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px dotted #b9b4b4;
}
.error-debug pre {
  word-wrap: normal;
}
.dictionary-locations .list-title,
.dictionary-types .list-title {
  text-transform: capitalize;
  margin-bottom: 10px;
}
.dictionary-locations .simple-list,
.dictionary-types .simple-list {
  list-style: none;
  padding-left: 0;
}
.dictionary-locations .simple-list a,
.dictionary-types .simple-list a {
  font-family: Roboto;
  text-decoration: none;
  color: #736c6c;
  font-weight: 300;
}
.dictionary-locations .simple-list a:hover,
.dictionary-types .simple-list a:hover {
  color: #333;
  text-decoration: underline;
}
.dictionary-locations .letter-navigation .border-right {
  border-right: 1px solid #b9b4b4;
  padding: 10px 0;
}
.dictionary-locations .letter-navigation .letter {
  display: inline-block;
  width: 42px;
}
.dictionary-locations .letter-navigation a {
  font-family: Roboto;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0 15px;
  text-decoration: none;
  color: #483f3f;
  font-weight: 300;
}
.dictionary-locations .letter-navigation a:hover {
  text-decoration: underline;
}
.dictionary-types .types-list {
  padding-left: 0;
}
.dictionary-types .types-list li {
  color: #483f3f;
  list-style-type: none;
}
.dictionary-types .types-list li:nth-child(-n+3) span.link-container {
  border-top: 1px solid #b9b4b4;
}
.dictionary-types .types-list li a {
  color: #483f3f;
  text-transform: capitalize;
  font-weight: 300;
}
.dictionary-types .types-list li a:before {
  display: inline-block;
  background: #ef366c;
  width: 5px;
  height: 5px;
  margin: 2pt 20px 2pt 0;
  content: " ";
}
.dictionary-types .types-list li span.link-container {
  display: block;
  border-bottom: 1px solid #b9b4b4;
  padding: 15px;
}
@media (max-width: 767px) {
  .dictionary-types .types-list li:nth-child(-n+3) span.link-container {
    border-top: none;
  }
}
.cookie-info {
  background: #f0eeef;
}
.cookie-info .container {
  position: relative;
}
.cookie-info .cookie-message {
  color: #736c6c;
  margin: 15px 100px 15px 0;
  font-size: 12px;
  font-weight: 300;
}
.cookie-info .cookie-button {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
}
.cookie-info .nc-cookie {
  background: url('/img/nc-cookie.svg');
  background-size: contain;
  height: 17px;
  width: 17px;
  display: block;
  float: left;
  margin-right: 5px;
}
@media (max-width: 480px) {
  .cookie-info .cookie-message {
    margin: 15px 0;
    font-size: 8pt;
  }
  .cookie-info .cookie-button {
    position: relative;
    margin: 0 0 15px 0;
    display: block;
    text-align: right;
  }
  .cookie-info .nc-cookie {
    height: 30px;
    width: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
.rentcars_banner {
  border: 1px solid #33962d;
  border-radius: 4px;
  background: url('/img/rentcar-banner/background.jpg') no-repeat bottom;
  margin: 15px auto;
  max-width: 210px;
}
.rentcars_banner .new {
  float: right;
  width: 55px;
  height: 45px;
  background: url('/img/rentcar-banner/new.png') no-repeat;
}
.rentcars_banner .content {
  margin: 0;
  padding: 0;
  border: none;
}
.rentcars_banner .content a.title {
  display: block;
  padding: 12px 10px 100px 10px;
  margin: 0;
}
.rentcars_banner .content a.title span {
  font-size: 8pt;
  color: #33962d;
  margin-bottom: 7px;
  display: block;
  font-weight: normal;
  line-height: 14px;
}
.rentcars_banner .content a.title span.city {
  font-size: 16pt;
  font-weight: bold;
  color: #000000;
  display: block;
  line-height: 1em;
}
.rentcars_banner .content a.title:hover {
  text-decoration: none;
}
.rentcars_banner .rent_button {
  display: block;
  background: url('/img/rentcar-banner/button.png') no-repeat 0 0;
  width: 182px;
  height: 37px;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 15px;
}
.rentcars_banner .rent_button:hover {
  background: url('/img/rentcar-banner/button.png') no-repeat -1px -37px;
}
.rentcars_banner .logo {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}
.rentcars_banner .popover {
  max-width: none;
}
.rentcars-popover {
  width: 460px;
  position: relative;
}
.rentcars-popover form {
  width: 100%;
  margin: 0;
  margin-bottom: .5em;
  padding: 0;
}
.rentcars-popover form fieldset {
  float: left;
  margin-right: 5px;
  width: 225px;
  border: none;
  padding: 0;
}
.rentcars-popover form fieldset legend {
  font-size: 12pt;
  line-height: 25px;
  padding: 0 2px;
  border-bottom: 1px solid #e5e5e5;
  color: #333333;
  font-weight: normal;
  margin-bottom: 20px;
  width: 100%;
}
.rentcars-popover form dl {
  margin: 0;
  content: "";
  display: table;
  line-height: 0;
  clear: both;
  width: 100%;
  padding-bottom: 0;
}
.rentcars-popover form dl dt {
  padding-left: 5px;
  width: 100px;
  clear: left;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rentcars-popover form dl dt label {
  font-size: 9pt;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 15px;
}
.rentcars-popover form dl dd {
  line-height: 20px;
  margin-left: 10px;
  width: 115px;
  float: left;
}
.rentcars-popover form dl dd input {
  font-size: 14px;
  padding: 7px;
  width: 75px;
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  color: #555555;
  display: inline-block;
  line-height: 30px;
  margin-left: 0;
}
.rentcars-popover form dl dd .input-group-addon {
  cursor: pointer;
}
.rentcars-popover form dl dd .styled-select {
  display: inline-block;
  width: 55px;
}
.rentcars-popover form dl dd .styled-select:before {
  right: 10px;
}
.rentcars-popover form dl dd .styled-select select {
  width: 100%;
  font-size: 14px;
}
.rentcars-popover form .action {
  margin-right: 5px;
  text-align: right;
}
#sidebar-search {
  margin-bottom: 40px;
}
#sidebar-search h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.static-map h1.page-title {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  padding-bottom: 0;
}
.static-map .page-subtitle {
  display: block;
  font-size: 24px;
  color: #736c6c;
  padding-bottom: 30px;
}
.static-map h3 {
  margin-bottom: 10px;
}
.static-map ul.two-columns li {
  width: 50%;
  float: left;
}
.locationSearchForm {
  margin-bottom: 10px;
}
.locationSearchForm form .row {
  margin-left: -2px;
  margin-right: -2px;
}
.locationSearchForm form div[class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
}
.locationSearchForm form input[type=text] {
  height: 50px;
}
.locationsLetters {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
}
.locationsLetters .choose-letter {
  line-height: 30px;
}
.locationsLetters ul.letters-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.locationsLetters ul.letters-list li {
  display: block;
  float: left;
  margin: 0;
  padding: 4px 5px;
  border-left: 1px solid #ef366c;
}
.locationsLetters ul.letters-list li:first-child {
  border-left: none;
}
.locationsLetters ul.letters-list li a {
  color: #000000;
}
.lettersContent ul,
.locationsList ul {
  overflow: hidden;
  margin-bottom: 10px;
}
.lettersContent ul li,
.locationsList ul li {
  width: 50%;
  float: left;
}
.lettersContent .paginationControl,
.locationsList .paginationControl {
  text-align: center;
}
#weatherIndexMap,
#weatherSearchResultsMap,
#locationMap {
  height: 450px;
}
#weatherIndexMap .leaflet-popup-content a,
#weatherSearchResultsMap .leaflet-popup-content a,
#locationMap .leaflet-popup-content a {
  color: #333;
}
#weatherIndexMap .leaflet-popup-content a span,
#weatherSearchResultsMap .leaflet-popup-content a span,
#locationMap .leaflet-popup-content a span {
  color: #a69c9c;
}
#weatherIndexMap {
  height: 450px;
  margin-bottom: 10px;
}
#weatherIndexMap .weather-popup .weather-popup-icon {
  float: left;
}
#weatherIndexMap .weather-popup .weather-popup-content h1 {
  font-size: 12pt;
  margin: 0 0 5px 69px;
  line-height: 40px;
}
#weatherIndexMap .weather-popup .weather-popup-content dl {
  margin: 0 0 5px 0;
}
#weatherIndexMap .weather-popup .weather-popup-content dl dt {
  float: left;
}
#weatherIndexMap .weather-popup .weather-popup-content dl dd {
  margin-left: 80px;
}
.weather {
  margin-bottom: 30px;
}
.weather h1.page-title {
  margin-top: 0;
  text-align: left;
}
.weather h1.page-title small {
  display: block;
}
.weather .forecast ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.weather .forecast ul li {
  margin: 5px 0;
  padding: 0;
}
.weather .forecast ul li .name {
  display: table;
}
.weather .forecast ul li .name img {
  float: left;
  margin-right: 10px;
}
.weather .forecast ul li .name span.day {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  text-align: left;
}
.weather .forecast ul li .name a {
  display: table-cell;
  vertical-align: middle;
}
.weather .forecast ul li .description {
  font-size: 9pt;
  background: #fef2f6;
  line-height: 40px;
}
.weather .forecast ul li .description span {
  white-space: nowrap;
  display: block;
  float: left;
  width: 25%;
}
.weather .forecast ul li .description span.title {
  font-weight: 500;
}
.weather .forecast ul li .description span.value {
  color: #dc124c;
}
.weather .forecast ul li .more-link {
  font-size: 9pt;
  white-space: nowrap;
  background: #fef2f6;
  line-height: 40px;
}
.weather .forecast ul li .more-link a {
  color: #f3004c;
}
.weather .forecast ul li .weather-popup {
  display: none;
}
.weather .shortInfo {
  position: relative;
  height: 40px;
  display: table;
  border-top: 1px solid #ef366c;
  border-bottom: 1px solid #ef366c;
}
.weather .shortInfo .description {
  display: table-cell;
  vertical-align: middle;
  padding-right: 150px;
  width: 100%;
  height: 40px;
  text-align: center;
}
.weather .shortInfo .date {
  position: absolute;
  width: 250px;
  height: 40px;
  right: 0;
  top: 0;
  text-align: center;
  padding: 5px;
  background: #f8dbe3;
  font-size: 12pt;
  line-height: 30px;
}
.weather .shortInfo .date a {
  color: #dd1c55;
}
.weather .longInfo {
  border-bottom: 1px solid #ef366c;
}
.weather .longInfo .icon {
  float: left;
  margin: 20px 0;
}
.weather .longInfo .data {
  margin-left: 80px;
  overflow: hidden;
}
.weather .longInfo .data .item {
  width: 33.33%;
  float: left;
  position: relative;
  padding: 5px;
  margin: 2px 0;
  font-size: 9pt;
}
.weather .longInfo .data .item .item-title {
  padding-right: 70px;
  width: 100%;
  display: block;
}
.weather .longInfo .data .item .item-value {
  position: absolute;
  width: 65px;
  right: 0;
  top: 0;
  text-align: center;
  padding: 5px;
  background: #f8dbe3;
}
.weather .nearby-locations {
  overflow: hidden;
}
.weather .nearby-locations li {
  float: left;
  width: 50%;
}
.weather #mini-location-map {
  height: 180px;
}
.weather .accu-weather-link {
  text-align: right;
}
.weather-widget .icon {
  display: inline-block;
  vertical-align: middle;
}
.weather-widget .temperature {
  font-size: 1.5em;
  display: inline-block;
}
.weather-widget a {
  display: block;
}
.icon-offer-special-wielkanoc {
  background: url('/img/offer-special/wc-icon_wielkanoc-kolor.svg') no-repeat;
}
.icon-offer-special-weekend-majowy {
  background: url('/img/offer-special/wc-icon_weekend-majowy-kolor.svg') no-repeat;
}
.icon-offer-special-weekend-czerwcowy {
  background: url('/img/offer-special/wc-icon_weekend-czerwcowy-kolor.svg') no-repeat;
}
.icon-offer-special-wakacje {
  background: url('/img/offer-special/wc-icon_wakacje-kolor.svg') no-repeat;
}
.icon-offer-special-zielone-szkoly {
  background: url('/img/offer-special/wc-icon_zielone-szkoly-kolor.svg') no-repeat;
}
.icon-offer-special-weekend-sierpniowy {
  background: url('/img/offer-special/wc-icon_weekend-sierpniowy-kolor.svg') no-repeat;
}
.icon-offer-special-narty {
  background: url('/img/offer-special/wc-icon_narty-kolor.svg') no-repeat;
}
.icon-offer-special-sylwester {
  background: url('/img/offer-special/wc-icon_nowy-rok-kolor.svg') no-repeat;
}
.icon-offer-special-andrzejki {
  background: url('/img/offer-special/wc-icon_andrzejki-kolor.svg') no-repeat;
}
.icon-offer-special-boze-narodzenie {
  background: url('/img/offer-special/wc-icon_boze-narodzenie-kolor.svg') no-repeat;
}
.icon-offer-special-ferie {
  background: url('/img/offer-special/wc-icon_ferie-kolor.svg') no-repeat;
}
.icon-offer-special-walentynki {
  background: url('/img/offer-special/wc-icon_walentynki-kolor.svg') no-repeat;
}
.icon-offer-special-weekend {
  background: url('/img/offer-special/wc-icon_weekend-kolor.svg') no-repeat;
}
.icon-offer-special-last-minute {
  background: url('/img/offer-special/nc-icon_last-minute-kolor.svg') no-repeat;
}
.icon-offer-special-wielkanoc-gray {
  background: url('/img/offer-special/nc-icon_wielkanoc.svg') no-repeat;
}
.icon-offer-special-weekend-majowy-gray {
  background: url('/img/offer-special/nc-icon_weekend-majowy.svg') no-repeat;
}
.icon-offer-special-weekend-czerwcowy-gray {
  background: url('/img/offer-special/nc-icon_weekend-czerwcowy.svg') no-repeat;
}
.icon-offer-special-wakacje-gray {
  background: url('/img/offer-special/nc-icon_wakacje.svg') no-repeat;
}
.icon-offer-special-zielone-szkoly-gray {
  background: url('/img/offer-special/nc-icon_zielone-szkoly.svg') no-repeat;
}
.icon-offer-special-weekend-sierpniowy-gray {
  background: url('/img/offer-special/nc-icon_weekend-sierpniowy.svg') no-repeat;
}
.icon-offer-special-narty-gray {
  background: url('/img/offer-special/nc-icon_narty.svg') no-repeat;
}
.icon-offer-special-sylwester-gray {
  background: url('/img/offer-special/nc-icon_nowy-rok.svg') no-repeat;
}
.icon-offer-special-andrzejki-gray {
  background: url('/img/offer-special/nc-icon_andrzejki.svg') no-repeat;
}
.icon-offer-special-boze-narodzenie-gray {
  background: url('/img/offer-special/nc-icon_boze-narodzenie.svg') no-repeat;
}
.icon-offer-special-ferie-gray {
  background: url('/img/offer-special/nc-icon_ferie.svg') no-repeat;
}
.icon-offer-special-walentynki-gray {
  background: url('/img/offer-special/nc-icon_walentynki.svg') no-repeat;
}
.icon-offer-special-weekend-gray {
  background: url('/img/offer-special/nc-icon_weekend.svg') no-repeat;
}
.icon-offer-special-last-minute-gray {
  background: url('/img/offer-special/nc-icon_last-minute.svg') no-repeat;
}
.icon-offer-special {
  display: inline-block;
  vertical-align: middle;
}
.iofs-40 {
  width: 40px;
  height: 40px;
  background-size: 40px;
}
.iofs-100 {
  width: 100px;
  height: 100px;
  background-size: 100px;
}
.iofs-150 {
  width: 150px;
  height: 150px;
  background-size: 150px;
}
.icon-offer-special-wide-andrzejki {
  background: url('/img/offer-special/bg_andrzejki.png') no-repeat;
}
.icon-offer-special-wide-boze-narodzenie {
  background: url('/img/offer-special/bg_boze-narodzenie.png') no-repeat;
}
.icon-offer-special-wide-ferie {
  background: url('/img/offer-special/bg_ferie-zimowe.png') no-repeat;
}
.icon-offer-special-wide-narty {
  background: url('/img/offer-special/bg_narty.png') no-repeat;
}
.icon-offer-special-wide-sylwester {
  background: url('/img/offer-special/bg_nowy-rok.png') no-repeat;
}
.icon-offer-special-wide-walentynki {
  background: url('/img/offer-special/bg_walentynki.png') no-repeat;
}
.icon-offer-special-wide-weekend-czerwcowy {
  background: url('/img/offer-special/bg_weekend-czerwcowy.png') no-repeat;
}
.icon-offer-special-wide-weekend-majowy {
  background: url('/img/offer-special/bg_weekend-majowy.png') no-repeat;
}
.icon-offer-special-wide-weekend-sierpniowy {
  background: url('/img/offer-special/bg_weekend-sierpniowy.png') no-repeat;
}
.icon-offer-special-wide-wielkanoc {
  background: url('/img/offer-special/bg_wielkanoc.png') no-repeat;
}
.icon-offer-special-wide-zielone-szkoly {
  background: url('/img/offer-special/bg_zielone-szkoly.png') no-repeat;
}
.icon-offer-special-wide-weekend {
  background: url('/img/offer-special/bg_weekend.png') no-repeat;
}
.icon-offer-special-wide-last-minute {
  background: url('/img/offer-special/bg_last-minute.png') no-repeat;
}
.icon-offer-special-wide-wakacje {
  background: url('/img/offer-special/bg_wakacje.png') no-repeat;
}
.icon-offer-special-wide {
  display: block;
  background-size: 100%;
  background-position: center;
  width: 100%;
  min-height: 160px;
}
.iofsw-gradient:after {
  display: block;
  width: 100%;
  min-height: 160px;
  content: " ";
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyNSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMC41Ii8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, #ffffff 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, right top, color-stop(15%, #ffffff), color-stop(50%, rgba(255, 255, 255, 0.5)), color-stop(75%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(left, #ffffff 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(left, #ffffff 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(left, #ffffff 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
  /* IE10 preview */
  background: linear-gradient(to right, #ffffff 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-8 */
}
@media (max-width: 480px) {
  .iofs-150 {
    width: 60px;
    height: 60px;
    background-size: 60px;
  }
}
.ellipsis-vertical {
  position: relative;
  max-height: 160px;
  overflow: hidden;
}
.ellipsis-vertical:after {
  height: 40px;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(255, 255, 255, 0);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorStr=#000000, endColorStr=#ffffff);
  /* IE6-9 brak obsługi RGBA */
}
table.subscription-table {
  width: 100%;
}
table.subscription-table .entity-limit-reached {
  margin-top: 5px;
  font-size: 10pt;
}
table.subscription-table thead tr td {
  border: 0;
}
table.subscription-table tr th {
  border: 1px solid #f0eeef;
}
table.subscription-table tr th.highlight {
  background: #ef366c;
  color: #fff;
}
table.subscription-table tr td {
  border: 1px solid #f0eeef;
}
table.subscription-table tr td.highlight {
  background: #fef2f6;
}
table.subscription-table tr td .fa {
  font-size: 20pt;
}
table.subscription-table tr td .fa.fa-check {
  color: #17b79b;
}
table.subscription-table tr td .fa.fa-times {
  color: #D8000C;
}
table.subscription-table tbody tr th {
  background: #f0eeef;
}
table.subscription-table.lg-subscription th,
table.subscription-table.lg-subscription td {
  padding: 15px;
}
table.subscription-table.lg-subscription thead td {
  font-size: 14pt;
  width: 260px;
}
table.subscription-table.lg-subscription thead th {
  text-align: center;
  font-size: 17pt;
}
table.subscription-table.lg-subscription thead th small {
  font-size: 10pt;
  font-weight: normal;
  display: block;
}
table.subscription-table.lg-subscription tbody th {
  font-weight: normal;
  position: relative;
  padding-right: 30px;
}
table.subscription-table.lg-subscription tbody th.empty {
  background: none;
  border: 0;
}
table.subscription-table.lg-subscription tbody th .btn-link {
  color: inherit;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
}
table.subscription-table.lg-subscription tbody th .popover {
  width: 270px;
}
table.subscription-table.lg-subscription tbody td {
  padding: 10px;
  text-align: center;
  font-size: 15pt;
}
table.subscription-table.xs-subscription thead td {
  padding: 7px;
  font-size: 10pt;
}
table.subscription-table.xs-subscription tbody tr th,
table.subscription-table.xs-subscription tbody tr td {
  padding: 7px;
}
table.subscription-table.xs-subscription tbody tr th {
  font-size: 10pt;
}
table.subscription-table.xs-subscription tbody tr th p {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 9pt;
}
table.subscription-table.xs-subscription tbody tr td {
  font-size: 10pt;
}
table.subscription-table.xs-subscription tbody tr td .button-blue,
table.subscription-table.xs-subscription tbody tr td .button-pink {
  padding: 0 15px;
}
table.subscription-table.specification {
  margin-top: 20px;
  display: none;
}
table.subscription-table.specification th {
  font-weight: normal;
  padding: 10px;
}
table.subscription-table.specification td {
  width: 40px;
  text-align: center;
}
table.subscription-table.specification td .fa {
  font-size: 18pt;
}
.trial-banner {
  max-width: 590px;
  margin: 0 auto;
}
.trial-banner .free-things {
  width: 100%;
  height: 50px;
  position: relative;
  font-size: 21pt;
  line-height: 50px;
  color: #fff;
}
.trial-banner .free-things .free-days,
.trial-banner .free-things .free-points {
  background: #ef366c;
  width: 50%;
  height: 50px;
  padding: 0 35px;
  font-size: 14pt;
}
.trial-banner .free-things .free-days > span,
.trial-banner .free-things .free-points > span {
  font-size: 21pt;
  font-weight: bold;
}
.trial-banner .free-things .free-days {
  border-radius: 25px 0 0 25px;
  box-shadow: -7px -7px 0px 0px rgba(0, 0, 0, 0.3);
  float: left;
}
.trial-banner .free-things .free-points {
  border-radius: 0 25px 25px 0;
  text-align: right;
  float: right;
}
.trial-banner .free-things .zero {
  position: absolute;
  top: -60px;
  left: 50%;
  margin-left: -60px;
  font-size: 35px;
  line-height: 84px;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background: #17b79b;
}
.trial-banner .free-things .zero .zero-content {
  position: relative;
  top: 10px;
  left: 10px;
  border: 8px solid #ef366c;
  background: #fff;
  color: #ef366c;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  text-align: center;
  box-shadow: -7px -7px 0px 0px rgba(0, 0, 0, 0.3);
}
.trial-banner .free-description {
  width: 100%;
  padding: 0 25px;
}
.trial-banner .free-description .free-days,
.trial-banner .free-description .free-points {
  width: 50%;
  height: 130px;
  line-height: 25px;
  float: left;
  font-family: Roboto;
  font-size: 13pt;
  color: #483f3f;
}
.trial-banner .free-description .free-days .description-content,
.trial-banner .free-description .free-points .description-content {
  background: #fff;
  padding: 25px 18px;
  height: 130px;
}
.trial-banner .free-description .free-days {
  padding-right: 2px;
  box-shadow: -7px -7px 0px 0px rgba(0, 0, 0, 0.3);
}
.trial-banner .free-description .free-points {
  text-align: right;
  padding-left: 2px;
}
.trial-banner .banner-button {
  text-align: center;
  margin-top: 2em;
}
.trial-banner .banner-button a {
  display: inline-block;
  padding: 0px 40px;
  border: none;
  border-radius: 0;
  line-height: 50px;
  height: 50px;
  color: #fff;
  font-size: 13pt;
  font-family: Dosis;
  font-weight: normal;
  cursor: pointer;
  background: #17b79b;
  border-bottom: 4px solid #14a088;
  height: auto !important;
  font-size: 18pt !important;
  line-height: 20pt !important;
  padding: 12px 65px !important;
}
.trial-banner .banner-button a.btn-block {
  text-align: center;
  padding: 0;
  width: 100%;
}
.trial-banner .banner-button a:hover {
  text-decoration: none;
  color: #fff;
}
.trial-banner .banner-button a.btn-const {
  width: 230px;
  text-align: center;
}
.trial-banner .banner-button a.btn-xs {
  font-size: 9pt;
  padding: 0px 5px;
  height: 28px;
  line-height: 28px;
}
.trial-banner .banner-button a.btn-xs.btn-const {
  width: 80px;
}
.trial-banner .banner-button a.btn-sm {
  font-size: 11pt;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
.trial-banner .banner-button a.btn-sm.btn-const {
  width: 80px;
}
.trial-banner .banner-button a.btn-md {
  font-size: 11pt;
  padding: 0px 25px;
  height: 40px;
  line-height: 40px;
}
.trial-banner .banner-button a.btn-md.btn-const {
  width: 80px;
}
.trial-banner .banner-button a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.trial-banner .banner-button a:hover {
  background: #18c0a3;
}
.trial-banner .banner-button a span {
  display: block;
  font-size: 11pt;
  font-weight: lighter;
}
@media (max-width: 767px) {
  .trial-banner .free-things .free-days,
  .trial-banner .free-things .free-points {
    height: 75px;
    padding: 10px 50px 0 15px;
    font-size: 14pt;
    line-height: 20pt;
    border-radius: 0;
    box-shadow: none;
  }
  .trial-banner .free-things .free-days > span,
  .trial-banner .free-things .free-points > span {
    font-size: 19pt;
  }
  .trial-banner .free-things .free-points {
    padding: 10px 15px 0 50px;
  }
  .trial-banner .free-things .zero {
    top: -50px;
    margin-left: -50px;
    font-size: 32px;
    line-height: 67px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  .trial-banner .free-things .zero .zero-content {
    top: 10px;
    left: 10px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  .trial-banner .free-description {
    padding: 0;
  }
  .trial-banner .free-description .free-days,
  .trial-banner .free-description .free-points {
    box-shadow: none;
  }
  .trial-banner .free-description .free-days .description-content,
  .trial-banner .free-description .free-points .description-content {
    padding: 5px 10px;
  }
}
.code {
  margin: 10px auto;
  border: 1px solid #d7d2d2;
  background: #f0f0f0;
  padding: 10px;
}
@media (max-width: 767px) {
  body.mf-opened .page-wrapper {
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    transition: visibility 0.27s;
    max-width: 100%;
  }
  body.mf-opened .footer {
    position: absolute;
    overflow: hidden;
    visibility: hidden;
  }
  .mf-modal-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    background: #fff;
    visibility: visible;
  }
  .mf-modal-fullscreen .mf-header,
  .mf-modal-fullscreen .modal-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding: 0 15px;
  }
  .mf-modal-fullscreen .mf-body,
  .mf-modal-fullscreen .modal-body {
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 50px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .mf-modal-fullscreen .modal-dialog {
    max-width: none;
  }
}
.offer-tags .label {
  background: #85b8cc;
  font-size: 80%;
  display: inline-block;
  padding: .4em .6em;
  margin: 2px 0;
  color: #ffffff;
  font-weight: normal;
}
.offer-map-popup .offer-map-popup-image {
  float: left;
}
.offer-map-popup .offer-map-popup-content {
  margin-left: 90px;
}
.offer-map-popup .offer-map-popup-content h1 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.offer-map-popup .offer-map-popup-content p {
  font-size: 13px;
  margin: 0 0 3px;
}
.offer-map-popup .offer-map-popup-content a {
  font-size: 11px;
}
.expandable-content {
  position: relative;
  margin-bottom: 30px;
  line-height: 18px;
  max-height: 120px;
  overflow: hidden;
}
.expandable-content p {
  margin: 0 0 3px 5px;
}
.expandable-content .transparent-row {
  background-color: rgba(240, 238, 239, 0);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(240, 238, 239, 0)), color-stop(50%, #f0eeef));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(240, 238, 239, 0) 0%, #f0eeef 50%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(240, 238, 239, 0) 0%, #f0eeef 50%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(240, 238, 239, 0) 0%, #f0eeef 50%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(240, 238, 239, 0) 0%, #f0eeef 50%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(240, 238, 239, 0) 0%, #f0eeef 50%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorStr=#000000, endColorStr=#ffffff);
  /* IE6-9 brak obsługi RGBA */
  height: 80px;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.expandable-content .transparent-row a {
  display: block;
  text-align: center;
  width: 100%;
  height: 40px;
  padding-top: 50px;
  font-weight: 400;
  font-size: 14px;
}
.fancybox-lock,
.fancybox-lock body {
  height: auto;
}
.ui-helper-hidden-accessible {
  display: none;
}
.date-picker-wrapper .month-wrapper table .day.real-today {
  background-color: #ffffff !important;
}
.date-picker-wrapper .month-wrapper table .day.checked {
  background-color: #ef366c !important;
}
.date-picker-wrapper .month-wrapper table .day.toMonth.hovering {
  background-color: #f8dbe3 !important;
}
.date-picker-wrapper .month-wrapper table .day.first-date-selected,
.date-picker-wrapper .month-wrapper table .day.last-date-selected {
  background-color: #dd1c55 !important;
}
.date-picker-wrapper table .caption .next,
.date-picker-wrapper table .caption .prev {
  color: #dc124c !important;
}
