table.subscription-table {
    width: 100%;

    .entity-limit-reached {
        margin-top: 5px;
        font-size: 10pt;
    }

    thead {
        tr td { border: 0; }
    }

    tr {

        th {
            border: 1px solid @colorBgGray;

            &.highlight {
                background: @colorPink;
                color: #fff;
            }
        }
        td {
            border: 1px solid @colorBgGray;

            &.highlight {
                background: @colorPinkBgLightest;
            }

            .fa {
                &.fa-check {
                    color: @colorGreen;
                }
                &.fa-times {
                    color: @colorFontRed;
                }
                font-size: 20pt;
            }
        }
    }
    tbody {
        tr {
            th {
                background: @colorBgGray;
            }
        }
    }


    &.lg-subscription {
        th, td {
            padding: 15px;
        }
        thead {
            td {
                font-size: 14pt;
                width: 260px;
            }
            th {
                text-align: center;
                font-size: 17pt;
                small {
                    font-size: 10pt;
                    font-weight: normal;
                    display: block;
                }
            }
        }
        tbody {
            th {
                font-weight: normal;
                position: relative;
                padding-right: 30px;

                &.empty {
                    background: none;
                    border: 0;
                }

                .btn-link {
                    color: inherit;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -20px;
                }

                .popover {
                    width: 270px;
                }
            }
            td {
                padding: 10px;
                text-align: center;
                font-size: 15pt;
            }
        }
    }

    &.xs-subscription {
        thead {
            td {
                padding: 7px;
                font-size: 10pt;
            }
        }
        tbody {
            tr {
                th, td {
                    padding: 7px;
                }
                th {
                    font-size: 10pt;
                    p {
                        margin-bottom: 0;
                        font-weight: normal;
                        font-size: 9pt;
                    }
                }
                td {
                    font-size: 10pt;

                    .button-blue,
                    .button-pink {
                        padding: 0 15px;
                    }
                }
            }
        }
    }

    &.specification {
        margin-top: 20px;
        display: none;

        th {
            font-weight: normal;
            padding: 10px;
        }

        td {
            width: 40px;
            text-align: center;
            .fa {
                font-size: 18pt;
            }
        }
    }
}
