section.reservation {
    .done {
        margin-top: 20px;
        margin-bottom: 50px;
        font-weight: 300;
        .booking-letter {
            text-align: center;
            font-size: 20px;
            color: @colorGreen;
            background: url("/img/letter.png") no-repeat center center;
            height: 120px;
        }
        .booking-icon {
            text-align: center;
            font-size: 35px;
            font-weight: 300;
            color: @colorGreen;
        }
        h3 {
            color: @colorGreen;
            text-align: center;
            font-size: 21px;
        }
        .booking-code {
            text-align: center;
            border: 1px solid darken(@colorBgGray, 5);
            background: @colorBgGray;
            padding: 20px;
            margin: 30px 0;
            font-size: 21px;
            color: @colorGreen;
            span {
                color: @colorPink;
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 14px;
            a {
                color: @colorGreen;
            }
        }

        &.error {
            .booking-icon, h3 {
                color: @colorFontRed;
            }
        }
    }
}