.payment {
    .offer-info {
        max-width: 750px;
        margin: 40px 0;

        .offer-name {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 25px;
        }

        .address {
            span {
                display: block;
                color: @colorFontGray;
            }
        }
    }

    .order-header {
        .form-group {
            margin-left: 0;
            margin-right: 0;
            & > div.item {
                padding-top: 7px;
            }
        }
    }

    &.subscription {
        .container {
            max-width: 1000px;
        }
    }

}

#form-order {
    fieldset {
        margin-bottom: 15px;
        &#fieldset-bill {
            margin-bottom: 0;
        }

        &#fieldset-ship {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .form-group.invoice {
        margin-bottom: 0;
    }

    .no-show {
        display: none;
    }
}
