section.register-navi {
    &.subscription {
        .logged-company {
            max-width: 1000px;
        }
    }
    &.payment {
        .logged-company {
            max-width: 750px;
        }
    }
}

section.register.subscription {
    .container {
        max-width: 1000px;
    }
}
#form-order {
    margin-top: 30px;
    fieldset {
        margin-bottom: 10px;
        &#fieldset-ord_proof_type {
            legend {
                margin-bottom: 10px;
            }
        }

        .form-element,
        .styled-checkbox {
            padding-top: 7px;
        }
    }

}
.payment {
    .payu {
        margin-top: 50px;
        p {
            margin-bottom: 20px;
        }
    }
    .payment-summary {
        & > div {
            margin-bottom: 3px;
        }
    }

    .trial {
        margin-top: 70px;
        font-size: 20px;

        .entity-limit-reached {
            margin-top: 5px;
            font-size: 10pt;
        }
    }

    .reorder {
        margin-top: 30px;
    }

    .choose-payment-title {
        margin-top: 40px;
    }

    .order-payment-methods {
        input[type="radio"]:checked + label {
            background: #ffffff;
            border: 1px solid @colorBorderGray;
        }
        label {
            display: block;
            border: 1px solid @colorBgGray;
            border-radius: 5px;
            padding: 10px;
            padding-left: 45px !important;
            margin: 0;
            color: @colorFontGray;
            font-size: 15px;
            min-height: 78px;
            font-weight: bold;
            &:before{
                bottom: 50%;
                position: absolute;
                left: 30px;
                margin-bottom: -9px;
                border: 1px solid @colorBgGray;
            }
            small {
                color: @colorFontGray;
                display: block;
                margin-right: 80px;
                font-size: 11px;
                font-weight: normal;
            }
            .payment-logo{
                width: 60px;
                height: auto;
                position: absolute;
                right: 30px;
                top: 50%;
                margin-top: -15px;
                img {
                    width: 60px;
                    height: auto;
                }
                &.transfer {
                    text-align: right;
                    margin-top: -10px;
                    i {
                        font-size: 20px;
                    }
                }
            }

        }
    }

    .order-payment-button {
        margin-top: 20px;
    }

    .bank-transfer {
        .info {
            margin-bottom: 20px;
        }
        .action {
            margin-top: 10px;
        }
        .alert {
            margin-top: 20px;
        }
    }
}


