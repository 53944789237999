.ellipsis-vertical {
    position: relative;
    max-height: 160px;
    overflow: hidden;

    &:after {
        height: 40px;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";

        .gradient(rgba(255,255, 255, 0.0), @colorWhite, bottom, top, 50%);
    }
}