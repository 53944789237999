@import "searchForm/common.less";
@import "searchForm/horizontal.less";
@import "searchForm/sidebar.less";
@import "searchForm/search-header.less";

.ui-autocomplete {

}

.location {
    &.after:before {
        position: absolute;
        border: 13px solid transparent;
        border-bottom-color: #bdbdbd;
        left: 25px;
        top: 38px;
        z-index: 120;
        content: ' ';
    }
    &.after:after {
        position: absolute;
        border: 12px solid transparent;
        border-bottom-color: #FFF;
        left: 26px;
        top: 40px;
        z-index: 120;
        content: ' ';
    }
}

.search-location-autocomplete {
    background: #fff;
    border: 1px solid @colorBorderGray;
    max-height: 300px;
    overflow-y: auto;
    z-index: 9999;
    max-width: 480px;
    list-style: none;
    padding: 0;
    li {
        padding: 10px 10px;
        border-bottom: 1px solid #e5e5e5;
        p {
            font-size: 80%;
            color: @colorFontGray;
            line-height: 80%;
            margin: 0;
        }
        &.ui-state-focus {
            color: @colorWhite;
            background: @colorPink;
            p {
                color: @colorWhite;
            }
        }
    }
}