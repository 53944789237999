@import "bootstrap-slider.less";

#search-form.sidebar {

    .location {
        input {
            padding-left: 5px;
        }
    }
    .input-group-addon {
        border-radius: 0;
    }

    fieldset {
        margin-top: 40px;
        &#fieldset-basic {
            margin-top: 0px;
        }
        &:first-child {
            margin-top: 0px;
        }

        legend {
            color: @colorTitleSection;
            font-size: 20px;
            line-height: 1.1;
            font-family: Dosis;
            font-weight: normal;
            border: none;
            margin: 0;
            margin-bottom: 25px;
            text-align: left;
            position: relative;
            -webkit-margin-collapse: separate;

            &:after {
                border-bottom: 1px solid @colorBorderGray;
                display: block;
                width: 100%;
                content: " ";
                height: 1px;
                line-height: 0px;
                position: absolute;
                margin-top: -11px;
            }

            span {
                background: @colorBgGray;
                color: @colorTitleSection;
                padding: 0 20px 0 0;
                position: relative;
                z-index: 1;
            }
            &.left {
                text-align: left;
                span {
                    padding-left: 0;
                }
            }
        }

        &#fieldset-price {
            .validation-error {
                position: relative;
                z-index: 2;
                display: inline-block;
            }
        }

    }

    .inline-row {
        margin: 0 -5px;
        div[class*='col-'] {
            padding: 0 5px;
        }
        label {
            color: @colorFontGrayDark;
            font-weight: 300;
            margin-top: 5px;
        }
        #basic-date_start,
        #basic-date_end {
            font-size: 14px;
            padding: 5px;
            text-align: center;
        }


        .guest {
            .styled-select {
                #basic-guest {
                    font-size: 14px;
                    text-align: center;
                    padding: 5px 5px 5px 28px;
                }
                &:before {
                    right: 7px;

                }
            }
            &:before {
                font-size: 16px;
                margin-top: 36px;
                margin-left: 8px;
            }
        }

    }

    .button-pink {
        margin-top: 20px;
    }

    #fieldset-price {
        label {

        }
    }

    #fieldset-type, #fieldset-amenity {
        label {
            font-weight: 300;
            color: @colorFontGrayDark;
            font-size: 15px;
            padding-left: 25px;
            a {
                color: @colorFontGrayDark;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }

            &:before {
                margin-left: -25px;
                margin-right: 0;
            }
        }
    }

    &.sidebar-long {
        #fieldset-basic {
            display: none;
        }
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    #search-form.sidebar {
        .location {
            &:before {
                content: '';
            }
        }

        #basic-location {
            font-size: 13px;
            height: 30px;
            line-height: 18px;
            padding-left: 5px;
            padding-right: 5px;
            &::-webkit-input-placeholder::before {
                content: '';
            }
            &::-moz-placeholder::before {
                content: '';
            }
            /* firefox 19+ */
            &:-ms-input-placeholder::before {
                content: '';
            }
            /* ie */
            &:-moz-placeholder::before {
                content: '';
            }
        }
        .inline-row {
            #basic-date_start,
            #basic-date_end,
            #basic-guest {
                font-size: 12px;
                height: 30px;
                line-height: 18px;
            }
            select {
                font-size: 12px;
                height: 30px;
                line-height: 18px;
            }
            .guest {
                &:before {
                    font-size: 14px;
                    margin-top: 35px;
                }
            }
        }

        #fieldset-type {
            display: inline-block;
            vertical-align: top;
        }

        #fieldset-amenity {
            display: inline-block;
            vertical-align: top;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .form-booking-search-sidebar {
        margin-bottom: 15px;

        .form-search-info {
            margin-bottom: 15px;
            border: 1px solid @colorBorderGray;
            background: @colorWhite;
            padding: 10px;
            cursor: pointer;
            line-height: 21px;
            font-size: 16px;
            font-weight: 300;

            .location {
                font-weight: 400;
            }

            .guest {
                margin-top: 5px;
            }
        }

        .toggle-form-search {
            color: @colorPink;
        }

        &.form-closed {
            #search-form {
                display: none;
            }

            .toggle-form-search {
                i.fa-chevron-up {
                    display: none;
                }
            }
        }

        &.form-opened {
            .toggle-form-search {
                i.fa-chevron-down {
                    display: none;
                }
            }
        }
    }

    #search-form.sidebar {
        .dates {
            .date:before {
                font-family: e-nocleg-icons;
                content: @nc-icon-calendar;
                float: left;
                font-size: 16px;
                margin-top: 36px;
                margin-left: 8px;
                color: #736c6c;
                position: absolute;
                pointer-events: none;
            }
        }
    }
}