section.landing-page {
    overflow: hidden;
    .landing-hero {
        background: @colorMarineGreen;
        position: relative;

        .road-top {
            position: absolute;
            bottom: 53px;
            width: 100%;
            height: 61px;
            background: #a0a0a0;
            border-top: 9px solid #8de00b;
        }
        .road-bottom {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 53px;
            background: #af753c;
            border-bottom: 32px solid #8de00b;

        }
        .container {
            position: relative;
            height: 570px;
            .title {
                margin-right: 600px;
                margin-top: 100px;
                h1 {
                    font-family: Roboto;
                    color: #ffffff;
                    font-size: 27pt;
                    font-weight: bold;
                    line-height: 55px;
                    span {
                        font-size: 37pt;
                    }
                }
            }
            .banner {
                float: right;
                width: 590px;
                margin-top: 100px;
                position: relative;
                z-index: 2;
                .banner-button {
                    a {
                        .button-pink;
                    }
                }
            }
            .hero {
                position: absolute;
                left: 50%;
                margin-left: -665px;
                bottom: -58px;
                width: 1331px;
                height: 413px;
                background: url('/img/landingpage_car.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 1331px 413px;

            }
        }
    }
}

.why-us {
    padding-bottom: 90px;
    .title {
        font-weight: bold;
    }
    img {
        border-radius: 72px;
    }
    h3 {
        font-size: 25pt;
        font-weight: bold;
        span {
            margin-top: 5px;
            display: block;
            font-size: 17pt;
        }
    }
    .button-box {
        margin-top: 50px;
    }
}

.landing-reviews {
    padding: 30px 0 20px;
    .container {
        background: url('/img/landingpage_hotel@x2.svg') right center no-repeat;
        background-size: 526px 260px;
    }
    .carousel {
        height: 380px;
        .item {
            color: #ffffff;
            .review-header {
                height: 150px;
                .photo {
                    float: left;
                    margin-right: 50px;
                    .oval-photo {
                        width: 150px;
                        height: 150px;
                        border-radius: 75px;
                        overflow: hidden;
                        margin: 0 auto;
                    }
                }

                .signature {
                    display: table;
                    height: 150px;
                    & > p {
                        display: table-cell;
                        vertical-align: middle;
                        font-size: 15pt;
                        line-height: 18pt;
                        & > span {
                            display: block;
                            font-size: 12pt;
                        }
                    }
                }
            }
            .review-cell {
                margin-top: 30px;
                font-size: 11pt;
                font-style: italic;
            }
        }
    }
    .carousel-indicators {
        position: static;
        width: 100%;
        margin: 10px 0;
        li {
            background: @colorBorderGray;
            border: none;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin: 0 3px;
            &.active {
                background: #ffffff;
            }
        }
    }
    .read-more {
        text-align: right;
        font-size: 14pt;
        margin-top: -35px;
        a {
            color: #ffffff;
        }
    }
}

section.viewership {
    padding: 50px 0 70px;
    .title {
        font-weight: bold;
    }
    .viewership-list {
        padding: 0;
        list-style: none;
        font-size: 16pt;
        max-width: 325px;
        margin: 20px auto;
        li {
            margin-bottom: 10px;
            i {
                color: @colorPink;
                margin-right: 20px;
                font-size: 20pt;
            }

        }
    }
}

section.landingpage-panel {
    background: @colorGray;
    border-top: 1px solid @colorGrayDark;
    border-bottom: 1px solid @colorGrayDark;
    padding-bottom: 50px;
    .title {
        font-weight: bold;
    }

    .panel-background {
        background: url('/img/landingpage_macbook@2x.svg') right top no-repeat;
        background-size: 705px 425px;
        padding-top: 60px;
    }
    .item {
        margin-bottom: 75px;
        position: relative;
        .photo {
            float: left;
            margin-right: 20px;
        }
        h4 {
            font-size: 17pt;
            font-weight: bold;
            margin-bottom: 8px;
        }
        p {
            font-size: 12pt;
            font-weight: 100;
        }
        .line {
            width: 100px;
            height: 2px;
            background: @colorBorderGray;
            position: absolute;
            top: 23px;
            left: 400px;
        }
    }
    .info {
        font-family: Dosis;
        font-size: 17pt;
        text-align: center;
        img {
            margin-right: 12px;
        }
    }
}

section.subscription {
    padding-bottom: 70px;

    .btn-specification {
        font-size: 10pt;
    }
    .short-subscription-link {
        font-size: 15pt;
        margin: 30px 0;
    }
    .short-subscription-table {
        display: none;
    }
}

section.free-try {
    padding: 30px 0;
    overflow: hidden;
    .container {
        position: relative;
        .background {
            position: absolute;
            right: -330px;
            bottom: -93px;
            width: 839px;
            height: 652px;
            background: url('/img/landingpage_balloon@2x.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 839px 652px;
        }
    }
    .title {
        font-family: Roboto;
        text-align: left;
        color: #ffffff;
        font-weight: bold;
        font-size: 25pt;
        line-height: 35pt;
        margin: 20px 0 80px;
        span {
            font-size: 30pt;
        }
    }
    .banner {
        float: left;
        position: relative;
        z-index: 2;
    }
    .trial-banner {
        .free-things {
            .free-days,
            .free-points {
                background: @colorMarineGreen;
            }
            .zero {
                background: @colorPink;
                .zero-content {
                    border: 8px solid @colorMarineGreen;
                }
            }
        }
    }
}

@media (max-width: @screen-md-max) {
    section.landing-page {
        .landing-hero {
            .container {
                .title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0;
                    text-align: center;
                }
                .banner {
                    float: none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 200px;
                    margin: 0 auto;
                }
            }
        }
    }

    section.landingpage-panel {
        .item {
            .line {
                display: none;
            }
        }
    }
}

@media (max-width: @screen-sm-max) {

}

@media (max-width: @screen-xs-max) {
    section.landing-page {
        .landing-hero {
            .container {
                .banner {
                    width: 100%;
                    padding: 0 10px;
                }
                .title {
                    width: 100%;
                    padding: 0 10px;
                    margin: 0;
                    h1 {
                        font-size: 21pt;
                        span {
                            font-size: 25pt;
                        }
                    }
                }
            }
        }
    }
    .landing-reviews {
        .container {
            background: none;
        }
    }
    section.landingpage-panel {
        .panel-background {
            background: none;
            padding-top: 0px;
        }
    }
    section.free-try {
        .title {
            text-align: center;
        }
        .container {
            .background {
                display: none;
            }
        }
        .banner {
            float: none;
        }
    }

    section.viewership {
        .viewership-list {
            font-size: 14pt;
            li {
                i {
                    margin-right: 10px;
                    font-size: 15pt;
                }
            }
        }
    }
}