section.register-title {
    padding: 50px 0;
    h1 {
        text-align: center;
        font-weight: 300;
    }
}

section.register-navi {
    padding: 2px 0;

    &.photos {
        .logged-company {
            max-width: 1000px;
        }
    }
    &.entity-section {
        .logged-company {
            max-width: 700px;
        }
    }

    ul.navi-items {
        list-style: none;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        li {
            display: table-cell;
            padding: 0 10px;
            text-align: center;
            position: relative;
            background: @colorBgGreen;
            max-width: 150px;
            div, a {
                display: block;
                color: @colorWhite;
                font-size: 12px;
                text-decoration: none;
                white-space: nowrap;
                span.step {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 28px;
                    border: 1px solid @colorWhite;
                    border-radius: 15px;
                    margin: 16px auto 16px 10px;
                    i {
                        color: @colorWhite;
                    }
                }
                span.title {
                    margin-left: 10px;
                    vertical-align: middle;
                    font-weight: 300;
                    display: inline-block;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.active {
                background: @colorWhite;
                div, a {
                    span.step {
                        border-color: @colorPink;
                        color: @colorPink;
                        i {
                            color: @colorPink;
                        }
                    }
                    span.title {
                        color: @colorPink;
                    }
                }
                &:after {
                    content: "\0020";
                    display: block;
                    border-left: 13px solid @colorWhite;
                    border-bottom: 13px solid transparent;
                    border-top: 13px solid transparent;
                    position: absolute;
                    top: 29%;
                    z-index: 3;
                    transform:scale(1,2.5);
                    -ms-transform:scale(1,2.5);
                    -webkit-transform:scale(1,2.5);
                }
                & ~ li {
                    color: @colorFontGray;
                    background: @colorBgGray;
                    div, a {
                        color: @colorFontGray;
                        span.step {
                            border: 1px solid @colorFontGray;
                            i {
                            }
                            i:before {
                                color: @colorGreen;
                            }
                        }
                    }
                    &:after {
                        content: "\0020";
                        display: block;
                        border-left: 13px solid @colorWhite;
                        border-bottom: 13px solid transparent;
                        border-top: 13px solid transparent;
                        position: absolute;
                        top: 29%;
                        right: -12px;
                        z-index: 2;
                        transform:scale(1,2.5);
                        -ms-transform:scale(1,2.5);
                        -webkit-transform:scale(1,2.5);
                    }
                    &:before {
                        content: "\0020";
                        display: block;
                        border-left: 13px solid @colorBgGray;
                        border-bottom: 13px solid transparent;
                        border-top: 13px solid transparent;
                        position: absolute;
                        top: 29%;
                        right: -10px;
                        z-index: 3;
                        transform:scale(1,2.5);
                        -ms-transform:scale(1,2.5);
                        -webkit-transform:scale(1,2.5);
                    }

                    &:last-child {
                        &:after, &:before {
                            display: none;
                        }
                    }
                }
            }
            &:after {
                content: "\0020";
                display: block;
                border-left: 13px solid @colorBgGreen;
                border-bottom: 13px solid transparent;
                border-top: 13px solid transparent;
                position: absolute;
                top: 29%;
                right: -12px;
                z-index: 2;
                transform:scale(1,2.5);
                -ms-transform:scale(1,2.5);
                -webkit-transform:scale(1,2.5);
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .logged-company {
        margin: 10px auto -45px auto;
        font-size: 13px;
        max-width: 650px;
        .alert {
            border: 0;
            border-radius: 0;
            small {
                display: block;
            }
        }
    }
}

@media (max-width: 1400px) {
    section.register-navi {
        ul.navi-items {
            li {
                div, a {
                    span.title {
                        max-width: 150px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    section.register-navi {
        ul.navi-items {
            li {
                div, a {
                    span.title {
                        max-width: 100px;
                    }
                }
            }
        }
    }
}

section.register.register-block {
    margin: 0px;
    .form-register-block {
        padding-bottom: 70px;
    }
}



section.register {
    margin: 70px 0;
    font-size: 16px;
    font-weight: 300;

    .container {
        max-width: 650px;
    }
    &.entity-section {
        .container {
            max-width: 700px;
        }
    }
    &.register-company {
        .container {
            max-width: 850px;
        }
    }
    &.photos {
        .container {
            max-width: 1000px;
            .main-star {
                color: @colorPink;
            }

            .moxie-shim {
                display: none;
            }
        }
    }


    strong {
        font-weight: 500;
    }

    h1.page-title {
        font-size: 25px;
        margin: 0 0 40px 0;
        padding: 0;
    }

    .account-block {
        text-align: center;
        padding: 30px;
        h1 {
            margin: 0;
        }
    }
    .form-register-block {
        border-right: 1px solid @colorBorderGrayLight;
        #form-new-company {
            margin-top: 20px;
            legend:after {
                display: none;
            }
        }
        & > div {
            margin-right: 40px;
        }
    }
    .register-request {
        border-top: 1px solid @colorBorderGrayLight;
        padding-top: 20px;
        margin-bottom: 20px;
    }

    form {
        fieldset {
            margin: 40px 0;
            &:first-child {
                margin-top: 0px;
            }
            &:last-child {
                margin-bottom: 0px;
            }

            legend {
                color: @colorTitleSection;
                font-size: 25px;
                line-height: 1.1;
                font-family: Dosis;
                font-weight: normal;
                border: none;
                margin: 0;
                margin-bottom: 30px;
                text-align: left;
                position: relative;
                -webkit-margin-collapse: separate;

                &:after {
                    border-bottom: 1px solid @colorBorderGray;
                    display: block;
                    width: 100%;
                    content: " ";
                    height: 1px;
                    line-height: 0px;
                    position: absolute;
                    margin-top: -13px;
                }

                span {
                    background: #fff;
                    color: @colorTitleSection;
                    padding: 0 20px 0 0;
                    position: relative;
                    z-index: 1;
                }
                &.left {
                    text-align: left;
                    span {
                        padding-left: 0;
                    }
                }
            }

            .form-element {
                margin-bottom: 20px;
            }
        }

        input[type="text"],
        input[type="password"] {
            height: 50px;
        }
        .styled-select {
            .select2-container,
            select {
                height: 50px;
                a {
                    height: 38px;
                    line-height: 38px;
                }
            }
            &:before {
                top: 17px;
            }
        }

        div.control-label {
            line-height: 50px;
        }

        .styled-radiobox,
        .styled-checkbox {
            &.inline {
                line-height: 50px;
            }
        }

        .form-submit {
            .btn-const {
                margin: 0;
            }
        }
    }

    .panel {
        .thumbnail {
            .thumb-container {
                line-height: 0;
            }
            canvas {
                width: 100%;
            }
            .description {
                width: 100%;
                position: relative;
                .gradient-gray {
                    position: absolute;
                    width: 100%;
                    height: 45px;
                    bottom: 0px;
                    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 75%);

                    .main-photo {
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        color: #fff;
                        .fa-star {
                            color: @colorPink;
                        }
                    }
                    .delete {
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        color: #fff;
                        &:hover {
                            color: darken(#fff, 5%);
                        }
                    }
                }
            }
        }

        h3 {
            margin-top: 10px;
            font-size: 27px;
            line-height: 35px;
            p {
                margin-bottom: 0;
                font-size: 14px;

            }
        }
        h4 {
            margin-top: 5px;
            font-size: 22px;
            line-height: 31px;
        }
        .summary-link-edit {
            font-size: 12px;
            margin-left: 5px;
            white-space: nowrap;
        }

        #summary-gmap {
            height: 200px;
        }

        .thumbnail-list {
            margin-top: 10px;
            margin-left: -5px;
            margin-right: -5px;
            overflow: hidden;
            img {
                float: left;
                margin: 0px 5px;
                margin-bottom: 10px;
            }
        }

        .entity-price {
            h3 {
                p {
                    font-size: 18px;
                    text-align: right;
                }
            }
        }
    }

    .login-into-panel {
        text-align: center;
        h2 {
            margin-top: 100px;
            margin-bottom: 20px;
            font-size: 25px;
        }
        & > div {
            margin-left: 40px;
        }
    }

    .loading {
        height: 100%;
        .loading-box {
            padding-bottom: 75%;
            margin-bottom: 3px;
            .box-middle {
                position: absolute;
                top: 50%;
                left: 50%;
                .centered {
                    position: absolute;
                    top: -25px;
                    left: -25px;
                }
            }
        }
    }

    .error-message {
        text-align: center;
    }
}

@media (max-width: @screen-xs-max) {
    section.register {
        .form-register-block {
            border-right: none;
            #form-new-company {
                padding-top: 20px;
                padding-bottom: 40px;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;

                .form-submit {
                    text-align: center;
                }
            }
            & > div {
                margin-right: 0;
            }
        }
        .login-into-panel {
            margin-top: -130px;
            & > div {
                margin-left: 0;
            }
        }
        .register-request {
            margin-top: 30px;
            padding-top: 30px;
            margin-bottom: 30px;
        }

    }
}

#form-offer-basic {
    #basic-location-gmap {
        height: 350px;
    }

    #fieldset-contact {
        .form-element.phone {
            position: relative;
            .button-close {
                position: absolute;
                background: none;
                right: 10px;
                top: 10px;
                width: 30px;
                height: 30px;
            }
        }
    }

    .map-warning {
        color: @colorRedError;
        margin-top: -5px;
        margin-bottom: 5px;
    }
    #fieldset-price {
        .description {
            margin-top: -15px;
            font-size: 13px;
            color: @colorFontGrayLight;
        }
    }
}

#form-offer-details {
    .form-submit {
        margin-top: 30px;
    }
}

#form-offer-entity {
    .label-inline {
        position: absolute;
        line-height: 50px;
    }
    .input-label-inline {
        width: 100%;
        padding-left: 35px;
        padding-right: 23px;
    }
    .control-label {
        .button-close {
            margin-left: 10px;
        }
    }

    .short-input {
        max-width: 150px;
    }

    .bed-row {
        button {
            color: @colorRedError;
            background: none;
            position: absolute;
            right: 10px;
            height: 50px;
        }
        &:not(.bed-row-delete) {
            button {
                display: none;
            }
        }
    }

}

#modal-register-login {
    .alert {
        font-size: 13px;
        button.close {
            display: none;
        }
    }
    form {
        max-width: 400px;
        margin: 0 auto 20px auto;
        label.error {
            text-align: left;
        }
    }

}
#modal-register-request {
    .modal-content {
        .modal-body {
            text-align: left;
            .form-submit {
                text-align: center;
            }
        }
    }
}

.form-register-photos {
    .actions {
        .btn-const {
            margin: 0;
        }
    }
}

@media (max-width: @screen-xs-max) {
    section.register-navi {
        ul.navi-items {
            li {
                width: 32%;
                margin-bottom: 10px;
            }
        }
    }

    #form-offer-entity {
        .label-inline {
            margin-left: 10px;
        }
        .input-label-inline {

        }
        .form-submit {
            .btn-const {
                width: 180px;
            }
        }
        .short-input {
            max-width: none;
        }
        .form-submit {
            .btn-const {
                padding: 0 20px;
                width: 140px;
            }
        }
    }

    section.register {
        .panel {
            .entity-title {
                h3 {
                    margin: 0px;
                }
            }
            .entity-price {
                h3 {
                    margin: 0px;
                    p {
                        text-align: left;
                    }
                }
            }
            #summary-gmap {
                margin-top: 10px;
            }
        }
    }

    #form-offer-basic {
        .form-submit {
            .btn-const {
                padding: 0 20px;
                width: 140px;
            }
        }
    }

    .form-register-photos {
        .actions {
            .btn-const {
                padding: 0 20px;
                width: 140px;
            }
        }
    }
}