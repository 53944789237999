#inquiry-form-container {
    form#inquiry-form {
        .row {
            margin-left: -5px;
            margin-right: 5px;
            div[class*=col-] {
                margin-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;
            }

            div[class*=col-sm-6],
            div[class*=col-sm-4] {
                width: 100%;
            }
            div[class*=col-sm-3] {
                width: 50%;

            }
        }
    }
}

form#inquiry-form-short,
form#inquiry-form {
    .date-icon,
    .name-icon,
    .email-icon,
    .phone-icon {
        input {
            padding-left: 35px;
        }
        &::before {
            font-family: e-nocleg-icons;
            float: left;
            font-size: 18px;
            color: @colorBorderGray;
            display: block;
            position: absolute;
            margin-top: 8px;
            margin-left: 8px;
            z-index: 2;
        }
    }
    .date-icon {
        &:before {
            content: @nc-icon-calendar;
        }
    }
    .name-icon {
        &:before {
            content: @nc-icon-guest-o;
        }
    }
    .email-icon {
        &:before {
            content: @nc-icon-envelope;
        }
    }
    .phone-icon {
        &:before {
            content: @nc-icon-phone;
        }
    }

    .placeholder {
        height: 40px;
        line-height: 30px;
    }
    input[type="text"] {
        height: 40px;
    }
    .input-group-btn {
        .btn {
            height: 40px;
        }
    }
    .form-element {
        label.control-label {
            float: left;
            font-size: 16px;
            line-height: 40px;
            margin: 0 3px 0 0;
            font-weight: 300;
        }
        label.error {
            clear: both;
        }

        .number-control {
            float: left;
            width: 108px;
            input {
                text-align: center;
            }

            .btn {
                width: 30px;
                padding: 0;
                margin-right: 0;
                margin-left: 0;
            }
            .input-group-btn {
                &:first-child {
                    .btn {
                        border-right: 0;
                    }
                }
                &:last-child {
                    .btn {
                        border-left: 0;
                    }
                }
            }
        }
    }
}

form#inquiry-form-short {
    background: #ffffff;
    padding: 10px;
    margin-bottom: 40px;
    div[class*=col-] {
        margin-bottom: 10px;
    }
    .placeholder {
        height: 50px;
        line-height: 40px;
    }
    input[type="text"] {
        height: 50px;
    }
    .date-icon,
    .name-icon,
    .email-icon,
    .phone-icon {
        &:before {
            margin-top: 13px;
        }
    }
}

.phone-modal,
.inquiry-modal {
    .modal-dialog {
        width: auto;
        max-width: 730px;
    }
    .modal-content {
        .modal-header {
            .modal-title {
                font-size: 18px;
                line-height: 25.88px;
            }
        }

        .modal-body {
            padding: 25px;
            text-align: left;
            .offer-name {
                margin-top: 10px;
                margin-bottom: 5px;
                font-size: 25px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .address {
                span {
                    color: @colorFontGray;
                    display: block;
                }
            }
            .show-phone {
                height: 40px;
                line-height: 40px;
                padding: 0px 25px;
                font-size: 15px;
                i {
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                    margin-left: -5px;
                    color: @colorBlue;
                    font-size: 18px;
                }
            }

            form#inquiry-form {
                margin-top: 10px;
                .row {
                    margin-left: -5px;
                    margin-right: 5px;
                    .form-element {
                        textarea {
                            height: 130px;
                        }
                    }
                    div[class*=col-] {
                        margin-bottom: 10px;
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                }
            }
        }

    }
}

.phone-modal {
    .modal-dialog {
        width: auto;
        max-width: 530px;
    }
    .modal-content {

        .modal-body {
            h1 {
                margin-top: 0;
            }

            .phone-section {
                @titleWidth: 170px;
                .title {
                    float: left;
                    margin-top: 13px;
                    width: @titleWidth;
                    font-size: 24px;
                    line-height: 1.3;
                    color: @colorFontGrayDark;
                    font-family: Dosis;
                }

                .phone-list {
                    margin: 10px 0;
                    margin-left: @titleWidth;
                    a {
                        margin-bottom: 5px;
                        margin-right: 5px;
                    }
                }
            }
            .disclaimer {
                margin: 0;
                margin-top: 10px;

                .fa {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    form#inquiry-form-short,
    form#inquiry-form {
        .date-icon {
            .placeholder {
                padding-left: 35px;
            }
        }
    }

    .phone-modal,
    .inquiry-modal {
        .modal-dialog {
            width: auto;
        }
    }
    .phone-modal {
        .modal-content {
            .modal-body {
                h1 {
                    margin-top: 10px;
                }
                .phone-section {
                    .phone-list {
                        margin: 0;
                    }
                }
            }
        }
    }
}