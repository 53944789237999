.entity-details-content {
    .row {
        margin-left: 0;
        margin-right: 0;
        & > div[class*="col"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .entity-title {
        margin: 0;
        background: #F0EEEF;
        padding: 15px 30px 15px 15px;
        font-size: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .entity-details {
        padding: 5px 20px;
        & > p {
            margin-bottom: 15px;
            font-weight: 300;
            .paragraph-title {
                font-weight: 500;
            }

            & > i {
                font-size: 28px;
                padding: 5px;
                vertical-align: middle;
                color: #918787;
            }
        }
        .entity-amenities {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                white-space: nowrap;
                line-height: 20px;
                line-height: 20px;
                margin: 5px 10px 5px 0px;
                .nc {
                    margin-right: 2px;
                    font-size: 20px;
                    vertical-align: text-bottom;
                    color: @colorBlue;
                }
            }
        }
    }
}

@media (max-width: @screen-xs-max) {

    .modal.iframe.modal-entity-details {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    top: 0;
                }

                button.close {
                    position: absolute;
                    opacity: 1;
                    color: @colorWhite;
                }
            }
        }
    }
    .iframe-content {
        .entity-details-content {
            margin-top: 50px;
            .entity-title {
                position: fixed;
                top: 0;
                width: 100%;
            }
        }
    }
}