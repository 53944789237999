.offer-availability-tab {
    background: #fff;
    padding-bottom: 15px;

    .legend-container {
        padding: 30px 0 30px 55px;
        font-weight: 300;

        span {
            padding-left: 15px;
        }
    }

    .legend {
        width: 30px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;

        &.legend-white {
            background: #fff;
            border: 1px solid @colorBorderGray;
        }

        &.legend-orange {
            background: @colorPink;
            border: 1px solid @colorPink;
        }
    }

    .calendar-container {
        text-align: center;
    }

    .calendar-month {
        display: inline-block;
        vertical-align: top;
        border: 1px solid @colorBorderGrayLightest;
        border-collapse: collapse;
        overflow: hidden;
        margin: 5px;

        th {
            text-transform: capitalize;
            line-height: 24px;
            font-weight: 400;
            font-size: 12px;
            padding: 0 5px;
            text-align: center;

            &.sunday {
                color: @colorFontRed;
            }
        }

        thead {
            tr:last-child {
                color: @colorBlue;
            }
        }

        .title {
            text-align: center;
            text-transform: capitalize;
            font-size: 10pt;
            line-height: 36px;
            background: transparent;
            color: @colorBgGraySpecial;
        }

        td {
            min-width: 30px;
            min-height: 30px;
            line-height: 24px;
            margin: 1px;
            padding: 2px 5px;
            text-align: center;
            border: 1px solid #fff;
            font-size: 12px;

            &.past {
                color: @colorFontGray;
            }

            &.saturday {
                background: @colorBgGrayLighter;
            }

            &.sunday {
                color: @colorFontRed;
                background: @colorBgGrayLighter;
            }

            &.selected {
                background: @colorPink;
                color: #fff;
            }
        }
    }
}