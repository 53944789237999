.offer-reviews {
    background: #fff;
    padding: 20px;
}

.review-form {
    position: relative;
}
#form-review {
    display: none;
    margin-bottom: 30px;
    border-bottom: 1px solid @colorBorderGray;
    padding-bottom: 30px;
    fieldset {
        & > div.row {
            & > div {
                margin-bottom: 5px;
            }
        }
    }

    .rating-star {
        color: @colorPink;
        select {
            display: none;
        }
    }

    .actions {
        text-align: right;
        margin-top: 20px;
        .btn-const {
            margin: 0;
        }
    }

    .name,
    .location {
        line-height: 34px;
        label {
            margin: 0;
        }
    }
}