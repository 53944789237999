@mf-modal-transition-time: 0.27s;

@media (max-width: @screen-xs-max) {
    body {
        &.mf-opened {

            .page-wrapper {
                position: absolute;
                overflow: hidden;
                visibility: hidden;
                transition: visibility @mf-modal-transition-time;
                max-width: 100%;
            }

            .footer {
                position: absolute;
                overflow: hidden;
                visibility: hidden;
            }
        }
    }

    .mf-modal-fullscreen {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10000;
        background: #fff;
        visibility: visible;

        .mf-header,
        .modal-header {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            padding: 0 15px;
        }

        .mf-body,
        .modal-body {
            width: 100%;
            height: calc(~'100% - 50px');
            margin-top: 50px;
            overflow: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        .modal-dialog {
            max-width: none;
        }
    }
}