.payment {
    &.order {
        .container {
            max-width: 750px;
        }

        margin-bottom: 40px;

        .order-summary-table {
            tbody {
                tr:last-of-type {
                    border-bottom: 2px solid #ddd;
                }
            }
        }

        .order-payment-summary {
            dl {
                dt {
                    float: left;
                    width: 150px;
                    text-align: left;
                    font-weight: normal;
                }
                dd {
                    margin-left: 150px;
                    text-align: right;
                    font-weight: bold;
                }
            }
        }

        .order-payment-table {
            margin-bottom: 15px;
        }
        .bonus {
            white-space: nowrap;
            color: @colorGreen;
        }

        .help {
            &.promo {
                background: @colorBgGray;
                padding: 1em;
            }
            .fa {
                font-size: 2em;
                float: left;
                margin-top: 0em;
                margin-bottom: 1em;
                margin-left: 0.2em;
                margin-right: 0.5em;
                padding: 0.2em;
                color: @colorBlue;
            }
        }

        #form-payment-charge {
            .has-error {
                .control-label {
                    color: @colorFontRed;
                }
            }
            .input-group {
                margin-right: 10px;
            }
        }
    }

    .alert.message {
        margin: 40px 0;
    }
}

