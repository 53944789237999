#search-form.horizontal, #search-availability-form, #search-form.sidebar {
    .location {
        input {
            padding-right: 30px;
        }
        &:before {
            font-family: e-nocleg-icons;
            content: @nc-icon-marker;
            float: left;
            font-size: 18px;
            top: 12px;
            right: 10px;
            color: @colorMarineGreen;
            position: absolute;
            pointer-events: none;
        }
    }

    .styled-select::before {
        color: @colorMarineGreen;
    }
}

#search-form.sidebar {
    .location {
        &:before {
            top: 6px;
        }
    }
}

#search-form.horizontal, #search-availability-form {
    .date {
        input {
            padding-right: 30px;
        }
        &:before {
            font-family: e-nocleg-icons;
            content: @nc-icon-calendar;
            float: right;
            font-size: 18px;
            color: @colorMarineGreen;
            top: 12px;
            right: 10px;
            position: absolute;
            z-index: 2;
            pointer-events: none;
        }
    }
}

.modal.modal-autocomplete {
    .modal-content {
        .modal-body {
            padding: 0px;
            input {
                height: 50px;
            }
        }
    }
}