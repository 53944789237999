section.pricing {
    background-color: @colorPink;
    padding-bottom: 50px;
    h1 {
        color: #ffffff;
    }
    .trial-banner {
        margin-top: 100px;
        .free-things {
            .free-days,
            .free-points {
                background: @colorMarineGreen;
            }
            .zero {
                background: @colorPink;
                .zero-content {
                    border: 8px solid @colorMarineGreen;
                }
            }
        }
    }
}