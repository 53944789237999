.modal {
    .modal-content {
        box-shadow: none;
        border: none;
        border-radius: 0px;
        background-clip: border-box;
        color: #626262;
        .modal-header {
            background: @colorBgGray;
            border-bottom: none;
            padding: 15px 25px 10px;
            .close {
                i {
                    font-size: 14px;
                }
            }
            h4.modal-title {
                margin: 0;
                font-weight: 300;
                color: #2c2c2c;
                font-size: 18px;
                line-height: 25.88px;
            }
        }
        .modal-body {
            padding: 25px;
            box-shadow: none;
            text-align: center;

            h2.message-title {
                margin-bottom: 0px;
            }
        }
        .modal-footer {
            border-top: none;
            box-shadow: none;
            margin-top: 0;
            padding: 25px;
            padding-top: 0;
            text-align: center;

            .button-modal-action {
                .button-white;
                .btn-const;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
            .button-modal-cancel {
                .button-white;
                .btn-const;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }

    &.modal-success {
        .modal-header {
            background: @colorMarineGreen;
            h4.modal-title {
                color: #FFFFFF;
            }
        }
        .modal-body {
            padding: 50px 25px;
            h2 {
                color: @colorMarineGreen;
            }
        }
    }
    &.modal-warning {
        .modal-header {
            background: @colorPink;
            h4.modal-title {
                color: #FFFFFF;
            }
        }
        .modal-body {
            padding: 50px 25px;
            h2 {
                color: @colorPink;
            }
        }
    }
    &.modal-error {
        .modal-header {
            background: @colorRedError;
            h4.modal-title {
                color: #FFFFFF;
            }
        }
        .modal-body {
            padding: 50px 25px;
            h2 {
                color: @colorRedError;
            }
        }
    }
    &.modal-confirm {
        .modal-body {
            padding: 50px 25px;
        }
        .modal-footer {
            .button-modal-action {
                .button-pink;
                margin-right: 15px;
            }
        }
    }
}

.modal-backdrop {
    position: fixed;
    bottom: 0;
}

@media (max-width: @screen-xs-max) {

        .modal {
            top: 0;
            bottom: 0;
            overflow: hidden;
            .modal-dialog {
                margin: 0;
                height: 100%;
                .modal-content-wrapper {
                    width: 100%;
                    height: 100%;
                }
                .modal-content {
                    width: 100%;
                    height: 100%;
                    .modal-header {
                        position: fixed;
                        top: 0;
                        width: 100%;
                        z-index: 2;
                        height: 50px;
                    }
                    .modal-body {
                        overflow-y: auto;
                        width: 100%;
                        top: 0;
                        bottom: 0;
                        position: absolute;
                    }

                    .loading-overlay {
                        top: 0;
                        bottom: 0;
                        position: fixed;
                    }

                    .modal-footer {
                        bottom: 0;
                        position: fixed;
                        width: 100%;
                    }
                }
            }

            &.datepicker-modal {
                input.datepicker {
                    display: none;
                }
                .date-picker-wrapper.inline-wrapper {
                    position: static;
                    border: none;
                    display: block !important;
                    .month-wrapper {
                        width: 100% !important;
                        table {
                            float: none;
                            margin: 0 auto;
                            width: 100%;
                            max-width: 300px;
                        }
                        .gap {
                            display: none;
                        }
                    }
                }
            }

            &.autocomplete-modal {
                .modal-body {
                    padding: 0;
                    input.autocomplete {
                        width: 100%;
                        padding: 0 20px;
                        height: 50px;
                    }
                    .search-location-autocomplete.on-modal {
                        position: absolute !important;
                        top: 50px !important;
                        bottom: 0px !important;
                        max-height: none;
                        max-width: none;
                        border-top: 0;
                        border-bottom: 0;
                        margin: 0;
                        text-align: left;

                    }
                }

            }
        }

}