.payment.balance {

    #form-payment-charge {
        .form-element {
            width: 100px;
            display: inline-block;
        }

        label {
            display: inline-block;
            vertical-align: top;
            margin: 8px 10px 8px 0;
        }

        .help {
            display: block;
            margin-top: 5px;
            margin-bottom: 15px;
        }

        .actions {
            input {
                width: 100%;
            }
        }
    }
}