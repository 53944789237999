section.register-navi {
    &.register-summary {
        .logged-company {
            max-width: 750px;
        }
    }
}
section.register.register-summary {
    .container {
        max-width: 750px;
    }
    .main-picture {
        img {
            min-height: 200px;
        }
    }

    .pictures {
        img {
            min-height: 100px;
        }
    }

    .edits {
        text-align: center;

        .button-white {
            margin-bottom: 5px;
        }
    }

    .actions {
        margin-top: 20px;
        .btn-const {
            margin-top: 0;
        }
    }
}