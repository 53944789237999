.search-results-header {
    h2.section-title {
        font-size: 20px;
        float: left;
        margin: 17px 20px 0 0;
        &:after {
            border: none;
        }
    }
}

.form-booking-search-header {
    background: @colorWhite;
    padding: 15px;
    border-bottom: 5px solid @colorPink;
    margin-bottom: 20px;

    .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
    }
    #search-form {
        input[type="text"],
        select {
            border: 1px solid @colorPinkLight;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .form-booking-search-header {
        margin: 0 -15px;
        border-bottom: 3px solid @colorPink;

        .form-search-info {
            margin-bottom: 15px;
            background: @colorWhite;
            cursor: pointer;
            line-height: 21px;
            font-size: 16px;
            font-weight: 300;

            & > div {
                margin-bottom: 5px;
                & > i {
                    margin-right: 5px;
                }
            }
            .location {
                font-weight: 400;
            }

            .guest {
                a {
                    color: @colorPink;
                }
            }
        }

        &.form-closed {
            #search-form {
                display: none;
            }
        }

        &.form-opened {
            #search-form {
                display: block;
            }
        }
    }
}