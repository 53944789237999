.partner-info {

    .banner-select {
        margin-bottom: 20px;
        .styled-select {
            margin-bottom: 10px;
            max-width: 400px;
        }
    }

    .code {
        transition:background-color 0.1s, color 0.1s;
        &.highlight {
            background-color: @colorWarning;
        }
    }
}