div.reviews {
    .related-offer{
        img {
            margin-bottom: 5px;
        }
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            margin-bottom: 5px;
        }
        .type {
            font-size: 12px;
            span {
                color: @colorFontGray;
            }
        }
    }

    .comment {
        font-weight: 300;
    }
    .reply-comment {
        position: relative;
        background: @colorBgGray;
        padding: 15px;
        margin-top: 20px;
        font-weight: 300;
        &:before {
            content: " ";
            position: absolute;
            top: -10px;
            left: 20px;
            width: 0;
            height: 0;
            border-bottom: 10px solid @colorBgGray;
            border-right: 10px solid transparent;
        }
    }
    .author {
        font-weight: 500;
        margin-bottom: 0;
        line-height: 50px;
        span.date,
        span.author-location {
            font-weight: 300;
            color: @colorFontGray;
        }
        .review-author-photo {
            display: block;
            border-radius: 25px;
            float: left;
            overflow: hidden ;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .average {
        font-size: 80px;
        font-weight: 300;
        float: left;
        margin-right: 20px;
        line-height: 125px;
    }
    .rating {
        .rating-details {
            .rate {
                color: @colorFontGray;
                font-weight: 400;
                .star-rating {
                    font-size: 12px;
                    margin-right: 2px;
                    line-height: 1.1em;
                }
            }
        }
    }
    .review-separation-line {
        border-bottom: 1px solid @colorBorderGray;
        margin: 20px 0;
    }

    .alert {
        margin: 0;
    }
}

@media (max-width: @screen-xs-max) {
    div.reviews {
        .rating {
            margin-top: 20px;
        }
    }
}

@media (max-width: @screen-phone-vertical-max) {
    div.reviews {

        .rating {
            text-align: center;
        }

        .average {
            float: none;
            display: inline-block;
            margin-right: 5px;
            font-size: 60px;
            vertical-align: top;
            line-height: 100px;
        }

        .rating-details {
            display: inline-block;
            text-align: left;
        }
    }
}