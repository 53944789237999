@import "variable/color.less";
@import "variable/screen-resolution.less";

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Pru33qjShpZSmG3z6VYwnbO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/Xu_FYwbs8k0teWf0uC8JpevvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}

@font-face {
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 400;
    src: url('/build/fonts/bariol/Bariol_Regular.eot?#iefix');
    src: local('Bariol'),
    local('Bariol-Regular'),
    url('/build/fonts/bariol/Bariol_Regular.eot?#iefix') format('embedded-opentype'),
    url('/build/fonts/bariol/Bariol_Regular.otf') format('otf'),
    url('/build/fonts/bariol/Bariol_Regular.woff') format('woff'),
    url('/build/fonts/bariol/Bariol_Regular.ttf') format('truetype'),
    url('/build/fonts/bariol/Bariol_Regular.svg#Bariol-Regular') format('svg');
}

@font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    src: url('/build/fonts/Dosis/Dosis-Regular.eot?#iefix');
    src: local('Dosis'),
    local('Dosis-Regular'),
    url('/build/fonts/Dosis/Dosis-Regular.ttf') format('truetype')
}