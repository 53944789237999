.trial-banner {
    max-width: 590px;
    margin: 0 auto;

    .free-things {
        width: 100%;
        height: 50px;
        position: relative;
        font-size: 21pt;
        line-height: 50px;
        color: #fff;



        .free-days,
        .free-points {
            background: @colorPink;
            width: 50%;
            height: 50px;
            padding: 0 35px;
            font-size: 14pt;
            & > span {
                font-size: 21pt;
                font-weight: bold;
            }
        }

        .free-days {
            border-radius: 25px 0 0 25px;
            box-shadow: -7px -7px 0px 0px rgba(0,0,0,0.30);
            float: left;
        }
        .free-points {
            border-radius: 0 25px 25px 0;
            text-align: right;
            float: right;
        }

        .zero {
            position: absolute;
            top: -60px;
            left: 50%;
            margin-left: -60px;
            font-size: 35px;
            line-height: 84px;
            width: 120px;
            height: 120px;
            border-radius: 60px;
            background: @colorMarineGreen;

            .zero-content {
                position: relative;
                top: 10px;
                left: 10px;
                border: 8px solid @colorPink;
                background: #fff;
                color: @colorPink;
                width: 100px;
                height: 100px;
                border-radius: 50px;
                text-align: center;
                box-shadow: -7px -7px 0px 0px rgba(0,0,0,0.30);
            }
        }
    }
    .free-description {
        width: 100%;
        padding: 0 25px;
        .free-days,
        .free-points {
            width: 50%;
            height: 130px;
            line-height: 25px;
            float: left;
            font-family: Roboto;
            font-size: 13pt;
            color: @colorFontSpecialGray;
            .description-content {
                background: #fff;
                padding: 25px 18px;
                height: 130px;
            }
        }
        .free-days {
            padding-right: 2px;
            box-shadow: -7px -7px 0px 0px rgba(0,0,0,0.30);
        }
        .free-points {
            text-align: right;
            padding-left: 2px;
        }
    }

    .banner-button {
        text-align: center;
        margin-top: 2em;
        a {
            .button-marine-green;
            height: auto !important;
            font-size: 18pt !important;
            line-height: 20pt !important;
            padding: 12px 65px !important;
            span {
                display: block;
                font-size: 11pt;
                font-weight: lighter;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .trial-banner {
        .free-things {
            .free-days,
            .free-points {
                height: 75px;
                padding: 10px 50px 0 15px;
                font-size: 14pt;
                line-height: 20pt;
                & > span {
                    font-size: 19pt;
                }
                border-radius: 0;
                box-shadow: none;
            }
            .free-points {
                padding: 10px 15px 0 50px;
            }

            .zero {
                top: -50px;
                margin-left: -50px;
                font-size: 32px;
                line-height: 67px;
                width: 100px;
                height: 100px;
                border-radius: 50px;

                .zero-content {
                    top: 10px;
                    left: 10px;
                    width: 80px;
                    height: 80px;
                    border-radius: 40px;
                }
            }
        }
        .free-description {
            padding: 0;
            .free-days,
            .free-points {
                box-shadow: none;
                .description-content {
                    padding: 5px 10px;
                }
            }
        }
    }
}
