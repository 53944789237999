.dictionary-locations, .dictionary-types {
    .list-title {
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .simple-list {
        list-style: none;
        padding-left: 0;

        a {
            font-family: Roboto;
            text-decoration: none;
            color: @colorFontGray;
            font-weight: 300;

            &:hover {
                color: @colorFontGrayDark;
                text-decoration: underline;
            }
        }
    }
}