@formFontWeight: 300;

.select2-drop {
    &.select2-drop-active {
        outline: 0px solid @colorPink !important;
        border: 1px solid @colorPink !important;
        border-top: none !important;
    }
}
.select2-container {
    &.select2-dropdown-open.select2-container-active {
        outline: 0px solid @colorPink !important;
        border: 1px solid @colorPink !important;
    }
}

.styled-select {
    position: relative;
    z-index: 1;
    &:before {
        color: @colorFontGray;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f0d7";
        display: block;
        position: absolute;
        right: 15px;
        top: 9px;
        font-size: 16px;
        z-index: 1;
    }
    .select2-container,
    select {
        display: block;
        border: 1px solid @colorBorderGray;
        width: 100%;
        height: 34px;
        line-height: 21px;
        background: #fff;
        color: @colorFontBlackLigther;
        padding: 5px 10px;
        font-size: 16px;
        font-weight: @formFontWeight;

        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.error {
            border-color: @colorFontRed;
        }
        a {
            margin: 0;
            padding: 0;
            border: none;
            background: none;
            box-shadow: none;
            -webkit-box-shadow: none;

            .select2-arrow {
                display: none;
                background: none;
                border: none;
            }

            &:focus {
                outline: 0px solid @colorPink !important;
                border: 1px solid @colorPink !important;
            }
        }

        &:focus {
            outline: 0px solid @colorPink !important;
            border: 1px solid @colorPink !important;
        }
    }
}

input[type="text"],
input[type="password"] {
    display: block;
    border: 1px solid @colorBorderGray;
    border-radius: 0;
    width: 100%;
    height: 34px;
    line-height: 21px;
    background: #fff;
    color: @colorFontBlackLigther;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: @formFontWeight;

    &.error {
        border-color: @colorFontRed;
    }
    &:focus {
        outline: 0px solid @colorPink !important;
        border: 1px solid @colorPink !important;
    }

    &[disabled] {
        background: @colorBgGray;
    }
}

.intl-tel-input {
    width: 100%;
}

textarea {
    display: block;
    border: 1px solid @colorBorderGray;
    border-radius: 0;
    width: 100%;
    height: 200px;
    line-height: 21px;
    background: #fff;
    color: @colorFontGrayDark;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 300;

    &.error {
        border-color: @colorFontRed;
    }

    &:focus {
        outline: 0px solid @colorPink !important;
        border: 1px solid @colorPink !important;
    }
}

form.greater {
    .form-element {
        //float: left;
        //margin-right: 5px;
        input[type='text'] {
            height: 50px;
        }
        .styled-select {
            display: block;
            &:before {
                top: 18px;
            }
            select {
                height: 50px;
            }
        }
        .input-group-btn {
            .btn {
                height: 50px;
            }
        }
    }
}

.styled-radiobox,
.styled-checkbox {
    label:not(.error) {
        cursor: pointer;
        font-weight: normal;
        margin-bottom: 5px;
        &:before {
            display: block;
            content: ' ';
            font: normal normal normal 14px/1 FontAwesome;
            line-height: 18px;
            width: 18px;
            height: 18px;
            background: #fff;
            border: 1px solid @colorBorderGray;
            color: @colorGreen;
            float: left;
            margin-right: 10px;
            padding-left: 1px;
            cursor: pointer;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        display: none;
        &:checked {
            & + label {
                &:before {
                    content: '\f00c';
                }
            }
        }
    }

    &.inline {
        padding-left: 0;
        line-height: 34px;
        label {
            margin-bottom: 0;
            margin-right: 20px;
            display: inline-block;
            line-height: normal;
            vertical-align: middle;

        }
    }
}

.ui-datepicker {
    background: #fff;
    width: 210px;
    padding: 5px;
    border: 1px solid @colorBgGray;
    display: none;
    .ui-datepicker-group{
        width: 48%;
        float: left;
        &:first-child {
            float: left;
        }
        &:nth-child(2) {
            float: right;
        }
    }

    .ui-datepicker-header {
        padding: 5px 0px;
        .ui-corner-all {
            text-decoration: none;
            color: @colorPink;
            width: 20px;
            height: 20px;
            &:hover {
                color: @colorPinkDarker;
            }
            &:after {
                display: block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transform: translate(0, 0);
                width: 20px;
                height: 20px;
                font-size: 20px;
                background: #fff;
                text-align: center;
                cursor: pointer;
            }

            span {
                display: none;
            }
            &.ui-datepicker-prev {
                float: left;
                &:after {
                    content: "\f0d9";
                }
            }
            &.ui-datepicker-next {
                float: right;
                &:after {
                    content: "\f0da";
                }
            }

        }
        .ui-datepicker-title {
            text-align: center;
        }
    }
    .ui-datepicker-calendar {
        width: 100%;
        th, td {
            text-align: center;
            font-weight: 300;
            width: 28px;
        }
        td {
            &.selected-range {
                background-color: @colorBlueTitle;
                color: @colorBgGray;
                a {
                    color: @colorBgGray;
                }
            }
            &.start-range {
                background-color: @colorFontBlueDark;
                color: @colorBgGray;
                &>a.ui-state-active,
                &>a.ui-state-default {
                    background-color: @colorFontBlueDark;;
                    color: @colorBgGray;
                }
            }
            &.end-range {
                background-color: @colorFontBlueDark;
                color: @colorBgGray;
                &>a.ui-state-active,
                &>a.ui-state-default {
                    background-color: @colorFontBlueDark;;
                    color: @colorBgGray;
                }
            }
            &.ui-datepicker-today {
                a {
                    background: @colorBgGray;
                }
            }
            &.ui-state-disabled {
                color: @colorBgGray;
            }
            a {
                display: block;
                padding: 2px 0;

                color: @colorFontGray;
                text-decoration: none;
                &.ui-state-active {
                    background: @colorBlue;
                    color: #fff;
                }
                &:hover {
                    background: @colorPink;
                    color: #fff;
                }
            }
        }
    }
}

form {
    ul.errors {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: 2px 5px;
            color: @colorFontRed;
            i {
                margin-right: 5px;
            }
        }
    }

    label.error {
        color: @colorFontRed;
        display: block;
        font-weight: normal;
        margin-top: 0.5em;
    }
    .form-element {
        .description {
            font-size: 13px;
            color: @colorFontGrayLight;
        }
    }

    .control-label {
        font-weight: normal;
    }

    div.control-label {
        line-height: 34px;
        label {
            font-weight: normal;
            vertical-align: middle;
            display: inline-block;
            line-height: normal;
            margin: 0;
            font-size: 15px;
        }
    }


    .input-group-addon {
        border-radius: 0;
        border-color: @colorBorderGray;
        background: @colorPink;
        color: @colorWhite;
    }
    .input-group-btn {
        .btn {
            border-color: @colorBorderGray;
            border-radius: 0;
            height: 34px;
        }
    }

    .placeholder {
        .placeholder-text {
            display: none;
        }
        .placeholder-value {
            display: none;
        }
    }
}



/* Extra small devices (phones, less than 768px) */
@media (max-width: @screen-xs-max) {
    form {
        .placeholder {
            position: relative;
            border: 1px solid #b9b4b4;
            width: 100%;
            height: 34px;
            line-height: 21px;
            background: #fff;
            color: #333;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: 300;
            input {
                display: none;
            }
            .placeholder-text {
                display: inline;
                color: @colorFontGrayLight;
                position: absolute;
            }
            .placeholder-value {
                display: inline;
                background: #fff;
                position: absolute;
            }
        }
    }
}
