.font-smoothing(@smoothing) {
    -webkit-font-smoothing: @smoothing;
}

.text-size-adjust(@adjust) {
    -webkit-text-size-adjust: @adjust;
    -ms-text-size-adjust: @adjust;
}

.font-feature-settings(@settings) {
    -webkit-font-feature-settings: @settings;
    -moz-font-feature-settings: @settings;
}

.border-radius(@radius) {
    border-radius: @radius;
    -webkit-border-radius:@radius;
    -moz-border-radius: @radius;
}

.gradient(@startColor: #000000, @endColor: #ffffff, @directionFrom: bottom, @directionTo: top, @stopPercent: 100%, @startColorIE: #000000, @endColorIE: #ffffff) {
    background-color: @startColor;
    background: -webkit-gradient(linear, left @directionTo, left @directionFrom, color-stop(0%,@startColor), color-stop(@stopPercent,@endColor)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(@directionTo, @startColor 0%,@endColor @stopPercent); /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(@directionTo, @startColor 0%, @endColor @stopPercent); /* FF3.6+ */
    background: -ms-linear-gradient(@directionTo, @startColor 0%,@endColor @stopPercent); /* IE10+ */
    background: -o-linear-gradient(@directionTo, @startColor 0%,    @endColor @stopPercent); /* Opera 11.10+ */
    background: linear-gradient(to @directionFrom, @startColor 0%,@endColor @stopPercent); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorStr=@startColorIE, endColorStr=@endColorIE); /* IE6-9 brak obsługi RGBA */
}