#weatherIndexMap {
    height: 450px;
    margin-bottom: 10px;
    .weather-popup {
        .weather-popup-icon {
            float: left;
        }
        .weather-popup-content {
            h1 {
                font-size: 12pt;
                margin: 0 0 5px 69px;
                line-height: 40px;
            }
            dl {
                margin: 0 0 5px 0;
                dt {
                    float: left;
                }
                dd {
                    margin-left: 80px;
                }
            }
        }
    }
}
.weather {
    margin-bottom: 30px;

    h1.page-title {
        margin-top: 0;
        text-align: left;
        small {
            display: block;
        }
    }
    .forecast {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 5px 0;
                padding: 0;
                .name {
                    display: table;
                    img {
                        float: left;
                        margin-right: 10px;
                    }
                    span.day {
                        display: table-cell;
                        vertical-align: middle;
                        width: 100%;
                        text-align: left;
                    }

                    a {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }

                .description {
                    font-size: 9pt;
                    background: @colorPinkBgLightest;
                    line-height: 40px;
                    span {
                        white-space: nowrap;
                        display: block;
                        float: left;
                        width: 25%;

                        &.title {
                            font-weight: 500;
                        }

                        &.value {
                            color: @colorPinkDarkest;
                        }
                    }
                }
                .more-link {
                    font-size: 9pt;
                    white-space: nowrap;
                    background: @colorPinkBgLightest;
                    line-height: 40px;

                    a {
                        color: @colorPinkDark;
                    }
                }
                .weather-popup {
                    display: none;
                }
            }
        }
    }

    .shortInfo {
        position: relative;
        height: 40px;
        display: table;
        border-top: 1px solid @colorPink;
        border-bottom: 1px solid @colorPink;
        .description {
            display: table-cell;
            vertical-align: middle;
            padding-right: 150px;
            width: 100%;
            height: 40px;
            text-align: center;
        }
        .date {
            position: absolute;
            width: 250px;
            height: 40px;
            right: 0;
            top: 0;
            text-align: center;
            padding: 5px;
            background: @colorPinkLightest;
            font-size: 12pt;
            line-height: 30px;

            a {
                color: @colorPinkDarker;
            }
        }
    }
    .longInfo {
        border-bottom: 1px solid @colorPink;
        .icon {
            float: left;
            margin: 20px 0;
        }
        .data {
            margin-left: 80px;
            overflow: hidden;
            .item {
                width: 33.33%;
                float: left;
                position: relative;
                padding: 5px;
                margin: 2px 0;
                font-size: 9pt;
                .item-title {
                    padding-right: 70px;
                    width: 100%;
                    display: block
                }
                .item-value {
                    position: absolute;
                    width: 65px;
                    right: 0;
                    top: 0;
                    text-align: center;
                    padding: 5px;
                    background: @colorPinkLightest;
                }
            }

        }
    }
    .nearby-locations {
        overflow: hidden;
        li {
            float: left;
            width: 50%;
        }
    }
    #mini-location-map {
        height: 180px;
    }

    .accu-weather-link {
        text-align: right;
    }
}

.weather-widget {
    .icon {
        display: inline-block;
        vertical-align: middle;
    }

    .temperature {
        font-size: 1.5em;
        display: inline-block;
    }

    a {
        display: block;
    }
}