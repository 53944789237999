.offer-map-popup {
    .offer-map-popup-image {
        float: left;
    }
    .offer-map-popup-content {
        margin-left: 90px;
        h1 {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }
        p {
            font-size: 13px;
            margin: 0 0 3px;
        }
        a {
            font-size: 11px;
        }
    }
}