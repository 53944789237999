.icon-offer-special-wielkanoc {
    background: url('/img/offer-special/wc-icon_wielkanoc-kolor.svg') no-repeat;
}

.icon-offer-special-weekend-majowy {
    background: url('/img/offer-special/wc-icon_weekend-majowy-kolor.svg') no-repeat;
}

.icon-offer-special-weekend-czerwcowy {
    background: url('/img/offer-special/wc-icon_weekend-czerwcowy-kolor.svg') no-repeat;
}

.icon-offer-special-wakacje {
    background: url('/img/offer-special/wc-icon_wakacje-kolor.svg') no-repeat;
}

.icon-offer-special-zielone-szkoly {
    background: url('/img/offer-special/wc-icon_zielone-szkoly-kolor.svg') no-repeat;
}

.icon-offer-special-weekend-sierpniowy {
    background: url('/img/offer-special/wc-icon_weekend-sierpniowy-kolor.svg') no-repeat;
}

.icon-offer-special-narty {
    background: url('/img/offer-special/wc-icon_narty-kolor.svg') no-repeat;
}

.icon-offer-special-sylwester {
    background: url('/img/offer-special/wc-icon_nowy-rok-kolor.svg') no-repeat;
}

.icon-offer-special-andrzejki {
    background: url('/img/offer-special/wc-icon_andrzejki-kolor.svg') no-repeat;
}

.icon-offer-special-boze-narodzenie {
    background: url('/img/offer-special/wc-icon_boze-narodzenie-kolor.svg') no-repeat;
}

.icon-offer-special-ferie {
    background: url('/img/offer-special/wc-icon_ferie-kolor.svg') no-repeat;
}

.icon-offer-special-walentynki {
    background: url('/img/offer-special/wc-icon_walentynki-kolor.svg') no-repeat;
}

.icon-offer-special-weekend {
    background: url('/img/offer-special/wc-icon_weekend-kolor.svg') no-repeat;
}

.icon-offer-special-last-minute {
    background: url('/img/offer-special/nc-icon_last-minute-kolor.svg') no-repeat;
}

.icon-offer-special-wielkanoc-gray {
    background: url('/img/offer-special/nc-icon_wielkanoc.svg') no-repeat;
}

.icon-offer-special-weekend-majowy-gray {
    background: url('/img/offer-special/nc-icon_weekend-majowy.svg') no-repeat;
}

.icon-offer-special-weekend-czerwcowy-gray {
    background: url('/img/offer-special/nc-icon_weekend-czerwcowy.svg') no-repeat;
}

.icon-offer-special-wakacje-gray {
    background: url('/img/offer-special/nc-icon_wakacje.svg') no-repeat;
}

.icon-offer-special-zielone-szkoly-gray {
    background: url('/img/offer-special/nc-icon_zielone-szkoly.svg') no-repeat;
}

.icon-offer-special-weekend-sierpniowy-gray {
    background: url('/img/offer-special/nc-icon_weekend-sierpniowy.svg') no-repeat;
}

.icon-offer-special-narty-gray {
    background: url('/img/offer-special/nc-icon_narty.svg') no-repeat;
}

.icon-offer-special-sylwester-gray {
    background: url('/img/offer-special/nc-icon_nowy-rok.svg') no-repeat;
}

.icon-offer-special-andrzejki-gray {
    background: url('/img/offer-special/nc-icon_andrzejki.svg') no-repeat;
}

.icon-offer-special-boze-narodzenie-gray {
    background: url('/img/offer-special/nc-icon_boze-narodzenie.svg') no-repeat;
}

.icon-offer-special-ferie-gray {
    background: url('/img/offer-special/nc-icon_ferie.svg') no-repeat;
}

.icon-offer-special-walentynki-gray {
    background: url('/img/offer-special/nc-icon_walentynki.svg') no-repeat;
}

.icon-offer-special-weekend-gray {
    background: url('/img/offer-special/nc-icon_weekend.svg') no-repeat;
}

.icon-offer-special-last-minute-gray {
    background: url('/img/offer-special/nc-icon_last-minute.svg') no-repeat;
}

.icon-offer-special {
    display: inline-block;
    vertical-align: middle;
}

.iofs-40 {
    width: 40px;
    height: 40px;
    background-size: 40px;
}

.iofs-100 {
    width: 100px;
    height: 100px;
    background-size: 100px;
}

.iofs-150 {
    width: 150px;
    height: 150px;
    background-size: 150px;
}

.icon-offer-special-wide-andrzejki {
    background: url('/img/offer-special/bg_andrzejki.png') no-repeat;
}

.icon-offer-special-wide-boze-narodzenie {
    background: url('/img/offer-special/bg_boze-narodzenie.png') no-repeat;
}

.icon-offer-special-wide-ferie {
    background: url('/img/offer-special/bg_ferie-zimowe.png') no-repeat;
}

.icon-offer-special-wide-narty {
    background: url('/img/offer-special/bg_narty.png') no-repeat;
}

.icon-offer-special-wide-sylwester {
    background: url('/img/offer-special/bg_nowy-rok.png') no-repeat;
}

.icon-offer-special-wide-walentynki {
    background: url('/img/offer-special/bg_walentynki.png') no-repeat;
}

.icon-offer-special-wide-weekend-czerwcowy {
    background: url('/img/offer-special/bg_weekend-czerwcowy.png') no-repeat;
}

.icon-offer-special-wide-weekend-majowy {
    background: url('/img/offer-special/bg_weekend-majowy.png') no-repeat;
}

.icon-offer-special-wide-weekend-sierpniowy {
    background: url('/img/offer-special/bg_weekend-sierpniowy.png') no-repeat;
}

.icon-offer-special-wide-wielkanoc {
    background: url('/img/offer-special/bg_wielkanoc.png') no-repeat;
}

.icon-offer-special-wide-zielone-szkoly {
    background: url('/img/offer-special/bg_zielone-szkoly.png') no-repeat;
}

.icon-offer-special-wide-weekend {
    background: url('/img/offer-special/bg_weekend.png') no-repeat;
}

.icon-offer-special-wide-last-minute {
    background: url('/img/offer-special/bg_last-minute.png') no-repeat;
}

.icon-offer-special-wide-wakacje {
    background: url('/img/offer-special/bg_wakacje.png') no-repeat;
}

.icon-offer-special-wide {
    display: block;
    background-size: 100%;
    background-position: center;
    width: 100%;
    min-height: 160px;
}

.iofsw-gradient:after {
    display: block;
    width: 100%;
    min-height: 160px;
    content: " ";
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyNSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMC41Ii8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 15%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right top, color-stop(15%,rgba(255,255,255,1)), color-stop(50%,rgba(255,255,255,0.5)), color-stop(75%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 15%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(left, rgba(255,255,255,1) 15%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(left, rgba(255,255,255,1) 15%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%); /* IE10 preview */
    background: linear-gradient(to right, rgba(255,255,255,1) 15%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-8 */
}

@media (max-width: @screen-phone-vertical-max) {
    .iofs-150 {
        width: 60px;
        height: 60px;
        background-size: 60px;
    }
}