.expandable-content {
    position: relative;
    margin-bottom: 30px;
    line-height: 18px;
    max-height: 120px;
    overflow: hidden;

    p {
        margin: 0 0 3px 5px;
    }

    .transparent-row {
        .gradient(rgba(240, 238, 239, 0.0), #f0eeef, bottom, top, 50%);
        height: 80px;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;

        a {
            display: block;
            text-align: center;
            width: 100%;
            height: 40px;
            padding-top: 50px;
            font-weight: 400;
            font-size: 14px;
        }
    }
}
