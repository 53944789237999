@import "variable/color.less";
@import "variable/screen-resolution.less";

.weather-picture {
    background: url('/img/weather-picture.png');
    width: 80px;
    height: 80px;
    &.icon-01 {
        background-position: 0px 0px;
    }
    &.icon-02 {
        background-position: 0px -80px;
    }
    &.icon-03 {
        background-position: 0px -160px;
    }
    &.icon-04 {
        background-position: 0px -240px;
    }
    &.icon-05 {
        background-position: 0px -320px;
    }
    &.icon-06 {
        background-position: 0px -400px;
    }
    &.icon-07 {
        background-position: 0px -480px;
    }
    &.icon-08 {
        background-position: 0px -560px;
    }
    &.icon-11 {
        background-position: 0px -640px;
    }
    &.icon-12 {
        background-position: 0px -720px;
    }
    &.icon-13 {
        background-position: 0px -800px;
    }
    &.icon-14 {
        background-position: 0px -880px;
    }
    &.icon-15 {
        background-position: 0px -960px;
    }
    &.icon-16 {
        background-position: 0px -1040px;
    }
    &.icon-17 {
        background-position: 0px -1120px;
    }
    &.icon-18 {
        background-position: 0px -1200px;
    }
    &.icon-19 {
        background-position: 0px -1280px;
    }
    &.icon-20 {
        background-position: 0px -1360px;
    }
    &.icon-21 {
        background-position: 0px -1440px;
    }
    &.icon-22 {
        background-position: 0px -1520px;
    }
    &.icon-23 {
        background-position: 0px -1600px;
    }
    &.icon-24 {
        background-position: 0px -1680px;
    }
    &.icon-25 {
        background-position: 0px -1760px;
    }
    &.icon-26 {
        background-position: 0px -1840px;
    }
    &.icon-29 {
        background-position: 0px -1920px;
    }
    &.icon-30 {
        background-position: 0px -2000px;
    }
    &.icon-31 {
        background-position: 0px -2080px;
    }
    &.icon-32 {
        background-position: 0px -2160px;
    }
    &.icon-33 {
        background-position: 0px -2240px;
    }
    &.icon-34 {
        background-position: 0px -2320px;
    }
    &.icon-35 {
        background-position: 0px -2400px;
    }
    &.icon-36 {
        background-position: 0px -2480px;
    }
    &.icon-37 {
        background-position: 0px -2560px;
    }
    &.icon-38 {
        background-position: 0px -2640px;
    }
    &.icon-39 {
        background-position: 0px -2720px;
    }
    &.icon-40 {
        background-position: 0px -2800px;
    }
    &.icon-41 {
        background-position: 0px -2880px;
    }
    &.icon-42 {
        background-position: 0px -2960px;
    }
    &.icon-43 {
        background-position: 0px -3040px;
    }
    &.icon-44 {
        background-position: 0px -3120px;
    }

}