@font-face {
  font-family: 'e-nocleg-icons';
  src:  url('/build/fonts/e-nocleg-icons.eot?w5catg');
  src:  url('/build/fonts/e-nocleg-icons.eot?w5catg#iefix') format('embedded-opentype'),
    url('/build/fonts/e-nocleg-icons.ttf?w5catg') format('truetype'),
    url('/build/fonts/e-nocleg-icons.woff?w5catg') format('woff'),
    url('/build/fonts/e-nocleg-icons.svg?w5catg#e-nocleg-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.nc {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'e-nocleg-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nc-buildings:before {
  content: "\e908";
}
.nc-lake:before {
  content: "\e909";
}
.nc-mountains:before {
  content: "\e90a";
}
.nc-sea:before {
  content: "\e90b";
}
.nc-phone:before {
  content: "\e600";
}
.nc-envelope:before {
  content: "\e601";
}
.nc-website:before {
  content: "\e602";
}
.nc-calendar:before {
  content: "\e603";
}
.nc-marker:before {
  content: "\e604";
}
.nc-person-1:before {
  content: "\e605";
}
.nc-person-2:before {
  content: "\e606";
}
.nc-person-3:before {
  content: "\e607";
}
.nc-person-4:before {
  content: "\e608";
}
.nc-person-5:before {
  content: "\e609";
}
.nc-person-6:before {
  content: "\e60a";
}
.nc-map:before {
  content: "\e60b";
}
.nc-bank:before {
  content: "\e60c";
}
.nc-phone-old:before {
  content: "\e60e";
}
.nc-arrow-right:before {
  content: "\e90c";
}
.nc-arrow-left:before {
  content: "\e90d";
}
.nc-amenity:before {
  content: "\e90e";
}
.nc-guest-o:before {
  content: "\e90f";
}
.nc-guest:before {
  content: "\e910";
}
.nc-bed-bunk:before {
  content: "\e900";
}
.nc-bed-double-big:before {
  content: "\e901";
}
.nc-bed-double-standard:before {
  content: "\e902";
}
.nc-bed-double-twin:before {
  content: "\e903";
}
.nc-bed-extra:before {
  content: "\e904";
}
.nc-bed-single:before {
  content: "\e905";
}
.nc-bed-sofa:before {
  content: "\e906";
}
.nc-mattress:before {
  content: "\e907";
}
.nc-service-priority:before {
  content: "\e947";
}
.nc-service-type:before {
  content: "\e94d";
}
.nc-service-priority-gray .path1:before {
  content: "\e94e";
  color: rgb(226, 226, 226);
}
.nc-service-priority-gray .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.nc-service-priority-gray .path3:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(79, 79, 79);
}
.nc-service-priority-gray .path4:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(194, 194, 194);
}
.nc-service-priority-gray .path5:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.nc-service-priority-gray .path6:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(133, 133, 133);
}
.nc-service-priority-gray .path7:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(133, 133, 133);
}
.nc-service-priority-gray .path8:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(227, 227, 227);
}
.nc-service-priority-gray .path9:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(79, 79, 79);
}
.nc-service-priority-gray .path10:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(79, 79, 79);
}
.nc-service-priority-gray .path11:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(79, 79, 79);
}
.nc-service-priority-gray .path12:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(195, 195, 195);
}
.nc-service-priority-gray .path13:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(138, 138, 138);
}
.nc-service-priority-gray .path14:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(138, 138, 138);
}
.nc-service-priority-gray .path15:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(138, 138, 138);
}
.nc-service-priority-gray .path16:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.nc-service-priority-gray .path17:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(122, 122, 122);
}
.nc-service-priority-gray .path18:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(79, 79, 79);
}
.nc-service-priority-gray .path19:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-recommended:before {
  content: "\e961";
}
.nc-service-type-gray .path1:before {
  content: "\e962";
  color: rgb(226, 226, 226);
}
.nc-service-type-gray .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(194, 194, 194);
}
.nc-service-type-gray .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(194, 194, 194);
}
.nc-service-type-gray .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.nc-service-type-gray .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.nc-service-type-gray .path6:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(152, 152, 152);
}
.nc-service-type-gray .path7:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(152, 152, 152);
}
.nc-service-type-gray .path8:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(152, 152, 152);
}
.nc-service-type-gray .path9:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(119, 119, 119);
}
.nc-service-type-gray .path10:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path11:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path12:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path13:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path14:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path15:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path16:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path17:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path18:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path19:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(152, 152, 152);
}
.nc-service-type-gray .path20:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path21:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path22:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path23:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path24:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path25:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(194, 194, 194);
}
.nc-service-type-gray .path26:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path27:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path28:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path29:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path30:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(129, 129, 129);
}
.nc-service-type-gray .path31:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(71, 71, 71);
}
.nc-service-type-gray .path32:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(135, 135, 135);
}
.nc-service-type-gray .path33:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(135, 135, 135);
}
.nc-service-type-gray .path34:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-gray .path35:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(242, 242, 240);
}
.nc-service-type-gray .path36:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.nc-service-recommended-gray .path1:before {
  content: "\e986";
  color: rgb(226, 226, 226);
}
.nc-service-recommended-gray .path2:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(133, 133, 133);
}
.nc-service-recommended-gray .path3:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-recommended-gray .path4:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(133, 133, 133);
}
.nc-service-recommended-gray .path5:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(105, 105, 105);
}
.nc-service-recommended-gray .path6:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-recommended-gray .path7:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-recommended-gray .path8:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-gray .path9:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(231, 231, 231);
}
.nc-service-recommended-gray .path10:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(191, 191, 191);
}
.nc-service-recommended-gray .path11:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-gray .path12:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-gray .path13:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-gray .path14:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-gray .path15:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(129, 129, 129);
}
.nc-service-recommended-gray .path16:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(32, 32, 32);
}
.nc-service-recommended-gray .path17:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-recommended-gray .path18:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(149, 149, 149);
}
.nc-service-priority-color .path1:before {
  content: "\e998";
  color: rgb(68, 160, 208);
}
.nc-service-priority-color .path2:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(53, 80, 97);
}
.nc-service-priority-color .path3:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(28, 61, 77);
}
.nc-service-priority-color .path4:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(113, 226, 239);
}
.nc-service-priority-color .path5:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(56, 198, 217);
}
.nc-service-priority-color .path6:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(229, 94, 84);
}
.nc-service-priority-color .path7:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(229, 94, 84);
}
.nc-service-priority-color .path8:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 225, 159);
}
.nc-service-priority-color .path9:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(81, 81, 81);
}
.nc-service-priority-color .path10:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(81, 81, 81);
}
.nc-service-priority-color .path11:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(81, 81, 81);
}
.nc-service-priority-color .path12:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(226, 195, 109);
}
.nc-service-priority-color .path13:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(140, 138, 137);
}
.nc-service-priority-color .path14:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(140, 138, 137);
}
.nc-service-priority-color .path15:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(140, 138, 137);
}
.nc-service-priority-color .path16:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(53, 80, 97);
}
.nc-service-priority-color .path17:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(204, 87, 84);
}
.nc-service-priority-color .path18:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(28, 61, 77);
}
.nc-service-priority-color .path19:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-highligh-gray .path1:before {
  content: "\e9ab";
  color: rgb(226, 226, 226);
}
.nc-service-highligh-gray .path2:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path3:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(143, 143, 143);
}
.nc-service-highligh-gray .path4:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(71, 71, 71);
}
.nc-service-highligh-gray .path5:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(199, 199, 199);
}
.nc-service-highligh-gray .path6:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(57, 57, 57);
}
.nc-service-highligh-gray .path7:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path8:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(241, 241, 241);
}
.nc-service-highligh-gray .path9:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path10:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path11:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(222, 222, 222);
}
.nc-service-highligh-gray .path12:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path13:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path14:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path15:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path16:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(129, 129, 129);
}
.nc-service-highligh-gray .path17:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(71, 71, 71);
}
.nc-service-highligh-gray .path18:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(199, 199, 199);
}
.nc-service-highligh-gray .path19:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(199, 199, 199);
}
.nc-service-highligh-gray .path20:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(241, 241, 241);
}
.nc-service-highligh-gray .path21:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(211, 211, 211);
}
.nc-service-highligh-gray .path22:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(143, 143, 143);
}
.nc-service-highligh-gray .path23:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(133, 133, 133);
}
.nc-service-highligh-gray .path24:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highligh-gray .path25:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(143, 143, 143);
}
.nc-service-highligh-gray .path26:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(214, 214, 214);
}
.nc-service-highligh-gray .path27:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(214, 214, 214);
}
.nc-service-type-color .path1:before {
  content: "\e9c6";
  color: rgb(68, 160, 208);
}
.nc-service-type-color .path2:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(255, 225, 159);
}
.nc-service-type-color .path3:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 225, 159);
}
.nc-service-type-color .path4:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(226, 195, 109);
}
.nc-service-type-color .path5:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(226, 195, 109);
}
.nc-service-type-color .path6:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(255, 112, 88);
}
.nc-service-type-color .path7:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 112, 88);
}
.nc-service-type-color .path8:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 112, 88);
}
.nc-service-type-color .path9:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(181, 99, 53);
}
.nc-service-type-color .path10:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path11:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path12:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path13:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path14:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path15:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path16:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path17:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path18:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path19:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path20:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(255, 112, 88);
}
.nc-service-type-color .path21:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path22:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path23:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path24:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path25:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path26:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 224, 125);
}
.nc-service-type-color .path27:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path28:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path29:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path30:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path31:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(222, 101, 22);
}
.nc-service-type-color .path32:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(51, 77, 92);
}
.nc-service-type-color .path33:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-type-color .path34:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-type-color .path35:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-type-color .path36:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(235, 230, 230);
}
.nc-service-type-color .path37:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(255, 136, 0);
}
.nc-service-tag-gray .path1:before {
  content: "\e9eb";
  color: rgb(226, 226, 226);
}
.nc-service-tag-gray .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(147, 147, 147);
}
.nc-service-tag-gray .path3:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(186, 186, 186);
}
.nc-service-highlight:before {
  content: "\e9ee";
}
.nc-service-recommended-color .path1:before {
  content: "\e9ef";
  color: rgb(68, 160, 208);
}
.nc-service-recommended-color .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(229, 94, 84);
}
.nc-service-recommended-color .path3:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-recommended-color .path4:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(229, 94, 84);
}
.nc-service-recommended-color .path5:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(0, 143, 191);
}
.nc-service-recommended-color .path6:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-recommended-color .path7:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-recommended-color .path8:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-color .path9:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(235, 230, 230);
}
.nc-service-recommended-color .path10:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(239, 191, 58);
}
.nc-service-recommended-color .path11:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-color .path12:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-color .path13:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-color .path14:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-recommended-color .path15:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(222, 101, 22);
}
.nc-service-recommended-color .path16:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(56, 22, 22);
}
.nc-service-recommended-color .path17:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-recommended-color .path18:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-tag:before {
  content: "\ea01";
}
.nc-service-highlight-color .path1:before {
  content: "\ea02";
  color: rgb(68, 160, 208);
}
.nc-service-highlight-color .path2:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path3:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(71, 178, 156);
}
.nc-service-highlight-color .path4:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(51, 77, 92);
}
.nc-service-highlight-color .path5:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(239, 201, 76);
}
.nc-service-highlight-color .path6:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(41, 62, 74);
}
.nc-service-highlight-color .path7:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path8:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(235, 242, 255);
}
.nc-service-highlight-color .path9:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path10:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path11:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(255, 224, 125);
}
.nc-service-highlight-color .path12:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path13:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path14:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path15:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path16:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(222, 101, 22);
}
.nc-service-highlight-color .path17:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(51, 77, 92);
}
.nc-service-highlight-color .path18:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-highlight-color .path19:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(255, 105, 93);
}
.nc-service-highlight-color .path20:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(235, 242, 255);
}
.nc-service-highlight-color .path21:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(252, 212, 98);
}
.nc-service-highlight-color .path22:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(71, 178, 156);
}
.nc-service-highlight-color .path23:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(229, 94, 84);
}
.nc-service-highlight-color .path24:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.nc-service-highlight-color .path25:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(255, 68, 53);
}
.nc-service-highlight-color .path26:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(212, 214, 216);
}
.nc-service-highlight-color .path27:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(212, 214, 216);
}
.nc-service-tag-color .path1:before {
  content: "\e940";
  color: rgb(254, 137, 1);
}
.nc-service-tag-color .path2:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 164, 74);
}
.nc-service-tag-color .path3:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(254, 137, 1);
}
.nc-service-tag-color .path4:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 164, 74);
}
.nc-service-tag-color .path5:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(68, 160, 208);
}
.nc-service-tag-color .path6:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(254, 137, 1);
}
.nc-service-tag-color .path7:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 164, 74);
}
.nc-kitchenette:before {
  content: "\e911";
  color: #736c6c;
}
.nc-kettle:before {
  content: "\e912";
  color: #736c6c;
}
.nc-non-smoking:before {
  content: "\e913";
  color: #736c6c;
}
.nc-fireplace:before {
  content: "\e914";
  color: #736c6c;
}
.nc-bathroom:before {
  content: "\e915";
  color: #736c6c;
}
.nc-fridge:before {
  content: "\e916";
  color: #736c6c;
}
.nc-microwave:before {
  content: "\e917";
  color: #736c6c;
}
.nc-mini-bar:before {
  content: "\e918";
  color: #736c6c;
}
.nc-radio:before {
  content: "\e919";
  color: #736c6c;
}
.nc-towels:before {
  content: "\e91a";
  color: #736c6c;
}
.nc-breakfast-included:before {
  content: "\e91b";
  color: #736c6c;
}
.nc-cleaning:before {
  content: "\e91c";
  color: #736c6c;
}
.nc-dryer:before {
  content: "\e91d";
  color: #736c6c;
}
.nc-tv:before {
  content: "\e91e";
  color: #736c6c;
}
.nc-iron:before {
  content: "\e91f";
  color: #736c6c;
}
.nc-internet-wifi:before {
  content: "\e920";
  color: #736c6c;
}
.nc-air-conditioning:before {
  content: "\e921";
  color: #736c6c;
}
.nc-room-of-balcony:before {
  content: "\e922";
  color: #736c6c;
}
.nc-accept-cards:before {
  content: "\e923";
  color: #736c6c;
}
.nc-accept-animals:before {
  content: "\e924";
  color: #736c6c;
}
.nc-restaurant:before {
  content: "\e925";
  color: #736c6c;
}
.nc-outdoor-pool:before {
  content: "\e926";
  color: #736c6c;
}
.nc-pool:before {
  content: "\e927";
  color: #736c6c;
}
.nc-access-to-computer:before {
  content: "\e928";
  color: #736c6c;
}
.nc-grill:before {
  content: "\e929";
  color: #736c6c;
}
.nc-tennis-court:before {
  content: "\e92a";
  color: #736c6c;
}
.nc-spot-on-fire:before {
  content: "\e92b";
  color: #736c6c;
}
.nc-order-laundering:before {
  content: "\e92c";
  color: #736c6c;
}
.nc-public-kitchen:before {
  content: "\e92d";
  color: #736c6c;
}
.nc-playground:before {
  content: "\e92e";
  color: #736c6c;
}
.nc-conference-room:before {
  content: "\e92f";
  color: #736c6c;
}
.nc-children-playroom:before {
  content: "\e930";
  color: #736c6c;
}
.nc-sauna:before {
  content: "\e931";
  color: #736c6c;
}
.nc-gym:before {
  content: "\e932";
  color: #736c6c;
}
.nc-ping-pong-table:before {
  content: "\e933";
  color: #736c6c;
}
.nc-facilities-for-families-with-children:before {
  content: "\e934";
  color: #736c6c;
}
.nc-fishing:before {
  content: "\e935";
  color: #736c6c;
}
.nc-bicycle-rental:before {
  content: "\e936";
  color: #736c6c;
}
.nc-water-equipment-rental:before {
  content: "\e937";
  color: #736c6c;
}
.nc-ski-equipment-rental:before {
  content: "\e938";
  color: #736c6c;
}
.nc-catering:before {
  content: "\e939";
  color: #736c6c;
}
.nc-billiards:before {
  content: "\e93a";
  color: #736c6c;
}
.nc-car-parking:before {
  content: "\e93b";
  color: #736c6c;
}
.nc-spa:before {
  content: "\e93c";
  color: #736c6c;
}
.nc-canteen:before {
  content: "\e93d";
  color: #736c6c;
}
.nc-facilities-for-disabled:before {
  content: "\e93e";
  color: #736c6c;
}
.nc-ski-lift:before {
  content: "\e93f";
  color: #736c6c;
}
