.rentcars_banner {
    border: 1px solid #33962d;
    border-radius: 4px;
    background: url('/img/rentcar-banner/background.jpg') no-repeat bottom;
    margin: 15px auto;
    max-width: 210px;

    .new {
        float: right;
        width: 55px;
        height: 45px;
        background: url('/img/rentcar-banner/new.png') no-repeat;
    }

    .content {
        margin: 0;
        padding: 0;
        border: none;

        a.title {
            display: block;
            padding: 12px 10px 100px 10px;
            margin: 0;

            span {
                font-size: 8pt;
                color: #33962d;
                margin-bottom: 7px;
                display: block;
                font-weight: normal;
                line-height: 14px;

                &.city {
                    font-size: 16pt;
                    font-weight: bold;
                    color: #000000;
                    display: block;
                    line-height: 1em;
                }
            }
            &:hover {
                text-decoration: none;
            }
        }
    }

    .rent_button {
        display: block;
        background: url('/img/rentcar-banner/button.png') no-repeat 0 0;
        width: 182px;
        height: 37px;
        padding: 0;
        cursor: pointer;
        margin: 0 auto;
        margin-bottom: 15px;

        &:hover {
            background: url('/img/rentcar-banner/button.png') no-repeat -1px -37px;
        }
    }

    .logo {
        display: block;
        text-align: center;
        margin-bottom: 15px;
    }

    .popover {
        max-width: none;
    }
}

.rentcars-popover {
    width: 460px;
    position: relative;

    form {
        width: 100%;
        margin: 0;
        margin-bottom: .5em;
        padding: 0;

        fieldset {
            float: left;
            margin-right: 5px;
            width: 225px;
            border: none;
            padding: 0;

            legend {
                font-size: 12pt;
                line-height: 25px;
                padding: 0 2px;
                border-bottom: 1px solid #e5e5e5;
                color: #333333;
                font-weight: normal;
                margin-bottom: 20px;
                width: 100%;
            }
        }

        dl {
            margin: 0;
            content: "";
            display: table;
            line-height: 0;
            clear: both;
            width: 100%;
            padding-bottom: 0;

            dt {
                padding-left: 5px;
                width: 100px;
                clear: left;
                float: left;
                overflow: hidden;
                text-overflow: ellipsis;

                label {
                    font-size: 9pt;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 400;
                    margin-bottom: 15px;
                }
            }

            dd {
                line-height: 20px;
                margin-left: 10px;
                width: 115px;
                float: left;

                input {
                    font-size: 14px;
                    padding: 7px;
                    width: 75px;
                    margin-bottom: 0;
                    position: relative;
                    vertical-align: top;
                    background-color: #FFFFFF;
                    border: 1px solid #CCCCCC;
                    color: #555555;
                    display: inline-block;
                    line-height: 30px;
                    margin-left: 0;
                }

                .input-group-addon {
                    cursor: pointer;
                }

                .styled-select {
                    display: inline-block;
                    width: 55px;

                    &:before {
                        right: 10px;
                    }

                    select {
                        width: 100%;
                        font-size: 14px;
                    }
                }
            }
        }

        .action {
            margin-right: 5px;
            text-align: right;
        }
    }
}
