.offer-tags {
    .label {
        background: @colorLightBlue;
        font-size: 80%;
        display: inline-block;
        padding: .4em .6em;
        margin: 2px 0;
        color: @colorWhite;
        font-weight: normal;
    }
}