@media (min-width: @screen-xs-max) {

    section.search-bar {
        .hero-background {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: @colorMarineGreen;

        }
    }
}

@media (min-width: @screen-sm-max) {
    section.search-bar {
        .hero-background {
            .container {
                height: 100%;
                .hero {
                    height: 100%;
                    background: url('/img/hero/hero@2x.png') no-repeat left center;
                    background-size: 1490px 404px;
                    margin-left: -465px;
                }
            }
        }
    }
}

.search-bar {
    height: 400px;
    position: relative;



    .search-form-container {
        margin-top: 80px;
        padding: 0px 0px;

        h1 {
            display: inline-block;
            margin: 0;
            padding: 15px;
            color: #ffffff;
            font-size: 25pt;
        }
        form {
            padding: 15px;
            input {
                margin-bottom: 20px;
            }
        }
    }
}

.offer-teaser {
    margin-bottom: 20px;
    background: #fff;
    max-height: 338px;
    margin-right: 0px;
    margin-left: 0px;
    & > a {
        text-decoration: none;
        color: inherit;
    }

    .image {
        display: block;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
        .price-box {
            position: absolute;
            right: 0;
            bottom: 0;
            background: rgba(23, 183, 155, 0.7);
            color: #fff;
            padding: 5px 15px;
            font-size: 14pt;
            span {
                font-size: 70%;
                color: #ddd;
            }
        }
    }
    .offer-teaser-container {
        display: block;
        padding: 10px 12px;
        .offer-teaser-content {
            margin-bottom: 5px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2em;

            &.description {
                margin-top: 5px;
                white-space: inherit;
                .transparent-block {
                    position: relative;
                    display: block;
                    max-height: 79px;
                    color: @colorFontGrayDark;
                    &:after {
                        content: " ";
                        z-index: 2;
                        display: block;
                        position: absolute;
                        height: 25%;
                        left: 0;
                        top: 66px;
                        width: 100%;
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
                    }
                }
            }
            &.inline-header {
                font-size: 15pt;
                font-weight: normal;
                color: @colorWhite;
                margin-bottom: 3px;
            }
            span {
                color: @colorFontGray;
            }
        }
        .read-more {
            display: block;
            color: @colorMarineGreen;
        }
        p {
            margin-bottom: 0;
            span {
                color: @colorFontGray;
            }
        }
    }
}

.recommended {
    padding-bottom: 40px;

    .new-object {
        .image {
            border: 3px solid @colorBorderGray;
            margin-bottom: 10px;
            width: 100%;
            height: 156px;
            display: block;

            .cross {
                background: @colorBorderGray;
                display: block;
                height: 52px;
                position: relative;
                width: 7px;
                left: 49%;
                top: 33%;
            }
            .cross:after {
                background: @colorBorderGray;
                content: "";
                height: 7px;
                left: -22px;
                position: absolute;
                top: 24px;
                width: 52px;
            }
        }

    }
}

section.provinces-and-regions {
    .icon-map-poland {
        width: 100%;
        height: auto;
        margin: 15px 0 45px 0;
    }

    .location-list {
        margin-bottom: 30px;
        width: 49%;
        display: inline-block;

        ul {
            text-align: left;
            list-style: none;
            margin: 0;
            padding: 0 5px;
            li {
                font-size: 11pt;
                font-weight: 300;
                color: @colorFontSpecialGray;
                border-top: 1px solid @colorBorderGray;
                padding: 10px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:last-child {
                    border-bottom: 1px solid @colorBorderGray;
                }
                &:before {
                    display: inline-block;
                    background: @colorPink;
                    width: 5px;
                    height: 5px;
                    margin: 2pt 10px 2pt 0;
                    content: " ";
                }

                a {
                    color: @colorFontSpecialGray;
                }
            }
        }
    }
}

section.special-offers {
    .holiday {
        text-align: center;
        margin-bottom: 40px;

        .icon-offer-special {
            display: block;
            margin: auto;
        }
        h3 {
            color: @colorFontSpecialGray;
            margin: 30px 0px;
            font-weight: normal;
        }
    }
}

section.popular {
    padding-bottom: 40px;
    i {
        font-size: 70px;
        color: @colorPink;
    }
    h3 {
        color: @colorPink;
        margin: 30px 0px;
        font-weight: normal;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            font-size: 12pt;
            font-weight: 300;
            color: #fff;
            padding: 10px 0;
            a {
                color: @colorFontSpecialGray;
            }
        }
    }
}

section.guides {
    padding-bottom: 40px;
    h2.section-title {
        overflow: hidden;
        margin-left: 75px;
        margin-right: 80px;
    }

    #carousel-guide {
        padding: 40px 45px 0;

        .carousel-indicators {
            top: 0;
            height: 20px;
            li {
                border: none;
                background: #DDDDDD;
                &.active {
                    background: @colorPink;
                }
            }
        }

        .carousel-guide-control {
            position: absolute;
            top: 0;
            height: 100%;
            color: @colorFontGray;
            text-decoration: none;
            &.left {
                left: 0;

            }
            &.right {
                right: 0;
            }
            & > img {
                top: 50%;
                position: relative;
                margin-top: -30px;
            }
        }

        .carousel-inner {
            .offer-teaser {
                margin-bottom: 0;
                .offer-teaser-container {
                    height: 180px;
                    .description {
                        margin-top: 15px;
                    }
                    .offer-teaser-content.inline-header {
                        margin: -11px -13px 0;
                        padding: 15px;
                        font-size: 11pt;
                        font-weight: normal;
                        color: @colorWhite;
                        background: @colorPink;
                        a {
                            color: inherit;
                        }
                    }
                }
            }
        }
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
    .search-bar {

        .hero-background {
            &.hero-1 {
                background: url('/img/nc-hero/nc-hero-01.jpg') no-repeat center;
                background-size: cover;
            }
            &.hero-2 {
                background: url("/img/nc-hero/nc-hero-02.jpg") no-repeat center;
                background-size: cover;
            }
            &.hero-3 {
                background: url("/img/nc-hero/nc-hero-03.jpg") no-repeat center;
                background-size: cover;
            }
            &.hero-4 {
                background: url("/img/nc-hero/nc-hero-04.jpg") no-repeat center;
                background-size: cover;
            }
            &.hero-5 {
                background: url("/img/nc-hero/nc-hero-05.jpg") no-repeat center;
                background-size: cover;
            }
        }

        .search-form-container {
            padding: 0px 50px;
        }
    }

}

@media (max-width: @screen-md-max) {
    .recommended {
        .new-object {
            .image {
                height: 128px;
                .cross {
                    left: 48%;
                    top: 29%;
                }
            }
        }
    }

    section.provinces-and-regions {
        .location-list {
            ul {
                li {
                    font-size: 9pt;
                }
            }
        }
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    section.special-offers {
        .holiday {
            ul {
                li {
                    padding-left: 10px;
                    padding-right: 10px;
                    &:before {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: @screen-sm-max) {
    .recommended {
        .new-object {
            .image {
                height: 132px;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .offer-teaser {
        .image {
            img {
                width: 100%;
            }
        }
    }
    .recommended {
        .new-object {
            .image {
                height: 157px;
                .cross {
                    left: 49%;
                    top: 34%;
                }
            }
        }
    }

    section.search-bar {
        background: @colorBgGray;
        height: auto;

        .search-form-container {
            h1 {
                color: @colorFontGrayDark;
                background: none;
            }
            #search-form {
                background: none;

            }
        }

        .hero-background {
            background: none;
            .hero-opacity {
                background: none;
                opacity: 0;
            }
        }
        .search-form-container {
            margin-top: 0;
            h1 {
                font-size: 26px;
                display: block;
                text-align: center;
                padding-bottom: 0;
            }
        }
    }

    section.special-offers {
        .holiday {
            margin-bottom: 20px;
        }
    }

    section.provinces-and-regions {
        .location-list {
            ul {
                padding: 0 15px;
                border: 1px solid @colorBorderGray;


                li {
                    padding: 10px 0;

                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }

        .locations {
            position: relative;
            min-height: 350px;
            overflow: hidden;

            .location-list {
                position: absolute;
                display: block;
                width: 100%;
                transition: all 0.25s ease;
                left: 0;

                ul {
                    li {
                        font-size: 8pt;
                    }
                }

                &.left {
                    left: -90%;
                    padding: 0;
                }

                &.visible {
                    left: 0;
                    padding: 0 45px;
                }

                &.right {
                    left: 90%;
                    padding: 0;
                }

                &.left,
                &.right {
                    transform: scale(0.9);
                    opacity: 0.5;
                }

            }
        }
    }
}

@media (max-width: @screen-xxs-max) {
    .offer-teaser {
        .offer-teaser-container {
            .read-more {
                display: none;
            }
            .offer-teaser-content {
                &.description {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: @screen-phone-vertical-max) {
    section.search-bar {
        .search-form-container {
            h1 {
                font-size: 20px;
            }
        }
    }

    .offer-teaser {
        .image,
        .offer-teaser-container {
            width: 100%;
        }
    }

    section.special-offers {
        .holiday h3 {
            margin: 15px 0;
        }
    }
}