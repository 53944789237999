.dictionary-locations {
    .letter-navigation {
        .border-right {
            border-right: 1px solid @colorBorderGray;
            padding: 10px 0;
        }

        .letter {
            display: inline-block;
            width: 42px;
        }

        a {
            font-family: Roboto;
            text-transform: uppercase;
            font-size: 16px;
            padding: 0 15px;
            text-decoration: none;
            color: @colorFontSpecialGray;
            font-weight: 300;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}