section.booking-navi {
    padding: 20px 0;
    ul.navi-items {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            width: 20%;
            padding: 0 10px;
            float: left;
            text-align: center;
            position: relative;

            color: @colorFontGray;
            font-size: 12px;
            text-decoration: none;
            span.step {
                display: block;
                width: 30px;
                height: 30px;
                line-height: 28px;
                border: 1px solid @colorFontGray;
                border-radius: 15px;
                margin: 0 auto;
                margin-bottom: 5px;
                i {
                    color: @colorGreen;
                }
            }
            &:hover {
                span.step {
                    -webkit-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
                    -moz-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
                    box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
                }
            }

            &.active {
                span.step {
                    border-color: @colorPink;
                    color: @colorPink;
                }
                &:hover {
                    span.step {
                        -webkit-box-shadow: 0px 0px 2px 0px fade(@colorPink, 75%);
                        -moz-box-shadow: 0px 0px 2px 0px fade(@colorPink, 75%);
                        box-shadow: 0px 0px 2px 0px fade(@colorPink, 75%);
                    }
                }
            }

            &:after {
                content: "\f054";
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transform: translate(0, 0);
                display: block;
                position: absolute;
                right: -5px;
                top: 10px;
                color: @colorFontGray;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

section.reservation {
    .booking {
        margin-bottom: 20px;
        .offer-name {
            margin: 0;
        }
        .address {
            span {
                color: @colorFontGray;
            }
            margin-bottom: 10px;
        }

        .dates {
            margin-bottom: 10px;
            dt {
                float: left;
                width: 100px;
                font-weight: normal;
            }
            dd {
                margin-left: 110px;
            }
        }

        .user-data {
            margin-bottom: 10px;
            dt {
                float: left;
                width: 150px;
                font-weight: normal;
            }
            dd {
                margin-left: 160px;
            }
        }

        .entities {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .total-price {
            margin-top: 20px;
            background: @colorBgBlue;
            padding: 10px 20px;
            font-size: 25px;
            color: #fff;
            .price {
                font-weight: bold;
                text-align: right;
            }
        }
    }

    form#form-booking {
        margin-bottom: 50px;
        fieldset {
            margin: 0;
        }

        .form-element {
            margin-bottom: 20px;
        }

        input[type="text"],
        input[type="password"] {
            height: 50px;
        }

        div.control-label {
            line-height: 50px;
        }

        .styled-radiobox,
        .styled-checkbox {
            &.inline {
                line-height: 50px;
            }
        }

    }
}