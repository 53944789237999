.modal.iframe {
    .modal-dialog {
        height: auto;
    }
    .modal-body {
        padding: 0;
        margin: 0;
        line-height: 0;

        &.loading {
            padding: 150px 0;
        }
    }
    .modal-content {
        border: none;
        .modal-header {
            min-height: 0;
            height: 0;
            padding: 0;
            .modal-title {
                display: none;
            }

        }
        button.close {
            position: absolute;
            right: 14px;
            top: 14px;
            z-index: 2;
            i {
                font-size: 14px;
            }
        }
    }
    .modal-footer {
        display: none;
    }
}

.iframe-content {
    overflow: hidden;
}

@media (max-width: @screen-xs-max) {

    .modal.iframe {
        .modal-dialog {
            margin: 0;
            height: 100%;
            .modal-content {
                .modal-header {
                    height: auto;
                    padding: 15px 25px 10px;
                    .modal-title {
                        display: block;
                    }
                }
                button.close {
                    position: static;
                }

                iframe {
                    overflow: hidden;
                }
            }
        }
    }
}

